import { defineStore } from "pinia";
import * as API from "@/api/rx-factory";
import * as ENDPOINTS from "@/api/endpoints";
const qs = require("qs");
import router from "@/router";
import static_vars from '@/staticVars';

export const myStore = defineStore({
  id: "userSettingStore",
  persist: {
    key: "userSetting",
    storage: window.localStorage,
    paths: [
      "user",
      "customerDetail",
      "flavordata",
      "topTrdaersList",
      "affiliateCustomerDetail",
      "countryList",
    ],
  },
  state: () => ({
    loading: false,
    singleLoading : false,
    user: {},
    customerDetail: {},
    flavordata: {},
    topTrdaersList: {},
    affiliateCustomerDetail: {},
    countryList: {},
  }),
  getters: {},
  actions: {
    login(params = {}, loader = false, token, _that, accType) {
      return new Promise((resolve, reject) => {
        if (loader) {
          _that.loader = true;
        }
        params['demoAccountTriggered'] = false;
        const data = qs.stringify(params);
        API.post(ENDPOINTS.EP_USER_LOGIN, data, token)
          .then((response) => {
            _that.loader = false;
            if (response.access_token) {
              this.user = response;
              if (accType == "web") {
                if (params["googleToken"]) {
                  window.open(
                    `${static_vars.domainURL}/dashboard/?token=${this.user.access_token}&refresh_token=${this.user.refresh_token}`,
                    "_self"
                  );
                } else {
                  _that.callCustomerDetail();
                }
                _that.SetToken(response);
              } else if (accType == "aff") {
                _that.callCustomerDetail();
              }
            }
            resolve(response);
          })
          .catch((error) => {
            _that.loader = false;
            reject(error);
          });
      });
    },
    register(params = {}, loader = false, _that, type) {
      return new Promise((resolve, reject) => {
        if (loader) {
          _that.loader = true;
        }
        API.post(ENDPOINTS.EP_REGISTRATION, params)
          .then((response) => {
            _that.loader = true;
            if (type && type == "Google") {
              _that.CretioGoogleFunc();
              _that.loginWithGoogle(params["token"]);
            } else {
              try {
                window.gtag("event", "Gen_Lead_2.0");
              } catch (ex) {
                console.log(ex);
              }
              if (params["tokenType"] == "USERNAME") {
                _that.loginMannual();
                _that.loginTokenType = 2;
              } else if (params["tokenType"] == "EMAIL") {
                _that.loginMannual();
              }
              resolve(response);
            }
          })
          .catch((error) => {
            if (error.response.status && error.response.status == 404) {
              // _that.loginWithGoogle(params['token'])
              _that.loader = false;
              reject(error);
            } else {
              _that.loader = false;
              reject(error);
            }
          });
      });
    },
    resendOTP(params = {}, loader = false, _that) {
      return new Promise((resolve, reject) => {
        if (loader) {
          _that.loader = true;
        }
        API.post(ENDPOINTS.EP_RESEND_OTP, params)
          .then((response) => {
            _that.loader = false;
            resolve(response);
          })
          .catch((error) => {
            _that.loader = false;
            reject(error);
          });
      });
    },
    verifyOTP(params = {}, loader = false, _that, accType) {
      return new Promise((resolve, reject) => {
        if (loader) {
          _that.loader = true;
        }
        API.put(ENDPOINTS.EP_VERIFY_OTP, params)
          .then((response) => {
            _that.loader = false;
            if (response === true) {
              if (accType == "web1") {
                _that.CretioFunc();
                _that.resendPhoneOTP();
                // _that.showEmailCode = false;
                // _that.form.emailcode = "";
                // _that.successStep = 2;
                // window.open(
                //   `${static_vars.domainURL}/dashboard/?token=${this.user.access_token}&refresh_token=${this.user.refresh_token}`,
                //   "_self"
                // );
              } else if(accType == "web"){
                _that.CretioFunc();
                _that.showEmailCode = false;
                _that.form.emailcode = "";
                _that.successStep = 2;
                window.open(
                  `${static_vars.domainURL}/dashboard/?token=${this.user.access_token}&refresh_token=${this.user.refresh_token}`,
                  "_self"
                );
              }else if (accType == "aff") {
                window.open(
                  `${static_vars.domainURL}/partners/dashboard/?auth=${this.user.access_token}`,
                  "_blank"
                );
              }
            }
            resolve(response);
          })
          .catch((error) => {
            _that.loader = false;
            reject(error);
          });
      });
    },
    sendOtpMobile(params = {}, loader = false) {
      return new Promise((resolve, reject) => {
        if (loader) {
          this.loader = true
        }
        API.post(ENDPOINTS.EP_SEND_SMS_MOBILE, params).then(response => {
          this.loader = false
          resolve(response)
        }).catch(error => {
          this.loader = false
          reject(error)
        })
      });
    },
     verifyMobileOTP(params = {}, loader = false,_that) {
      return new Promise((resolve, reject) => {
        if (loader) {
          this.loader = true
        }
        API.put(ENDPOINTS.EP_VERIFY_MOBILE_OTP, params).then(response => {
          this.loader = false
          // _that.showEmailCode = false;
          _that.form.emailcode = "";
          _that.form.phonecode = "",
          // _that.successStep = 2;
          // window.open(
          //   `${static_vars.domainURL}/dashboard/?token=${this.user.access_token}&refresh_token=${this.user.refresh_token}`,
          //   "_self"
          // );
          resolve(response)
        }).catch(error => {
          this.loader = false
          reject(error)
        })
      });
    },
    callCustomerDetail(params = {}, loader = false) {
      if (loader) {
        this.loading = true;
      }
      return new Promise((resolve, reject) => {
        const ep = ENDPOINTS.EP_GET_CUSTOMER_DETAIL;
        API.get(ep)
          .then((response) => {
            this.loading = false;
            this.customerDetail = response;
            resolve(response);
          })
          .catch((error) => {
            this.loading = false;
            reject(error);
          });
      });
    },
    callUpdateProfileInfo(params = {}, loader = false) {
      if (loader) {
        this.loading = true;
      }
      return new Promise((resolve, reject) => {
        const ep = ENDPOINTS.EP_GET_CUSTOMER_DETAILS_AFFILIATE;
        API.put(ep,params)
          .then((response) => {
            this.loading = false;
            this.customerDetail = response;
            resolve(response);
          })
          .catch((error) => {
            this.loading = false;
            reject(error);
          });
      });
    },
    callCustomerDetailAffiliate(params = {}, loader = false) {
      if (loader) {
        this.loading = true;
      }
      return new Promise((resolve, reject) => {
        const ep = ENDPOINTS.EP_GET_CUSTOMER_DETAILS_AFFILIATE;
        API.get(ep)
          .then((response) => {
            this.loading = false;
            this.affiliateCustomerDetail = response;
            resolve(response);
          })
          .catch((error) => {
            this.loading = false;
            reject(error);
          });
      });
    },
    getCountryList(params = {}, loader = false) {
      if (loader) {
        this.loading = true;
      }
      return new Promise((resolve, reject) => {
        const ep = ENDPOINTS.EP_GET_COUNTRY_LIST;
        API.get(ep)
          .then((response) => {
            this.loading = false;
            this.countryList = response;
            resolve(response);
          })
          .catch((error) => {
            this.loading = false;
            reject(error);
          });
      });
    },
    getFlavorID(params = {}, loader = false) {
      if (loader) {
        this.loading = false;
      }
      return new Promise((resolve, reject) => {
        const ep = this.user.access_token
          ? ENDPOINTS.EP_GET_LOGIN_FLAVOR_LIST
          : ENDPOINTS.EP_GET_FLAVOR_LIST;
        API.get(ep)
          .then((response) => {
            this.loading = false;
            this.flavordata = response;
            resolve(response);
          })
          .catch((error) => {
            this.loading = false;
            reject(error);
          });
      });
    },
    callTopTradersList(params = {}, loader = false) {
      if (loader) {
        this.loading = true;
      }
      return new Promise((resolve, reject) => {
        let ep = ENDPOINTS.EP_GET_TOP_TRADER_LIST;
        if (Object.keys(this.flavordata).length && this.flavordata.flavorId) {
          ep = ep.replace("{{flavorId}}", this.flavordata.flavorId);
          ep = ep.replace("{{accessingFlavorId}}", this.flavordata.flavorId);
        } else {
          ep = ep.replace("{{flavorId}}", "1");
          ep = ep.replace("{{accessingFlavorId}}", "1");
        }
        API.get(ep)
          .then((response) => {
            this.loading = false;
            // console.log('responseresponse',response[0].result)
            this.topTrdaersList = response;
            resolve(response);
          })
          .catch((error) => {
            this.loading = false;
            reject(error);
          });
      });
    },
    affilateLanded(params = {}, loader = false) {
      if (loader) {
        this.loading = true;
      }
      return new Promise((resolve, reject) => {
        API.post(ENDPOINTS.EP_AFFILATE_LANDED, params)
          .then((response) => {
            this.loading = false;
            resolve(response);
          })
          .catch((error) => {
            this.loading = false;
            reject(error);
          });
      });
    },
  },
});
