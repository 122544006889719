export default {
    locales: {
        vi: "Vietnam"
    },
    // leader page
    leader: {
        'text1': `ZuluTrade Logo`,
        'text2': `Đăng ký ngay`,
        'text3': `Đăng ký`,
        'text4': `Đăng ký với tư cách là người lãnh đạo tại ZuluTrade.`,
        'text5': `Công khai tài khoản và bắt đầu giao dịch.`,
        'text6': `Các nhà đầu tư sao chép chiến lược của bạn và bạn nhận được thu nhập của mình.`,
        'text7': `Lãnh đạo`,
        'text8': `Một`,
        'text9': `Các nhà đầu tư theo dõi các nhà lãnh đạo sau khi phân tích trang hiệu suất của họ.`,
        'text10': `Mỗi khi một nhà lãnh đạo thực hiện giao dịch, giao dịch đó cũng được sao chép vào tài khoản của nhà đầu tư.`,
        'text11': `Các giao dịch mang lại lợi nhuận cho người dẫn đầu cũng mang lại lợi nhuận cho nhà đầu tư.`,
        'text12': `chủ đầu tư`,
        'text13': `MỘT`
    },
    pages: {
        'text1': `Tham gia hàng đầu thế giới`,
        'text2': `Giao dịch xã hội`,
        'text3': `Cộng đồng!`,
        'text4': `Nhận $5 - $10 cho mỗi $100.000 được giao dịch. Số tiền này sẽ tuỳ thuộc vào cặp tiền tệ được giao dịch, loại tài khoản, v.v.`,
        'text5': `Hãy trở thành thành viên của một cộng đồng toàn cầu với hơn 2 triệu người dùng và hơn 90,000 người dẫn đầu trên nền tảng giao dịch xã hội hàng đầu.`,
        'text6': `Chia sẻ chiến lược của bạn và nhận hoa hồng từ sao chép giao dịch. Hãy tham gia ZuluTrade!`,
        'text7': `ZuluTrade Leader`,
        'text8': `Mở tài khoản Người dẫn đầu`,
        'text9': `Tìm hiểu thêm`
    },
    second3: {
        'text1': `Enjoy Flexible`,
        'text2': `Compensation Schemes`,
        'text3': `Our default monetization model is CPA, but we are open to discussing more options for established affiliates, like hybrid and custom deals if they can deliver large volumes of customers. Our CPA playouts are categorized into Tiers, depending on the Geolocation of the accounts referred by our affiliates.`,
        'text4': `Maximize your earnings with our affiliate program. Earn up to 300$ per investor introduced, and claim even more with our hybrid custom CPA model`,
        'text5': `Become an Affiliate`,
        'text6': `Tìm hiểu thêm`,
        'text7': `Affiliate Zulu Coin`
    },
    section2: {
        'text1': `Nhà lãnh đạo thực hiện hàng đầu`,
        'text2': `Những chiến lược có đà tăng trưởng tốt và tiệm cận mức cao nhất từ trước đến nay (ATH)`,
        'text3': `Người sao chép`,
        'text4': `Người theo dõi`,
        'text5': `Tham gia cùng những người xuất sắc nhất`
    },
    section3: {
        'text1': `Tại sao tham gia ZuluTrade với tư cách là một`,
        'text2': `Lãnh đạo?`,
        'text3': `ZuluTrade licence icon`,
        'text4': `ZuluTrade được quản lý bởi HCMC tại EU, FSA tại Nhật Bản, FSC tại Mauritius và FSCA tại Nam Phi.`,
        'text5': `ZuluTrade advanced and feature rich icon`,
        'text6': `Một nền tảng tiên tiến và đa tính năng, cho phép tùy chỉnh trải nghiệm giao dịch của bạn.`,
        'text7': `ZuluTrade support icon`,
        'text8': `Gia tăng lợi nhuận với bộ phận giao dịch chuyên biệt, giúp thu hút và giữ chân các nhà đầu tư.`,
        'text9': `ZuluTrade No hidden costs icon`,
        'text10': `Không có chi phí ẩn hay giới hạn thanh toán hàng tháng.`,
        'text11': `ZuluTrade multiple brokers icon`,
        'text12': `Nền tảng được thiết kế để hỗ trợ nhiều nhà môi giới.`,
        'text13': `ZuluTrade network icon`,
        'text14': `Mạng lưới giao dịch xã hội rộng lớn với hơn 90.000 người dẫn đầu.`
    },
    section4: {
        'text1': `Làm sao`,
        'text2': `ZuluTrade`,
        'text3': `làm việc cho`,
        'text4': `Lãnh đạo?`,
        'text5': `Join ZuluTrade icon`,
        'text6': `Hãy trở thành một người dẫn đầu thực sự trên ZuluTrade`,
        'text7': `Tham gia ZuluTrade với vai trò là một người dẫn đầu, kết nối với tài khoản và chia sẻ các chiến lược giao dịch thành công của bạn!`,
        'text8': `Rankings icon`,
        'text9': `Trở nên nổi bật và thành công`,
        'text10': `Hệ thống xếp hạng của chúng tôi đánh giá dựa trên hiệu suất, tính ổn định, hành vi và triển vọng.`,
        'text11': `Earnings icon`,
        'text12': `Kiếm thêm thu nhập một cách dễ dàng`,
        'text13': `Khi trở thành một người dẫn đầu, bạn sẽ nhận được tiền khi các nhà đầu tư khác sao chép các giao dịch của bạn, số tiền này sẽ tuỳ theo quy mô đầu tư và mức độ chấp nhận rủi ro của họ.`
    },
    header: {
        'text12': `ZuluTrade's`,
    },
    section5: {
        'text1': `Người dẫn đầu tại ZuluTrade:`,
        'text2': `Họ làm những gì?`,
        'text3': `'Người dẫn đầu' là những người dùng ZuluTrade chia sẻ và cho phép những người dùng khác sao chép các giao dịch của họ vào tài khoản. Mỗi giao dịch được thực hiện trong tài khoản của người dẫn đầu sẽ được gửi đến tất cả các nhà đầu tư của họ dưới dạng tín hiệu giao dịch.`,
        'text4': `Tạo ra một cộng đồng và dẫn dắt họ!`,
        'text5': `research icon`,
        'text6': `Nghiên cứu `,
        'text7': `thị trường, tin tức và thông tin cập nhật về công ty.`,
        'text8': `intract icon`,
        'text9': `Tương tác `,
        'text10': `với các nhà đầu tư và thảo luận về các cập nhật liên quan đến chiến lược giao dịch`,
        'text11': `broadcast icon`,
        'text12': `Truyền `,
        'text13': `các tín hiệu giao dịch đến các nhà đầu tư ZuluTrade và lên bảng xếp hạng trên trang web.`
    },
    section6: {
        'text1': `Cách tham gia ZuluTrade với tư cách là một`,
        'text2': `Lãnh đạo?`,
        'text3': `Bước 1`,
        'text4': `Đăng ký trở thành Người dẫn đầu tại ZuluTrade.`,
        'text5': `Bước 2`,
        'text6': `Chia sẻ tài khoản công khai và bắt đầu giao dịch.`,
        'text7': `Bước 3`,
        'text8': `Bạn sẽ kiếm được thu nhập khi nhà đầu tư sao chép chiến lược của bạn.`,
        'text9': `Tham gia cùng chúng tôi tại ZuluTrade`,
        'text10': `Tự động điền thông tin cá nhân của bạn bằng cách `,
        'text11': `Google icon`,
        'text12': `hoặc điền thủ công`,
        'text13': `Tên người dùng hoặc email`,
        'text14': `Điền mật khẩu`,
        'text15': `Tạo tài khoản`
    },
    second5: {
        'text1': `How to become`,
        'text2': `ZuluTrade Affiliate`,
        'text3': `in 3 easy steps?`,
        'text4': `1`,
        'text5': `Click on 'Become an Affiliate'.`,
        'text6': `2`,
        'text7': `Complete the registration and get your unique affiliate URL.`,
        'text8': `3`,
        'text9': `Invite your contacts using unique affiliate URL  and start earning.`,
        'text10': `One-Time Pin has been sent to your email. Please enter it below.`,
        'text11': `Enter OTP`,
        'text12': `Submit`,
        'text13': `Affiliates Account Registration`,
        'text14': `Enter Email Address`,
        'text15': `Enter Your Password`,
        'text16': `Confirm Password`,
        'text17': `Chọn quốc gia cư trú`,
        'text18': `Company Full Name (For Corporate Accounts)`,
        'text19': `Enter Company Full Name`,
        'text20': `Company's Legal Representative`,
        'text21': `Tên đầu tiên`,
        'text22': `Họ`,
        'text23': `I agree to the`,
        'text24': `Privacy Policy`,
        'text25': `Terms.`,
        'text26': `Register`,
        'text27': `Back to login`,
        'text28': `Giao dịch các công cụ tài chính liên quan đến rủi ro thua lỗ đáng kể. Đối với tuyên bố từ chối trách nhiệm đầy đủ`,
        'text29': `bấm vào đây.`
    },
    affiliate: {
        'text1': `How to become`,
        'text2': `ZuluTrade Affiliate`,
        'text3': `in 3 easy steps?`,
        'text4': `1`,
        'text5': `Click on 'Become an Affiliate'.`,
        'text6': `2`,
        'text7': `Complete the registration and get your unique affiliate URL.`,
        'text8': `3`,
        'text9': `Invite your contacts using unique affiliate URL and start earning.`,
        'text10': `Verify your Email address`,
        'text11': `Enter the 6-digit verification code that was sent to`,
        'text12': `The code is valid for 10 minutes.`,
        'text13': `Resend Code`,
        'text14': `Submit`,
        'text15': `Affiliates Account Registration`,
        'text16': `Enter Email Address`,
        'text17': `Enter Your Password`,
        'text18': `Confirm Password`,
        'text19': `Chọn quốc gia cư trú`,
        'text20': `Company Full Name (For Corporate Accounts)`,
        'text21': `Enter Company Full Name`,
        'text22': `Tên đầu tiên`,
        'text23': `Họ`,
        'text24': `I agree to the`,
        'text25': `Privacy Policy`,
        'text26': `&`,
        'text27': `Terms.`,
        'text28': `Register`,
        'text29': `Thank`,
        'text30': `you!`,
        'text31': `Your account has created successfully.`,
        'text32': `Giao dịch các công cụ tài chính liên quan đến rủi ro thua lỗ đáng kể. Đối với tuyên bố từ chối trách nhiệm đầy đủ`,
        'text33': `bấm vào đây.`,
    },
    zulutrade: {
        'text1': `Cách tham gia ZuluTrade với tư cách là`,
        'text2': `Bước 1`,
        'text3': `Bước 2`,
        'text4': `Bước 3`,
        'text5': `Tham gia cùng chúng tôi tại ZuluTrade`,
        'text6': `Tự động điền thông tin cá nhân của bạn bằng cách `,
        'text7': `hoặc điền thủ công`,
        'text8': `E-mail`,
        'text9': `Mật khẩu`,
        'text10': `Mật khẩu mạnh`,
        'text11': `1 uppercase character`,
        'text12': `8 or more characters`,
        'text13': `at least 1 number`,
        'text14': `at least 1 letter`,
        'text15': `at least 1 special character`,
        'text16': `Loading...`,
        'text17': `Tạo tài khoản`,
        'text18': `Giao dịch các công cụ tài chính liên quan đến rủi ro thua lỗ đáng kể. Đối với tuyên bố từ chối trách nhiệm đầy đủ`,
        'text19': `bấm vào đây.`,
        'text20': `Tên đầu tiên`,
        'text21': `Số điện thoại`,
        'text22': `Họ`,
        'text23': `Chọn quốc gia cư trú`,
        'text24': `Tìm kiếm ở đây..`,
        'text25': `Quốc gia là bắt buộc`,
        'text26': `Số điện thoại là bắt buộc`,
    },

    // Investor page
    
    banner: {
        'text1': `Đầu tư dễ dàng với`,
        'text2': `CopyTrading và Social Trading!`,
        'text3': `Đi Zulu!`,
        'text4': `ZuluTrade là một trong những nền tảng giao dịch xã hội hàng đầu thế giới với hơn 90.000 người dẫn đầu chuyên nghiệp và hơn 2 triệu người dùng hoạt động trên nền tảng này. Hãy khám phá những người dẫn đầu hàng đầu từ khắp nơi trên thế giới, sao chép chiến lược và giao dịch của họ trong thời gian thực vào tài khoản đầu tư của bạn.`,
        'text5': `Bắt đầu đầu tư`,
        'text6': `ZuluTrade Banner Image`
    },
    third1: {
        'text1': `Theo`,
        'text2': `Các chuyên gia`,
        'text3': `và đánh bại`,
        'text4': `tỷ lệ cược`,
        'text5': `trong thế giới giao dịch với Copy Trading`,
        'text6': `Đưa hành trình giao dịch của bạn lên một tầm cao mới với ZuluTrade - luôn đồng hành cùng bạn.`,
        'text7': `ZuluTrade Compare Leaders Image`,
        'text8': `Mở tài khoản Demo để vừa giao dịch, vừa học hỏi!`,
        'text9': `DEMO`,
        'text10': `Become an Affiliate`,
    },
    third2: {
        'text1': `Tại sao lựa chọn`,
        'text2': `ZuluTrade?`,
        'text3': `ZuluTrade transparent copy trading platform icon`,
        'text4': `Nền tảng sao chép giao dịch lâu đời nhất và minh bạch nhất.`,
        'text5': `ZuluTrade license icon`,
        'text6': `ZuluTrade được quản lý bởi HCMC tại EU, FSA tại Nhật Bản, FSC tại Mauritius và FSCA tại Nam Phi.`,
        'text7': `ZuluTrade investors profit icon`,
        'text8': `Bạn có thể liên hệ với đội ngũ dịch vụ khách hàng chất lượng của chúng tôi 24/5 qua trò chuyện, điện thoại hoặc email!`,
        'text9': `ZuluTrade serves globally icon`,
        'text10': `Phục vụ khách hàng toàn cầu tại hơn 150 quốc gia.`,
        'text11': `Average winning ratio`,
        'text12': `Các công cụ xã hội cho phép bạn tương tác với những người dẫn đầu và các nhà đầu tư khác.`,
        'text13': `vast community`,
        'text14': `Một cộng đồng lớn gồm hơn 2 triệu người dùng trên toàn thế giới.`
    },
    third3: {
        'text1': `Điều gì khiến ZuluTrade `,
        'text2': `đặc biệt?`,
        'text3': `ZuluTrade Unique Features Image`,
        'text4': `ZuluGuard`,
        'text5': `TM`,
        'text6': `Một công cụ giao dịch xã hội thông minh, an toàn cho khoản đầu tư của bạn. ZuluGuard bảo vệ vốn đầu tư của bạn bằng cách liên tục theo dõi tài khoản của bạn, đảm bảo lợi nhuận và giảm các khoản lỗ tiềm ẩn.`,
        'text7': `Nền tảng đa dạng`,
        'text8': `Bạn có thể chọn giao dịch từ nhiều nền tảng có sẵn:`,
        'text9': `MT4, MT5, ActTrader, X Open Hub, Match-Trader.`,
        'text10': `Bảng tin xã hội`,
        'text11': `Tương tác với các nhà đầu tư và những người dẫn đầu khác trên khắp thế giới. Bình luận, đánh giá, tham gia thảo luận và chinh phục thị trường bằng trí tuệ của một cộng đồng xã hội.`,
        'text12': `Bắt đầu sao chép giao dịch ngay`
    },
    third4: {
        'text1': `Cách tham gia ZuluTrade với tư cách là một`,
        'text2': `Nhà đầu tư?`,
        'text3': `Bước 1`,
        'text4': `Nhà đầu tư theo dõi Người dẫn đầu sau khi phân tích hiệu suất của họ.`,
        'text5': `Bước 2`,
        'text6': `Mỗi khi người dẫn đầu thực hiện giao dịch, giao dịch đó cũng sẽ được sao chép vào tài khoản của nhà đầu tư.`,
        'text7': `Bước 3`,
        'text8': `Các giao dịch mang lại lợi nhuận cho người dẫn đầu cũng sẽ mang lại lợi nhuận cho nhà đầu tư.`,
        'text9': `Tạo tài khoản`,
        'text10': `Giao dịch các công cụ tài chính liên quan đến rủi ro thua lỗ đáng kể. Đối với tuyên bố từ chối trách nhiệm đầy đủ`,
        'text11': `bấm vào đây.`
    }
}
