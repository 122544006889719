<template>
<section id="bannerForm" class="pt80 pb80 bodyColor">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7 col-12 mb-4 mb-lg-0">
                <div class="sectionDisc mobText-center maxCustom">
                    <h1>{{$t('pages.text1')}} <span>{{$t('pages.text2')}}</span> {{$t('pages.text3')}}</h1>
                    <h5 class="mb-0">{{$t('pages.text4')}}</h5>
                   <p class="sec-sub-heading">{{$t('pages.text5')}}<br>{{$t('pages.text6')}}</p>
                   <!--  <div class="row justify-content-center">
                        <div class="col-md-8 col-12">
                            <img class="img-fluid" src="../../assets/images/zuluImages/socialTrading.svg" :alt="$t('pages.text7')" width="680" height="280">
                        </div> 
                   </div>-->
                    <!-- <div class="banner-links">
                        <a href="#registerForm" class="commonBtn linkBtn"><span>{{$t('pages.text8')}}</span></a>
                        <a href="https://www.zulutrade.com/become-a-leader" target="_blank" class="commonBtn linkBtn"><span>{{$t('pages.text9')}}</span></a>
                    </div> -->
                </div>
            </div>
            <div class="col-lg-5 col-12">
                <joinZuluTrade v-if="$route.name == 'SocialTrading'" />
                <joinZuluTrade1 v-if="$route.name == 'SocialTrading1'" />
            </div>
        </div>
    </div>
</section>
</template>
<script>
import joinZuluTrade from "@/components/joinZulutradeForm.vue";
import joinZuluTrade1 from "@/components/joinZulutradeForm1.vue";
export default {
  name: "Banner",
  components: { joinZuluTrade,joinZuluTrade1 },
};
</script>
