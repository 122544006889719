export default {
  locales: {
    "ar": "Arabic"
  },
  header: {
    "text12": "قائد"
  },
  leader: {
    "text1": ``,
    "text4": "قم بالتسجيل بصفتك قائدًا في ZuluTrade.",
    "text5": "اجعل الحساب عامًا وابدأ التداول.",
    "text6": "يقوم المستثمرون بنسخ استراتيجيتك وأنت تحصل على أرباحك.",
    "text7": "القائد",
    "text8": "a",
    "text9": "يقوم المستثمرون بمتابعة القادة بعد تحليل صفحات الأداء الخاصة بهؤلاء القادة.",
    "text10": "في كل مرة يقوم فيها القائد بإجراء صفقة، يتم نسخ هذه الصفقة إلى حسابات المستثمرين أيضًا.",
    "text11": "الصفقات التي تحقق أرباحًا للقائد تحقق أرباحًا أيضًا للمستثمر.",
    "text12": "المستثمر",
    "text13": "an"
  },
  pages: {
    "text1": "انضم إلى مجتمع ",
    "text2": " التداول الاجتماعي",
    "text3": "الرائد عالميًا!",
    "text4": "اربح 5 دولار - 10 دولار عن كل حجم تداول 100 ألف دولار. تعتمد التكلفة على زوج العملة الذي يتم تداوله ونوع الحساب وما إلى ذلك.",
    "text5": "كن جزءًا من مجتمع عالمي يضم أكثر من 2 مليون مستخدم وأكثر من 90 ألف قائد على أفضل منصة للتداول الاجتماعي.",
    "text6": "شارك استراتيجياتك واربح عمولات على الصفقات المنسوخة. انضم إلى ZuluTrade الآن!",
    "text7": ``,
    "text8": "افتح حساب قائد"
  },
  section2: {
    "text1": "القادة الأفضل أداء",
    "text2": "استراتيجيات ذات زخم إيجابي وتتحرك بالقرب من أعلى المستويات على الإطلاق",
    "text3": "الناسخون",
    "text4": "المتابعون",
    "text5": "انضم إلى أفضلهم"
  },
  section3: {
    "text1": "لماذا يجب أن تنضم إلى ZuluTrade بصفتك ",
    "text2": "قائدًا؟",
    "text3": ``,
    "text4": "إن ZuluTrade مرخصة وخاضعة للرقابة من هيئة سوق المال اليونانية بالاتحاد الأوروبي (HCMC) وهيئة الخدمات المالية اليابانية (FSA) وهيئة الخدمات المالية في موريشيوس (FSC) وهيئة سلوك القطاع المالي (FSCA) في جنوب أفريقيا.",
    "text5": ``,
    "text6": "منصة متقدمة وغنية بالمزايا من أجل إضفاء طابعك الشخصي على تجربتك في التداول.",
    "text7": ``,
    "text8": "زد ربحيتك مع مكتب متداولين متخصص من أجل جذب المستثمرين والاحتفاظ بهم.",
    "text9": ``,
    "text10": "دون تكاليف مخفية أو حدود قصوى للمدفوعات الشهرية.",
    "text11": ``,
    "text12": "منصة مصممة لدعم وسطاء متعددين.",
    "text13": ``,
    "text14": "شبكة تداول اجتماعي ضخمة تضم أكثر من 90 ألف قائد."
  },
  section4: {
    "text1": "كيف تعمل",
    "text2": "ZuluTrade",
    "text3": "بالنسبة إلى",
    "text4": " القادة؟",
    "text5": ``,
    "text6": "كن قائدًا حقيقيًا على ZuluTrade",
    "text7": "انضم إلىZuluTrade بصفتك قائدًا، واربط حسابك على المنصة، وشارك استراتيجيات التداول الرابحة الخاصة بك!",
    "text8": ``,
    "text9": "كن مميزًا وناجحًا ",
    "text10": "يراعي نظام التصنيف لدينا الأداء والاستقرار والسلوك والأفاق.",
    "text12": "أرباح دون توتر أو قلق",
    "text13": "بصفتك قائدًا، فأنت تربح عندما يقوم المستثمرون بنسخ صفقاتك، مع الأخذ في الاعتبار حجم استثماراتهم ودرجة تحملهم للمخاطر."
  },
  section5: {
    "text1": "قادة ZuluTrade:",
    "text2": "ماذا يفعلون؟",
    "text3": "إن ‘القائد’هو أحد مستخدمي ZuluTrade والذي يشارك صفقاته ويسمح للمستخدمين الآخرين بنسخ صفقته في حساباتهم. كل إجراء تداول يتم في حساب القائدة يتم إرساله تلقائيًا إلى جميع المستثمرين المتابعين للقائدة في صورة إشارة مبثوثة.",
    "text4": "قم بإنشاء مجتمع وقيادته!",
    "text5": ``,
    "text6": "البحث",
    "text7": "في الأسواق والأخبار وتحديثات الشركة بشكل دوري.",
    "text8": ``,
    "text9": "التفاعل",
    "text10": "مع المستثمرين وإبلاغهم بالتحديثات المتعلقة باستراتيجية التداول.",
    "text11": ``,
    "text12": "بث",
    "text13": "إشارات التداول إلى المستثمرين في ZuluTrade والظهور بشكل متميز على التصنيفات في الموقع الإلكترون."
  },
  section6: {
    "text1": "كيف تنضم إلى ZuluTrade بصفتك",
    "text2": "قائدًا؟",
    "text3": "الخطوة 1",
    "text4": "قم بالتسجيل بصفتك قائدًا في ZuluTrade.",
    "text5": "الخطوة  2",
    "text6": "اجعل الحساب عامًا وابدأ التداول.",
    "text7": "الخطوة  3",
    "text8": "يقوم المستثمرون بنسخ استراتيجيتك وأنت تحصل على أرباحك.",
    "text9": "انضم إلينا في ZuluTrade",
    "text10": "املأ بياناتك الشخصية تلقائيًا باستخدام",
    "text11": ``,
    "text12": "أو قم بملئها يدويًا",
    "text13": "اسم المستخدم أو البريد الإلكتروني",
    "text14": "ادخل كلمة المرور",
    "text15": "إنشاء حساب"
  },
  second3: {
    "text6": "اكتشف المزيد"
  },
  second5: {
    "text28": "ينطوي التداول في الأدوات المالية على مخاطر مرتفعة بتكبد الخسارة. للاطلاع على إخلاء المسؤولية الكامل",
    "text29": " انقر هنا."
  },
  otp: {
    "text1": "أدخل عنوان بريدك الإلكتروني",
    "text2": "البريد الإلكتروني",
    "text3": "قيد التحميل...",
    "text4": "تأكيد عنوان بريدك الإلكتروني",
    "text5": "أدخل رمز التحقق المكون من 6 أرقام الذي تم إرساله إلى ",
    "text6": "الرمز صالح لمدة 10 دقائق.",
    "text7": "قد قرأت وأقبل",
    "text8": "شروط الاستخدام",
    "text9": "سياسة الخصوصية",
    "text10": "سياسة تضارب المصالح",
    "text11": "إقرار الموافقة",
    "text12": "و",
    "text13": "إخلاء المسرولية",
    "text14": "تأكيد البريد الإلكتروني"
  },
  affiliate: {
    "text32": "ينطوي التداول في الأدوات المالية على مخاطر مرتفعة بتكبد الخسارة. للاطلاع على إخلاء المسؤولية الكامل",
    "text33": "انقر هنا."
  },
  zulutrade: {
    "text1": "كيف تنضم إلى ZuluTrade بصفتك ",
    "text2": "الخطوة 1",
    "text3": "الخطوة  2",
    "text4": "الخطوة  3",
    "text5": "انضم إلينا في ZuluTrade",
    "text6": "املأ بياناتك الشخصية تلقائيًا باستخدام",
    "text7": "أو قم بملئها يدويًا",
    "text8": " البريد الإلكتروني",
    "text9": " كلمة المرور",
    "text10": "قوة  كلمة المرور",
    "text11": ``,
    "text12": ``,
    "text13": ``,
    "text14": ``,
    "text15": ``,
    "text17": "إنشاء حساب",
    "text18": "ينطوي التداول في الأدوات المالية على مخاطر مرتفعة بتكبد الخسارة. للاطلاع على إخلاء المسؤولية الكامل",
    "text19": "نقر هنا.",
    "text20": "الاسم الأول",
    "text21": "رقم الهاتف",
    "text22": "الاسم الأخير",
    "text23": "حدد بلد الإقامة",
    "text24": "ابحث هنا..",
    "text25": "البلد إلزامي",
    "text26": "رقم الهاتف إلزامي"
  },
  banner: {
    "text1": "الاستثمار أصبح سهلاً بفضل ",
    "text2": "التداول بالنسخ والتداول الاجتماعي!",
    "text3": "هيا إلى Zulu!",
    "text4": "منصة ZuluTrade هي واحدة من منصات التداول الاجتماعي الرائدة عالميًا مع أكثر من 90 ألف قائد محترف وأكثر من 2 مليون مستخدم نشط على المنصة. اكتشف أفضل القادة من جميع أنحاء العالم وانسخ استراتيجياتهم واستنسخ صفقاتهم في الوقت الفعلي في حساب الاستثمار الخاص بك.",
    "text5": "ابدأ الاستثمار"
  },
  third1: {
    "text1": "تابع",
    "text2": "الخبراء",
    "text3": "وتغلب على",
    "text4": "المصاعب",
    "text5": "في عالم التداول مع التداول بالنسخ",
    "text6": "ارتق برحلتك إلى عالم التداول لمستوى أعلى في ظل ZuluTrade بجانبك.",
    "text8": "افتح حساب تجريبي والعب لتتعلم!!",
    "text9": "تجريبي",
    "text10": "كن منتسبًا"
  },
  third2: {
    "text1": "لماذا",
    "text2": `ZuluTrade`,
    "text4": "أقدم منصات التداول بالنسخ وأكثرها شفافية..",
    "text6": "إن ZuluTrade مرخصة وخاضعة للرقابة من هيئة سوق المال اليونانية بالاتحاد الأوروبي (HCMC) وهيئة الخدمات المالية اليابانية (FSA) وهيئة الخدمات المالية في موريشيوس (FSC) وهيئة سلوك القطاع المالي (FSCA) في جنوب أفريقيا.",
    "text8": "إمكانية التواصل عبر خدمة المحادثة أو الهاتف أو البريد الإلكتروني على مدار  ساعة يوميًا/ أيام أسبوعيًا مع خدمة العملاء رفيعة المستوى!",
    "text10": "تقدم خدماتها حول العالم في أكثر من 150 دولة.",
    "text11": "متوسط معدل الربح",
    "text12": "تتيح لك الأدوات الاجتماعية التفاعل مع القادة وزملائك المستثمرين.",
    "text13": "مجتمع ضخم",
    "text14": "مجتمع ضخم يضم أكثر من 2 مليون مستخدم حول العالم."
  },
  third3: {
    "text1": "ما الذي يجعل ZuluTrade",
    "text2": "متفردة عن غيرها؟",
    "text4": "أداة ZuluGuard",
    "text5": `TM`,
    "text6": "أداة تداول اجتماعي ذكية وآمنة مصممة لاستثماراتك. تحمي أداة ZuluGuard الأموال التي تستثمرها من خلال المراقبة الدائمة لحسابك مما يؤمن أرباحك ويقلل من خسائرك المحتملة.",
    "text7": "منصات متعددة",
    "text8": "يمكنك اختيار التداول من المنصات المتعددة المتاحة:",
    "text9": "MT4, MT5, ActTrader, X Open Hub, Match-Trader.",
    "text10": "تواصل اجتماعي",
    "text11": "تفاعل مع زملائك المستثمرين والقادة حول العالم. قم بالتعليق والتصنيف والمشاركة في المناقشة وروض السوق باستخدام حكمة المجتمع الاجتماعي.",
    "text12": "ابدأ التداول بالنسخ الآن"
  },
  third4: {
    "text1": "كيف تنضم إلى ZuluTrade بصفتك",
    "text2": "مستثمرًا?",
    "text3": "الخطوة 1",
    "text4": "يقوم المستثمرون بمتابعة القادة بعد تحليل صفحات الأداء الخاصة بهؤلاء القادة..",
    "text5": "الخطوة 2",
    "text6": "في كل مرة يقوم فيها القائد بإجراء صفقة، يتم نسخ هذه الصفقة إلى حسابات المستثمرين أيضًا.",
    "text7": "الخطوة 3",
    "text8": "الصفقات التي تحقق أرباحًا للقائد تحقق أرباحًا أيضًا للمستثمر.",
    "text9": "إنشاء حساب",
    "text10": "ينطوي التداول في الأدوات المالية على مخاطر مرتفعة بتكبد الخسارة. للاطلاع على إخلاء المسؤولية الكامل",
    "text11": " انقر هنا."
  }
}