<template>
    <footer class="mainFooter" v-if="footer()">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-2" v-if="$route.path == '/lp/copy-trading'">
                    <div class="content">
                        <ul class="footerList">
                            <li><a href="javascript:void(0)">{{$t('footer.text1')}}</a></li>
                            <li><a href="javascript:void(0)">{{$t('footer.text2')}}</a></li>
                            <li><a href="javascript:void(0)">{{$t('footer.text3')}}</a></li>
                            <li><a href="javascript:void(0)">{{$t('footer.text4')}}</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-12 col-md-2" v-if="$route.path == '/lp/copy-trading'">
                    <h6>{{$t('footer.text43')}}</h6>
                    <ul class="footerList">
                        <li><a href="javascript:void(0)">{{$t('footer.text5')}}</a></li>
                        <li><a href="javascript:void(0)">{{$t('footer.text6')}}</a></li>
                        <li><a href="javascript:void(0)">{{$t('footer.text7')}}</a></li>
                        <li><a href="javascript:void(0)">{{$t('footer.text8')}}</a></li>
                        <li><a href="javascript:void(0)">{{$t('footer.text9')}}</a></li>
                        <li><a href="javascript:void(0)">{{$t('footer.text10')}}</a></li>
                    </ul>
                </div>
                <div class="col-12 col-md-2" v-if="$route.path == '/lp/copy-trading'">
                    <h6>{{$t('footer.text44')}}</h6>
                    <ul class="footerList">
                        <li><a href="javascript:void(0)">{{$t('footer.text11')}}</a></li>
                        <li><a href="javascript:void(0)">{{$t('footer.text12')}}</a></li>
                        <li><a href="javascript:void(0)">{{$t('footer.text13')}}</a></li>
                        <li><a href="javascript:void(0)">{{$t('footer.text14')}}</a></li>
                        <li><a href="javascript:void(0)">{{$t('footer.text15')}}</a></li>
                        <li><a href="javascript:void(0)">{{$t('footer.text16')}}</a></li>
                        <li><a href="javascript:void(0)">{{$t('footer.text17')}}</a></li>
                        <li><a href="javascript:void(0)">{{$t('footer.text18')}}</a></li>
                    </ul>
                </div>
                <div class="col-12 col-md-2" v-if="$route.path == '/lp/copy-trading'">
                    <h6>{{$t('footer.text45')}}</h6>
                    <ul class="footerList">
                        <li><a href="javascript:void(0)">{{$t('footer.text19')}}</a></li>
                        <li><a href="javascript:void(0)">{{$t('footer.text20')}}</a></li>
                        <li><a href="javascript:void(0)">{{$t('footer.text21')}}</a></li>
                        <li><a href="javascript:void(0)">{{$t('footer.text22')}}</a></li>
                        <li><a href="javascript:void(0)">{{$t('footer.text23')}}</a></li>
                    </ul>
                </div>
                <div class="col-12 col-md-2" v-if="$route.path == '/lp/copy-trading'">
                    <h6>{{$t('footer.text46')}}</h6>
                    <ul class="footerList">
                        <li><a href="javascript:void(0)">{{$t('footer.text24')}}</a></li>
                        <li><a href="javascript:void(0)">{{$t('footer.text25')}}</a></li>
                        <li><a href="javascript:void(0)">{{$t('footer.text26')}}</a></li>
                        <li><a href="javascript:void(0)">{{$t('footer.text27')}}</a></li>
                        <li><a href="javascript:void(0)">{{$t('footer.text28')}}</a></li>
                        <li><a href="javascript:void(0)">{{$t('footer.text29')}}</a></li>
                        <li><a href="javascript:void(0)">{{$t('footer.text30')}}</a></li>
                        <li><a href="javascript:void(0)">{{$t('footer.text31')}}</a></li>
                    </ul>
                </div>
                <div class="col-12" :class="($route.path == '/lp/copy-trading')?'col-md-2':'text-end'">
                    <div class="socialFooter">
                        <div class="d-flex align-items-center justify-content-end mb-3"
                            v-if="$route.path == '/lp/copy-trading'">
                            <a href="https://apps.apple.com/gr/app/zulutrade/id336913058" target="_blank"
                                class="appStore me-2"><img src="../../assets/images/app-store.png"
                                    :alt="$t('footer.text32')" :title="$t('footer.text33')" /></a>
                            <a href="https://play.google.com/store/apps/details?id=zulu.trade.app" class="appStore"
                                target="_blank"><img src="../../assets/images/play-store.png" :alt="$t('footer.text34')"
                                    :title="$t('footer.text35')" /></a>
                        </div>
                        <p class="text-end text-white mb-2">{{$t('footer.text36')}}</p>
                        <ul class="socialList d-flex align-items-center justify-content-end">
                            <li>
                                <a href="https://www.facebook.com/zulutrade/" target="_blank">
                                    <vue-feather type="facebook"></vue-feather>
                                </a>
                            </li>
                            <li>
                                <a href="https://twitter.com/ZuluTrade" target="_blank">
                                    <vue-feather type="twitter"></vue-feather>
                                    <!-- <i class="icon"
                                         data-feather="twitter"></i> -->
                                </a>
                            </li>
                            <li>
                                <a href="https://www.linkedin.com/company/zulutrade" target="_blank">
                                    <vue-feather type="linkedin"></vue-feather>
                                    <!-- <i class="icon"
                                         data-feather="linkedin"></i> -->
                                </a>
                            </li>
                            <li>
                                <a href="https://www.youtube.com/user/zulutradechannel" target="_blank">
                                    <vue-feather type="youtube"></vue-feather>
                                    <!-- <i class="icon"
                                         data-feather="youtube"></i> -->
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-12">
                    <div class="disclamer mt-1">
                        <p>{{$t('footer.text37')}}</p>
                        <p>
                            {{$t('footer.text38')}}
                        </p>
                        <p>
                            {{$t('footer.text39')}}
                        </p>
                        <p>
                            {{$t('footer.text40')}}
                        </p>
                        <p>
                            {{$t('footer.text41')}}
                            <a class="link" :href="`${static_vars.domainURL}/risk-disclaimer`" target="_blank">
                                {{$t('footer.text42')}}</a>.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
    import Vue from 'vue';
    export default {
        methods: {
            footer() {
                if (this.$route.name == 'SocialTrading') {
                    return false
                } else if (this.$route.name == 'zuluLanding') {
                    return false
                }
                else if (this.$route.name == 'zuluTradeAffiliate') {
                    return false
                } else {
                    return true
                }
            }
        },
    }
</script>
