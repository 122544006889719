export default {
    locales: {
        it: "Italian"
    },
    // leader page
    leader: {
        'text1': `ZuluTrade Logo`,
        'text2': `Registrati ora`,
        'text3': `Registrati`,
        'text4': `Iscriviti come leader su ZuluTrade.`,
        'text5': `Rendi pubblico il tuo account e inizia a fare trading.`,
        'text6': `Gli investitori copiano la tua strategia e tu ricevi i tuoi guadagni.`,
        'text7': `Capo`,
        'text8': `una`,
        'text9': `Gli investitori seguono i leader dopo aver analizzato la loro pagina delle prestazioni.`,
        'text10': `Ogni volta che un leader effettua un'operazione, viene copiata anche sul conto dell'investitore.`,
        'text11': `Le negoziazioni redditizie per il leader sono anche redditizie per l'investitore.`,
        'text12': `Investitore`,
        'text13': `uno`
    },
    pages: {
        'text1': `Unisciti al leader mondiale`,
        'text2': `Commercio sociale`,
        'text3': `Comunità!`,
        'text4': `Guadagna $5-$10 per ogni $100.000 negoziati. Il costo dipende dalle coppie valutarie negoziate, dal tipo di conto ecc.`,
        'text5': `Entra a far parte di una community globale di oltre 2 milioni di utenti e oltre 90.000 leader sulla principale piattaforma di social trading.`,
        'text6': `Condividi le tue strategie, guadagna commissioni sulle operazioni copiate. Entra in ZuluTrade ora.`,
        'text7': `ZuluTrade Leader`,
        'text8': `Apri un conto Leader`,
        'text9': `Scopri di più`
    },
    second3: {
        'text1': `Enjoy Flexible`,
        'text2': `Compensation Schemes`,
        'text3': `Our default monetization model is CPA, but we are open to discussing more options for established affiliates, like hybrid and custom deals if they can deliver large volumes of customers. Our CPA playouts are categorized into Tiers, depending on the Geolocation of the accounts referred by our affiliates.`,
        'text4': `Maximize your earnings with our affiliate program. Earn up to 300$ per investor introduced, and claim even more with our hybrid custom CPA model`,
        'text5': `Become an Affiliate`,
        'text6': `Scopri di più`,
        'text7': `Affiliate Zulu Coin`
    },
    header: {
        'text12': `ZuluTrade's`,
    },
    section2: {
        'text1': `I leader a migliore performance di`,
        'text2': `Strategie che hanno un momentum positivo e si portano verso massimi storici`,
        'text3': `Copiatori`,
        'text4': `Follower`,
        'text5': `Unisciti ai migliori`
    },
    section3: {
        'text1': `Perché iscriversi ZuluTrade come `,
        'text2': `leader?`,
        'text3': `ZuluTrade licence icon`,
        'text4': `ZuluTrade è regolamentata dalla HCMC nell’UE, dalla FSA in Giappone, dalla FSC a Mauritius e dalla FSCA in Sudafrica.`,
        'text5': `ZuluTrade advanced and feature rich icon`,
        'text6': `Piattaforma avanzata e ricca di funzionalità per personalizzare la tua esperienza di trading.`,
        'text7': `ZuluTrade support icon`,
        'text8': `Migliora la redditività con un trader desk dedicato per attirare e trattenere gli investitori.`,
        'text9': `ZuluTrade No hidden costs icon`,
        'text10': `Nessun costo nascosto o limiti mensili di pagamento.`,
        'text11': `ZuluTrade multiple brokers icon`,
        'text12': `Piattaforma realizzata per supportare diversi broker.`,
        'text13': `ZuluTrade network icon`,
        'text14': `Vasto social trading network con oltre 90.000 leader.`
    },
    section4: {
        'text1': `Come`,
        'text2': `ZuluTrade`,
        'text3': `lavora per`,
        'text4': `leader?`,
        'text5': `Join ZuluTrade icon`,
        'text6': `Diventa uno vero Leader su ZuluTrade`,
        'text7': `Registrati a ZuluTrade come leader, collega il tuo conto e condividi le tue strategie di trading vincenti.`,
        'text8': `Rankings icon`,
        'text9': `Distinguiti e metti in mostra il successo`,
        'text10': `Il nostro sistema di classificazione tiene in considerazione performance, stabilità, comportamento e outlook.`,
        'text11': `Earnings icon`,
        'text12': `Guadagni senza stress`,
        'text13': `Come leader guadagni quando gli investitori copiano le tue operazioni, prendendo in considerazione dimensione dell’investimento e propensione al rischio.`
    },
    section5: {
        'text1': `Leader ZuluTrade:`,
        'text2': `Cosa fanno?`,
        'text3': `Un “leader” è un utente di ZuluTrade che condivide e consente ad altri utenti di copiare le proprie operazioni effettuate sul conto. Ogni azione di trading eseguita sul conto del leader viene inviata a tutti gli investitori del leader sotto forma di segnale.`,
        'text4': `Crea una community e fai da guida!`,
        'text5': `research icon`,
        'text6': `Ricerca`,
        'text7': `Effettua ricerche sul mercato, consulta le notizie e rimani aggiornato sulle novità dell’azienda.`,
        'text8': `intract icon`,
        'text9': `Interagisci`,
        'text10': `Interagisci con gli investitori e comunica aggiornamenti sulla tua strategia di trading.`,
        'text11': `broadcast icon`,
        'text12': `Trasmetti`,
        'text13': `Trasmetti segnali di trading agli investitori di ZuluTrade e ottieni una posizione in evidenza nella classifica del sito.`
    },
    section6: {
        'text1': `Come ci si registra a ZuluTrade come `,
        'text2': `leader?`,
        'text3': `Passo 1`,
        'text4': `Registrati come leader su ZuluTrade.`,
        'text5': `Passo 2`,
        'text6': `Rendi il conto pubblico e inizia a fare trading.`,
        'text7': `Passo 3`,
        'text8': `Gli investitori copiano la tua strategia e tu ricevi i guadagni.`,
        'text9': `Registrati su ZuluTrade`,
        'text10': `Compila automaticamente i tuoi dati personali usando`,
        'text11': `Google icon`,
        'text12': `o manualmente`,
        'text13': `Nome utente o email`,
        'text14': `Inserisci la password`,
        'text15': `Apri un conto`
    },
    second5: {
        'text1': `How to become`,
        'text2': `ZuluTrade Affiliate`,
        'text3': `in 3 easy steps?`,
        'text4': `1`,
        'text5': `Click on 'Become an Affiliate'.`,
        'text6': `2`,
        'text7': `Complete the registration and get your unique affiliate URL.`,
        'text8': `3`,
        'text9': `Invite your contacts using unique affiliate URL  and start earning.`,
        'text10': `One-Time Pin has been sent to your email. Please enter it below.`,
        'text11': `Enter OTP`,
        'text12': `Submit`,
        'text13': `Affiliates Account Registration`,
        'text14': `Enter Email Address`,
        'text15': `Enter Your Password`,
        'text16': `Confirm Password`,
        'text17': `Seleziona Paese di residenza`,
        'text18': `Company Full Name (For Corporate Accounts)`,
        'text19': `Enter Company Full Name`,
        'text20': `Company's Legal Representative`,
        'text21': `First Name`,
        'text22': `Last Name`,
        'text23': `I agree to the`,
        'text24': `Privacy Policy`,
        'text25': `Terms.`,
        'text26': `Register`,
        'text27': `Back to login`,
        'text28': `La negoziazione di strumenti finanziari comporta un notevole rischio di perdita. Per il completo disclaimer`,
        'text29': `clicca qui.`
    },
    affiliate: {
        'text1': `How to become`,
        'text2': `ZuluTrade Affiliate`,
        'text3': `in 3 easy steps?`,
        'text4': `1`,
        'text5': `Click on 'Become an Affiliate'.`,
        'text6': `2`,
        'text7': `Complete the registration and get your unique affiliate URL.`,
        'text8': `3`,
        'text9': `Invite your contacts using unique affiliate URL and start earning.`,
        'text10': `Verify your Email address`,
        'text11': `Enter the 6-digit verification code that was sent to`,
        'text12': `The code is valid for 10 minutes.`,
        'text13': `Resend Code`,
        'text14': `Submit`,
        'text15': `Affiliates Account Registration`,
        'text16': `Enter Email Address`,
        'text17': `Enter Your Password`,
        'text18': `Confirm Password`,
        'text19': `Seleziona Paese di residenza`,
        'text20': `Company Full Name (For Corporate Accounts)`,
        'text21': `Enter Company Full Name`,
        'text22': `First Name`,
        'text23': `Last Name`,
        'text24': `I agree to the`,
        'text25': `Privacy Policy`,
        'text26': `&`,
        'text27': `Terms.`,
        'text28': `Register`,
        'text29': `Thank`,
        'text30': `you!`,
        'text31': `Your account has created successfully.`,
        'text32': `La negoziazione di strumenti finanziari comporta un notevole rischio di perdita. Per il completo disclaimer`,
        'text33': `clicca qui.`,
    },
    zulutrade: {
        'text1': `Come ci si registra a ZuluTrade come`,
        'text2': `Passo 1`,
        'text3': `Passo 2`,
        'text4': `Passo 3`,
        'text5': `Registrati su ZuluTrade`,
        'text6': `Compila automaticamente i tuoi dati personali usando`,
        'text7': `o manualmente`,
        'text8': `E-mail`,
        'text9': `Parola d'ordine`,
        'text10': `Sicurezza della password`,
        'text11': `1 uppercase character`,
        'text12': `8 or more characters`,
        'text13': `at least 1 number`,
        'text14': `at least 1 letter`,
        'text15': `at least 1 special character`,
        'text16': `Loading...`,
        'text17': `Apri un conto`,
        'text18': `La negoziazione di strumenti finanziari comporta un notevole rischio di perdita. Per il completo disclaimer`,
        'text19': `clicca qui.`,
        'text20': `Nome di battesimo`,
        'text21': `Numero di telefono`,
        'text22': `Cognome`,
        'text23': `Seleziona Paese di residenza`,
        'text24': `Cerca qui..`,
        'text25': `Il paese è obbligatorio`,
        'text26': `Il numero di telefono è obbligatorio`,
    },
    // Investor page

    banner: {
        'text1': `Investire è facile con`,
        'text2': `CopyTrading e Social Trading!`,
        'text3': `Vai Zulù!`,
        'text4': `ZuluTrade è una delle piattaforme di social trading leader al mondo con oltre 90.000 leader professionisti e oltre 2 milioni di utenti attivi sulla piattaforma. Scopri i principali leader da tutto il mondo, copia la loro strategia e replica le loro operazioni in tempo reale con il tuo conto di investimento.`,
        'text5': `Inizia a investire`,
        'text6': `ZuluTrade Banner Image`
    },
    third1: {
        'text1': `Segui gli`,
        'text2': `esperti`,
        'text3': `per battere la`,
        'text4': `probabilità`,
        'text5': `nel mondo del trading con il Copytrading`,
        'text6': `Porta il tuo trading al prossimo livello con ZuluTrade al tuo fianco.`,
        'text7': `ZuluTrade Compare Leaders Image`,
        'text8': `Apri un conto demo e sfruttalo per imparare.`,
        'text9': `DEMO`,
        'text10': `Become an Affiliate`,
    },
    third2: {
        'text1': `Perché`,
        'text2': `ZuluTrade?`,
        'text3': `ZuluTrade transparent copy trading platform icon`,
        'text4': `La piattaforma di copy trading di più lunga data e più trasparente esistente`,
        'text5': `ZuluTrade license icon`,
        'text6': `ZuluTrade è regolamentata dalla HCMC nell’UE, dalla FSA in Giappone, dalla FSC a Mauritius e dalla FSCA in Sudafrica.`,
        'text7': `ZuluTrade investors profit icon`,
        'text8': `Puoi contattare il nostro rinomato servizio clienti 24/5 tramite chat, al telefono o per email.`,
        'text9': `ZuluTrade serves globally icon`,
        'text10': `Serve clienti in tutto il mondo da oltre 150 paesi.`,
        'text11': `Average winning ratio`,
        'text12': `Strumenti social che ti permettono di interagire con leader e altri investitori.`,
        'text13': `vast community`,
        'text14': `Una vasta community di oltre 2 milioni di utenti nel mondo.`
    },
    third3: {
        'text1': `Cosa rende ZuluTrade`,
        'text2': `unico?`,
        'text3': `ZuluTrade Unique Features Image`,
        'text4': `ZuluGuard`,
        'text5': `TM`,
        'text6': `Uno strumento di social trading smart e sicuro per il tuo investimento. ZuluGuard protegge il tuo capitale di investimento monitorando costantemente il tuo conto, prendendo i benefici e riducendo le perdite potenziali.`,
        'text7': `Più piattaforme`,
        'text8': `Puoi scegliere di fare trading sulle diverse piattaforme disponibili:`,
        'text9': `MT4, MT5, ActTrader, X Open Hub, Match-Trader.`,
        'text10': `Feed social`,
        'text11': `Interagisci con altri investitori e leader da tutto il mondo. Commenta, valuta, partecipa alle discussioni e cavalca i mercati sfruttando la saggezza di una community tutta social.`,
        'text12': `Inizia a fare copy trading ora`
    },
    third4: {
        'text1': `Come ci si registra a ZuluTrade come`,
        'text2': `investitore?`,
        'text3': `Passo 1`,
        'text4': `Gli investitori seguono i leader dopo aver analizzato la pagina dedicata alla performance.`,
        'text5': `Passo 2`,
        'text6': `Ogni volta che un leader effettua un’operazione, questa viene copiata anche sul conto dell’investitore.`,
        'text7': `Passo 3`,
        'text8': `Le operazioni profittevoli per il leader danno origine ad un profitto anche per l’investitore.`,
        'text9': `Apri un conto`,
        'text10': `La negoziazione di strumenti finanziari comporta un notevole rischio di perdita. Per il completo disclaimer`,
        'text11': `clicca qui.`
    }
}