export default {
    locales: {
        fil: "Filipino"
    },
    // leader page
    leader: {
        'text1': `ZuluTrade Logo`,
        'text2': `Mag-sign Up na Ngayon`,
        'text3': `Mag-sign Up`,
        'text4': `Mag-sign up bilang pinuno sa ZuluTrade.`,
        'text5': `Gawing pampubliko ang account at simulan ang pangangalakal.`,
        'text6': `Kinokopya ng mga mamumuhunan ang iyong diskarte at matatanggap mo ang iyong mga kita.`,
        'text7': `Pinuno`,
        'text8': `a`,
        'text9': `Sinusundan ng mga mamumuhunan ang mga pinuno pagkatapos suriin ang kanilang pahina ng pagganap.`,
        'text10': `Sa bawat oras na ang isang kalakalan ay ginawa ng isang pinuno, ito ay kinopya sa account ng mga mamumuhunan din.`,
        'text11': `Ang mga trade na kumikita para sa pinuno ay kumikita din para sa mamumuhunan.`,
        'text12': `mamumuhunan`,
        'text13': `isang`
    },
    pages: {
        'text1': `Sumali sa Nangunguna sa Mundo`,
        'text2': `Social Trading`,
        'text3': `Komunidad!`,
        'text4': `Kumita ng $5-$10 sa bawat $100K na tinrade. Ang halaga ng gastos ay depende sa mga pares ng currency na tinrade, uri ng account, atbp.`,
        'text5': `Maging bahagi ng isang pandaigdigang komunidad na may 2M+ gumagamit at 90k+ na leader sa nangungunang social trading platform.`,
        'text6': `Ibahagi ang iyong mga estratehiya, kumita ng mga komisyon sa mga kinopyang trade. Sumali na sa ZuluTrade ngayon!`,
        'text7': `Pinuno ng ZuluTrade`,
        'text8': `Magbukas ng Leader Account`,
        'text9': `Matuto ng Higit Pa`
    },
    second3: {
        'text1': `Enjoy Flexible`,
        'text2': `Compensation Schemes`,
        'text3': `Our default monetization model is CPA, but we are open to discussing more options for established affiliates, like hybrid and custom deals if they can deliver large volumes of customers. Our CPA playouts are categorized into Tiers, depending on the Geolocation of the accounts referred by our affiliates.`,
        'text4': `Maximize your earnings with our affiliate program. Earn up to 300$ per investor introduced, and claim even more with our hybrid custom CPA model`,
        'text5': `Become an Affiliate`,
        'text6': `Matuto ng Higit Pa`,
        'text7': `Affiliate Zulu Coin`
    },
    header: {
        'text12': `ZuluTrade's`,
    },
    section2: {
        'text1': `Mga Nangunguna sa Pagganap`,
        'text2': `Mga estratehiyang may positibong momentum at gumagalaw nang malapit sa mga ATH (All Time Highs)`,
        'text3': `Mga tagakopya`,
        'text4': `Mga Tagasunod`,
        'text5': `Sumali sa Mga Pinakamahusay sa kanila`
    },
    section3: {
        'text1': `Bakit Sumali sa ZuluTrade bilang isang`,
        'text2': `Leader?`,
        'text3': `ZuluTrade licence icon`,
        'text4': `Pinangangasiwaan ang ZuluTrade ng HCMC sa EU, ng FSA sa Japan, ng FSC sa Mauritius at ng FSCA sa South Africa.`,
        'text5': `ZuluTrade advanced and feature rich icon`,
        'text6': `Advanced platform na may maraming tampok upang i-customize ang iyong karanasan sa pagte-trade.`,
        'text7': `ZuluTrade support icon`,
        'text8': `Palakasin ang kakayahang kumita salamat sa isang dedikadong traders desk upang makahikayat at mapanatili ang mga mamumuhunan.`,
        'text9': `ZuluTrade No hidden costs icon`,
        'text10': `Walang nakatagong gastos o buwanang limitasyon sa payout.`,
        'text11': `ZuluTrade multiple brokers icon`,
        'text12': `Platform na ginawa para suportahan ang iba’t-ibang broker.`,
        'text13': `ZuluTrade network icon`,
        'text14': `Malawak na social trading network na may 90k+ Leader.`
    },
    section4: {
        'text1': `Paano`,
        'text2': `ZuluTrade`,
        'text3': `gumagana para sa`,
        'text4': `Leaders?`,
        'text5': `Join ZuluTrade icon`,
        'text6': `BMaging isang tunay na Leader sa ZuluTrade`,
        'text7': `Sumali sa ZuluTrade bilang isang leader, ikonekta ang iyong account, at ibahagi ang iyong mga panalong estratehiya sa pagte-trade!`,
        'text8': `Rankings icon`,
        'text9': `Mangibabaw at Magtagumpay`,
        'text10': `Isinasaalang-alang ng aming ranking system ang pagganap, katatagan, pag-uugali at pananaw. `,
        'text11': `Earnings icon`,
        'text12': `Walang Stress na Kita`,
        'text13': `Bilang isang leader, kikita ka kapag kokopyahin ng mga mamumuhunan ang iyong trade, habang isinasaalang-alan ang laki ng kanilang pamumuhunan at toleransiya sa panganib.`
    },
    section5: {
        'text1': `Pinuno ng ZuluTrades:`,
        'text2': `Ano ang ginagawa nila?`,
        'text3': `Ang isang ‘Leader’ ay isang gumagamit ng ZuluTrade, na ibinabahagi at pinahihintulutan ang ibang mga gumagamit na kopyahin ang kanyang mga trade sa kanilang mga account. Ang bawat pagkilos sa pagte-trade na isinagawa sa account ng Leader ay ipapadala sa lahat ng Mga Mamumuhunan ng Leader bilang isang signal na ibo-broadcast.`,
        'text4': `Bumuo ng isang komunidad at pamunuan sila!`,
        'text5': `research icon`,
        'text6': `Saliksikin`,
        'text7': `ang pamilihan, balita at mga update sa kompanya nang panayan.`,
        'text8': `intract icon`,
        'text9': `Makihalubilo`,
        'text10': `sa Mga Mamumuhunan at ipagbigay-alam ang mga update tungkol sa estratehiya sa pagte-trade.`,
        'text11': `broadcast icon`,
        'text12': `I-broadcast`,
        'text13': `ang mga signal sa pagte-trade sa mga Mamumuhunan ng ZuluTrade at lumabas sa mga ranking ng website.`
    },
    section6: {
        'text1': `Paano sumali sa ZuluTrade bilang isang `,
        'text2': `Leader?`,
        'text3': `Ika-1 Hakbang`,
        'text4': `Mag-sign up bilang isang leader sa ZuluTrade.`,
        'text5': `Ika-2 Hakbang`,
        'text6': `Gawing publiko ang account at magsimulang mag-trade.`,
        'text7': `Ika-3 Hakbang`,
        'text8': `Kokopyahin ng mga mamumuhunan ang iyong estratehiya at matatanggap mo ang iyong kita.`,
        'text9': `Sumali sa amin sa ZuluTrade`,
        'text10': `I-auto-fill ang iyong mga personal na detalye gamit ang`,
        'text11': `Google icon`,
        'text12': `o manwal na punan`,
        'text13': `Username o email`,
        'text14': `Ilagay ang Password`,
        'text15': `Gumawa ng Account`
    },
    second5: {
        'text1': `How to become`,
        'text2': `ZuluTrade Affiliate`,
        'text3': `in 3 easy steps?`,
        'text4': `1`,
        'text5': `Click on 'Become an Affiliate'.`,
        'text6': `2`,
        'text7': `Complete the registration and get your unique affiliate URL.`,
        'text8': `3`,
        'text9': `Invite your contacts using unique affiliate URL  and start earning.`,
        'text10': `One-Time Pin has been sent to your email. Please enter it below.`,
        'text11': `Enter OTP`,
        'text12': `Submit`,
        'text13': `Affiliates Account Registration`,
        'text14': `Enter Email Address`,
        'text15': `Enter Your Password`,
        'text16': `Confirm Password`,
        'text17': `Piliin ang Bansa ng Paninirahan`,
        'text18': `Company Full Name (For Corporate Accounts)`,
        'text19': `Enter Company Full Name`,
        'text20': `Company's Legal Representative`,
        'text21': `First Name`,
        'text22': `Last Name`,
        'text23': `I agree to the`,
        'text24': `Privacy Policy`,
        'text25': `Terms.`,
        'text26': `Register`,
        'text27': `Back to login`,
        'text28': `Ang pangangalakal sa mga instrumento sa pananalapi ay nagsasangkot ng malaking panganib ng pagkalugi. Para sa buong disclaimer`,
        'text29': `pindutin dito.`
    },
    affiliate: {
        'text1': `How to become`,
        'text2': `ZuluTrade Affiliate`,
        'text3': `in 3 easy steps?`,
        'text4': `1`,
        'text5': `Click on 'Become an Affiliate'.`,
        'text6': `2`,
        'text7': `Complete the registration and get your unique affiliate URL.`,
        'text8': `3`,
        'text9': `Invite your contacts using unique affiliate URL and start earning.`,
        'text10': `Verify your Email address`,
        'text11': `Enter the 6-digit verification code that was sent to`,
        'text12': `The code is valid for 10 minutes.`,
        'text13': `Resend Code`,
        'text14': `Submit`,
        'text15': `Affiliates Account Registration`,
        'text16': `Enter Email Address`,
        'text17': `Enter Your Password`,
        'text18': `Confirm Password`,
        'text19': `Piliin ang Bansa ng Paninirahan`,
        'text20': `Company Full Name (For Corporate Accounts)`,
        'text21': `Enter Company Full Name`,
        'text22': `First Name`,
        'text23': `Last Name`,
        'text24': `I agree to the`,
        'text25': `Privacy Policy`,
        'text26': `&`,
        'text27': `Terms.`,
        'text28': `Register`,
        'text29': `Thank`,
        'text30': `you!`,
        'text31': `Your account has created successfully.`,
        'text32': `Ang pangangalakal sa mga instrumento sa pananalapi ay nagsasangkot ng malaking panganib ng pagkalugi. Para sa buong disclaimer`,
        'text33': `pindutin dito.`,
    },
    zulutrade: {
        'text1': `Paano sumali sa ZuluTrade bilang isang `,
        'text2': `Ika-1 Hakbang`,
        'text3': `Ika-2 Hakbang`,
        'text4': `Ika-3 Hakbang`,
        'text5': `Sumali sa amin sa ZuluTrade`,
        'text6': `I-auto-fill ang iyong mga personal na detalye gamit ang`,
        'text7': `o manwal na punan`,
        'text8': `Email`,
        'text9': `Password`,
        'text10': `Lakas ng Password`,
        'text11': `1 uppercase character`,
        'text12': `8 or more characters`,
        'text13': `at least 1 number`,
        'text14': `at least 1 letter`,
        'text15': `at least 1 special character`,
        'text16': `Loading...`,
        'text17': `Gumawa ng Account`,
        'text18': `Ang pangangalakal sa mga instrumento sa pananalapi ay nagsasangkot ng malaking panganib ng pagkalugi. Para sa buong disclaimer`,
        'text19': `pindutin dito.`,
        'text20': `Pangalan`,
        'text21': `Numero ng telepono`,
        'text22': `Huling pangalan`,
        'text23': `Piliin ang Bansa ng Paninirahan`,
        'text24': `Maghanap dito..`,
        'text25': `Kinakailangan ang bansa`,
        'text26': `Kinakailangan ang numero ng telepono`,
    },
    // Investor page

    banner: {
        'text1': `Naging madali ang pamumuhunan sa`,
        'text2': `CopyTrading and Social Trading!`,
        'text3': `Sige Zulu!`,
        'text4': `ZuluTrade ay isa sa nangungunang social trading platform sa mundo na may 90k+ pro leader at 2M+ aktibong gumagamit sa platform. Tuklasin ang mga nangungunang leader mula sa buong mundo, kopyahin ang kanilang estratehiya at gayahin ang kanilang mga trade sa real-time sa iyong investment account.`,
        'text5': `Magsimulang Mamuhuna`,
        'text6': `ZuluTrade Banner Image`
    },
    third1: {
        'text1': `Sundin ang`,
        'text2': `eksperto`,
        'text3': `at talunin ang`,
        'text4': `posibilidad`,
        'text5': `sa mundo ng pangangalakal sa Copy Trading`,
        'text6': `Dalhin ang iyong paglalakbay sa pagte-trade sa susunod na antas gamit ang ZuluTrade.`,
        'text7': `ZuluTrade Compare Leaders Image`,
        'text8': `Magbukas ng isang Demo account at maglaro para matuto!`,
        'text9': `DEMO`,
        'text10': `Become an Affiliate`,
    },
    third2: {
        'text1': `Bakit`,
        'text2': `ZuluTrade?`,
        'text3': `ZuluTrade transparent copy trading platform icon`,
        'text4': `Pinakaluma at pinaka transparent na copy trading platform.`,
        'text5': `ZuluTrade license icon`,
        'text6': `Pinangangasiwaan ang ZuluTrade ng HCMC sa EU, ng FSA sa Japan, ng FSC sa Mauritius at ng FSCA sa South Africa.`,
        'text7': `ZuluTrade investors profit icon`,
        'text8': `Maaaring makipag-ugnayan sa aming Serbisyo sa Customer na may napakagandang rating nang 24/5 sa pamamagitan ng chat, telepono o email!`,
        'text9': `ZuluTrade serves globally icon`,
        'text10': `Naglilingkod sa buong daigdig sa higit sa 150 bansa.`,
        'text11': `Average winning ratio`,
        'text12': `Pinahihintulutan kayo ng mga panlipunang kagamitan na makihalubilo sa mga leader at ibang mga mamumuhunan.`,
        'text13': `vast community`,
        'text14': `Isang malawak na komunidad na may 2M+ gumagamit sa buong daigdig.`
    },
    third3: {
        'text1': `Ano ang ginagawa ng ZuluTrade`,
        'text2': `kakaiba?`,
        'text3': `ZuluTrade Unique Features Image`,
        'text4': `ZuluGuard`,
        'text5': `TM`,
        'text6': `Isang matalino at ligtas na kagamitan sa social trading para sa iyong pamumuhunan . Pinangangalagaan ng ZuluGuard ang iyong kapital ng pamumuhunan sa pamamagitan ng patuloy na pagsusubaybay sa iyong account, pag-secure sa tubo at pagbabawas sa potensyal na pagkalugi.`,
        'text7': `Iba’t-Ibang Platform`,
        'text8': `Maaari kang pumiling mag-trade mula sa iba’t-ibang platform na available:`,
        'text9': `MT4, MT5, ActTrader, X Open Hub, Match-Trader.`,
        'text10': `Social Feed`,
        'text11': `Makihalubilo sa ibang mga mamumuhunan at leader sa buong mundo. Magkomento, i-rate at makilahok sa talakayan at mapaamo ang pamilihan gamit ang karunungan ng panlipunang komunidad.`,
        'text12': `Magsimula nang Mag-CopyTrading Ngayon`
    },
    third4: {
        'text1': `Paano sumali sa ZuluTrade bilang isang`,
        'text2': `Mamumuhunan?`,
        'text3': `Ika-1 Hakbang`,
        'text4': `Sumusunod ang mga mamumuhunan sa mga leader pagkatapos nilang suriin ang kanilang pahina ng pagganap.`,
        'text5': `Ika-2 Hakbang`,
        'text6': `Bawat beses na magsasagawa ang isang leader ng isang trade, kokopyahin din ito sa account ng mamumuhunan.`,
        'text7': `Ika-3 Hakbang`,
        'text8': `Ang mga trade na kikita para sa leader ay kikita rin para sa mamumuhunan.`,
        'text9': `Gumawa ng Account`,
        'text10': `Ang pangangalakal sa mga instrumento sa pananalapi ay nagsasangkot ng malaking panganib ng pagkalugi. Para sa buong disclaimer`,
        'text11': `pindutin dito.`
    }
}