import axios from 'axios'
import { useToast } from 'vue-toastification'
import { myStore } from '@/store/pinia'
import static_vars from '@/staticVars.js'
const Buffer = require('buffer/').Buffer
const toast = useToast()

export const post = (url, params, token) => {
    return new Promise((resolve, reject) => {
        const store = myStore()
        const headers = {}
        if (store.user.access_token) {
            headers['Authorization'] = `Bearer ${store.user.access_token}`
        }
        if (token) { //for login api 
            headers['Authorization'] = token == 'basic' ? `Basic ${Buffer.from(`${static_vars.CLIENT_ID}:${static_vars.CLIENT_SECRET}`).toString('base64')}` : `Bearer ${store.user.access_token}`
        }
        headers['Content-Type'] = (token && token == 'basic') ? 'application/x-www-form-urlencoded' : 'application/json'
        headers['Accept'] = 'application/json'
        headers['Content-Encoding'] = 'gzip, deflate, br'
        axios.post(
            url,
            params,
            { headers }
        ).then(response => {
            if (response.data.status && response.data.status === 404) {
                toast(response.data.message, {
                    type: 'error'
                })
                reject(response.data)
            } else {
                resolve(response.data)
            }
        }).catch(error => {
            if (error.status && error.status === 401) {
                toast(error.response.data.message, {
                    type: 'error'
                })
                reject(error)
            } else {
                // if (url != 'https://www.zulutrade.com/zulutrade-client/api/customer/register') {
                    
                // }
                if (error.response && error.response.data) {
                    toast(error.response.data.message, {
                        type: 'error'
                    })
                }
                reject(error)
            }
        })
    })
}

export const get = (url, token) => {
    return new Promise((resolve, reject) => {
        const store = myStore()
        const headers = {}
        if (store.user.access_token) {
            headers['Authorization'] = `Bearer ${store.user.access_token}`
        }
        if (token) {
            headers['Authorization'] = token == 'basic' ? `Basic ${Buffer.from(`${static_vars.CLIENT_ID}:${static_vars.CLIENT_SECRET}`).toString('base64')}` : `Bearer ${store.user.access_token}`
        }
        axios.get(
            url,
            { headers }
        ).then(response => {
            if (response.info && response.info == 'NOK') {
                toast(response.data.message, {
                    type: 'error'
                })
                reject(response)
            } else {
                resolve(response.data)
            }
        }).catch(error => {
            if (error.response && error.response.status && error.response.status === 401) {
                // handleUnauthorizedToken()
                toast(error.response.data.message, {
                    type: 'error'
                })
                reject(error)
            } else {
                if (error?.response?.data?.message) {
                    toast(error.response.data.message, {
                        type: 'error'
                    })
                } else {
                    toast(error?.response?.data?.error, {
                        type: 'error'
                    })
                }
                reject({ ...error })
            }
        })
    })
}

// export const DELETE = (url,params) => {
//     try{
//         return new Promise((resolve, reject) => {
//             const store = myStore()
//             const headers = {}
//             if (store.user.access_token) {
//                 headers['Authorization'] = `Bearer ${store.user.access_token}`
//             }
//             headers['Content-Type'] = 'application/json'
//             headers['Accept'] = 'application/json'
//             headers['Content-Encoding'] = 'gzip, deflate, br'
//             // headers["Referer"] = window.location.href
//             // headers["Referrer-Policy"] =  "strict-origin-when-cross-origin"
//             if(static_vars.getCookie('_ga')){
//                 headers['Cookie'] = document.cookie
//             }
//             axios.delete(url,{
//                 data : params,
//                 headers 
//             }).then(response => {
//                 if (response.data.status && response.data.status === 404) {
//                     toast(response.data.message, {
//                         type: 'error'
//                     })
//                     reject(response.data)
//                 } else {
//                     resolve(response.data)
//                 }
//             }).catch(error => {
//                 if (error.status && error.status === 401) {
//                     // handleUnauthorizedToken()
//                      toast(error.response.data.message, {
//                     type: 'error'
//                 })
//                     reject(error)
//                 } else {
//                     toast('Something went wrong. Please try again', {
//                         type: 'error'
//                     })
//                     reject(error)
//                 }
//             })
//         })
//     }catch(e){
//         console.log('e',e)
//     }
// }

export const put = (url, params, token) => {
    return new Promise((resolve, reject) => {
        const store = myStore()
        const headers = {}
        if (store.user.access_token) {
            headers['Authorization'] = `Bearer ${store.user.access_token}`
        }
        if (token) {//for login api 
            headers['Authorization'] = token == 'basic' ? `Basic ${Buffer.from(`${static_vars.CLIENT_ID}:${static_vars.CLIENT_SECRET}`).toString('base64')}` : `Bearer ${store.user.access_token}`
        }
        headers['Content-Type'] = (token && token == 'basic') ? 'application/x-www-form-urlencoded' : 'application/json'
        headers['Accept'] = 'application/json'
        headers['Content-Encoding'] = 'gzip, deflate, br'
        axios.put(
            url,
            params,
            { headers }
        ).then(response => {
            if (response.data.status && response.data.status === 404) {
                toast(response.message, {
                    type: 'error'
                })
                reject(response.data)
            } else {
                resolve(response.data)
            }
        }).catch(error => {
            if (error.status && error.status === 401) {
                toast(error.response.data.message, {
                    type: 'error'
                })
                reject(error)
            } else {
                toast(error.response.data.message, {
                    type: 'error'
                })
                reject(error)
            }
        })
    })
}