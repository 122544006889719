<template>
<section class="top-perform-leaders pt40 pb80">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="section-disc text-center">
                    <h2>{{$t('header.text12')}} <span>{{$t('section2.text1')}}</span></h2>
                    <p class="sec-sub-heading">{{$t('section2.text2')}}</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12" v-if="Object.keys(store.topTrdaersList).length > 0 && store.topTrdaersList[0].result && store.topTrdaersList[0].result.length">
                <div class="top-trader-slider">
                    <div class="topTrade-slideCol" v-for="item, index in  store.topTrdaersList[0].result.slice(0,5)" :key="index">
                        <div class="topTrade-slide-info" v-show="item.trader && Object.keys(item.trader).length">
                            <div class="card-body px-0 pb-0" v-show="item.trader.profile && Object.keys(item.trader.profile).length > 0">
                                <div class="vueRisk" v-show="item.trader.profile.name">
                                    <div class="bTn mb-0 f-12 bold uppercase text-center">
                                        <a class="d-block" target="_blank" :href="`${static_vars.domainURL}/trader/${item.trader.profile.id}/trading?t=${Object.keys(item.trader.timeframeStats)[0]}&m=1`">
                                            <span class="circleImg d-inline-block mb-3">
                                                <img class="img-fluid" :src="(!item.trader.profile.photoApproved) ? (item.trader.profile.customerAvatarUrl) ? item.trader.profile.customerAvatarUrl : static_vars.imageURL + '/webservices/Image.ashx?type=user&size=XL&id=' + item.trader.profile.zuluAccountId + '&ignore=false' : static_vars.imageURL + '/webservices/Image.ashx?type=user&size=XL&id=' + item.trader.profile.zuluAccountId + '&ignore=false'" :alt="item.trader.profile.name" :title="item.trader.profile.name" />
                                            </span>
                                        </a>
                                        <p class="bTn mb-2 f-12 bold uppercase" :class="[{'blueBtn':item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].timeFrameRisk==1},{'greenBtn':item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].timeFrameRisk==2},{'yellowBtn':item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].timeFrameRisk==3},{'redBtn':item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].timeFrameRisk==4},{'pinkBtn':item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].timeFrameRisk==5}]" v-if="item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].hasOwnProperty('timeFrameRisk')">
                                            Risk {{item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].timeFrameRisk}}
                                        </p>
                                    </div>
                                    <a class="text-center" target="_blank" :href="`${static_vars.domainURL}/trader/${item.trader.profile.id}/trading?t=${Object.keys(item.trader.timeframeStats)[0]}&m=1`">
                                        <div class="trade-name">
                                            <h6 class="mb-1 f-16">{{ item.trader.profile.name }}</h6>
                                            <div class="trade-folorwers">
                                                <div class="element d-flex align-items-center justify-content-center me-2" v-if="item.trader.overallStats.followers">
                                                    <p class="moregray f-12 mb-0 me-1">{{$t('section2.text3')}}</p>
                                                    <p class="f-12 medium mb-0">{{ item.trader.overallStats.followers || 0}}</p>
                                                </div>
                                                <div class="element d-flex align-items-center justify-content-center" v-if="item.trader.overallStats && item.trader.overallStats.includedInWatchlist">
                                                    <p class="moregray f-12 mb-0 me-1">{{$t('section2.text4')}}</p>
                                                    <p class="f-12 medium mb-0">{{ item.trader.overallStats.includedInWatchlist || 0 }}</p>
                                                </div>
                                            </div>
                                            <div class="trade-graph-digit">
                                                <!-- <p class="f-12 mb-0">ROI</p> -->
                                                <p class="mb-0 px-1 bold d-inline-flex" :class="ROIClass(item)" v-if="item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].hasOwnProperty('annualizedRorBasedRoi')">
                                                    {{parseInt(item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].annualizedRorBasedRoi) >= 0 ? '+' : '-'}}{{
                                                    parseFloat(item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].annualizedRorBasedRoi).toFixed(1) || 0 }}%
                                                </p>
                                            </div>
                                        </div>
                                    </a>
                                    <div class="trade-graph-img chartElement" :id="'topTraders' + item.trader.profile.zuluAccountId + '_' + (store.topTrdaersList[0].order ? store.topTrdaersList[0].order : 1)" style="width: 100%; height: 126px; display: flex;"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="f-flex banner-links text-center">
                    <a :href="`${static_vars.domainURL}/leaders`" target="_blank" class="commonBtn linkBtn"><span>{{$t('section2.text5')}}</span></a>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import {
    Carousel,
    Slide,
    Pagination,
    Navigation
} from "vue3-carousel";
import {
    myStore
} from "@/store/pinia";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
export default {
    setup() {
        const store = myStore();
        return {
            store
        };
    },
    data() {
        return {};
    },
    watch: {
        'store.flavordata'() {
            if (Object.keys(this.store.flavordata).length && this.store.flavordata.flavorId) {
                this.getTopTradersList();
            }
        },
    },
    methods: {
        getTopTradersList() {
            this.store.callTopTradersList({}, false).then(() => {
                if (Object.keys(this.store.topTrdaersList).length && this.store.topTrdaersList[0].result && this.store.topTrdaersList[0].result.length) {
                    this.store.topTrdaersList[0].result.slice(0, 5).forEach((item) => {
                        if (
                            item.trader &&
                            Object.keys(item.trader).length &&
                            item.trader.profile &&
                            Object.keys(item.trader.profile).length &&
                            item.trader.profile.zuluAccountId &&
                            item.trader.timeFrameGraph?.series?.length &&
                            item.trader.timeFrameGraph.series[0].data &&
                            item.trader.timeFrameGraph.series[0].data.length
                        ) {
                            am5.ready(() => {
                                this.tradersChart(item.trader.profile.zuluAccountId + "_" + (this.store.topTrdaersList[0].order ? this.store.topTrdaersList[0].order : 1), item.trader.timeFrameGraph.series[0].data);
                            });
                        }
                    });
                }
            });
        },
        tradersChart(id, chartdata) {
            let chartId = "topTraders" + id;
            let d = am5.registry.rootElements.find((i) => i.dom && i.dom.id === chartId);
            d?.dispose();
            let root = am5.Root.new(chartId);

            root.setThemes([am5themes_Animated.new(root)]);
            root.numberFormatter.set("numberFormat", "#,###.00");

            root.dateFormatter.setAll({
                dateFormat: "dd MMM yyyy",
                dateFields: ["valueX"],
            });

            // Create chart
            var chart = root.container.children.push(
                am5xy.XYChart.new(root, {
                    focusable: true,
                    paddingLeft: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                })
            );

            // Create axes
            let xAxis = chart.xAxes.push(
                am5xy.DateAxis.new(root, {
                    visible: false,
                    groupData: true,
                    baseInterval: {
                        timeUnit: "day",
                        count: 1,
                    },
                    renderer: am5xy.AxisRendererX.new(root, {
                        minGridDistance: 10,
                    }),
                    // tooltip: am5.Tooltip.new(root, {})
                })
            );

            let yAxis = chart.yAxes.push(
                am5xy.ValueAxis.new(root, {
                    visible: false,
                    strictMinMax: true,
                    baseValue: -1000,
                    renderer: am5xy.AxisRendererY.new(root, {}),
                })
            );
            xAxis.get("renderer").grid.template.set("forceHidden", true);
            yAxis.get("renderer").grid.template.set("forceHidden", true);

            chart.get("colors").set("colors", [am5.color(0xff7200)]);

            // Add series
            var series = chart.series.push(
                am5xy.SmoothedXLineSeries.new(root, {
                    minBulletDistance: 10,
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: "y",
                    valueXField: "x",
                    tooltip: am5.Tooltip.new(root, {
                        // pointerOrientation: "horizontal",
                        labelText: "[bold]Date[/]: {valueX.formatDate('dd MMM yyyy')}  \n [bold]ROI(%)[/] : {valueY}",
                    }),
                })
            );
            series.fills.template.setAll({
                visible: true,
            });
            series.fills.template.set(
                "fillGradient",
                am5.LinearGradient.new(root, {
                    stops: [{
                            opacity: 0.1,
                        },
                        {
                            opacity: 0.04,
                        },
                    ],
                })
            );
            series.strokes.template.setAll({
                strokeWidth: 2,
            });
            // Set up data processor to parse string dates
            series.data.processor = am5.DataProcessor.new(root, {
                dateFormat: "yyyy-MM-dd",
                dateFields: ["x"],
            });
            let cursor = chart.set(
                "cursor",
                am5xy.XYCursor.new(root, {
                    // xAxis: xAxis,
                    // behavior: "zoomX"
                })
            );
            cursor.lineY.set("visible", false);
            cursor.lineX.set("visible", false);
            xAxis.get("renderer").labels.template.setAll({
                fill: root.interfaceColors.set("fill", am5.color("#c9bebe")),
                fontSize: 13,
            });
            yAxis.get("renderer").labels.template.setAll({
                fill: root.interfaceColors.set("fill", am5.color("#c9bebe")),
                fontSize: 13,
            });
            // Set data
            var data = chartdata;
            series.data.setAll(data);
            // Make stuff animate on load
            series.appear(1000);
            chart.appear(1000, 100);
        },
        ROIClass(item) {
            if (parseInt(item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].annualizedRorBasedRoi) >= 0) {
                return "greenView";
            } else {
                return "redView";
            }
        },
    },
    mounted() {
        if (Object.keys(this.store.flavordata).length && this.store.flavordata.flavorId) {
            this.getTopTradersList();
        }
    },
};
</script>
