<template>
    <section class="become-zulutrade-leader pt80 pb80 easy-steps-section">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="section-disc text-center">
                        <h2 id="langAR">{{$t('section4.text1')}} <span>{{$t('section4.text2')}} </span> {{$t('section4.text3')}} <span>{{$t('section4.text4')}}</span></h2>
                    </div>
                </div>
            </div>
            <div class="row zuluTrade-leader-row">
                <div class="col-md-4 col-12">
                    <div class="easy-step-col">
                        <div class="easy-step-col-img">
                        <img class="img-fluid" src="../../assets/images/zuluImages/step-img1.svg" :alt="$t('section4.text5')">
                        </div>
                        <div class="easy-step-info">
                        <h3>{{$t('section4.text6')}}</h3>
                        <p>{{$t('section4.text7')}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-12">
                    <div class="easy-step-col">
                        <div class="easy-step-col-img">
                        <img class="img-fluid" src="../../assets/images/zuluImages/step-img2.svg" :alt="$t('section4.text8')">
                        </div>
                       <div class="easy-step-info">
                        <h3>{{$t('section4.text9')}}</h3>
                        <p>{{$t('section4.text10')}}  </p>
                    </div>
                        
                    </div>
                </div>
                <div class="col-md-4 col-12">
                    <div class="easy-step-col">
                        <div class="easy-step-col-img">
                        <img class="img-fluid" src="../../assets/images/zuluImages/step-img3.svg" alt="$t('section4.text11')">
                        </div>
                        <div class="easy-step-info">
                        <h3>{{$t('section4.text12')}}</h3>
                        <p>{{$t('section4.text13')}}</p>
                        </div>
                        
                    </div>
                 </div>
                </div>
           
            <div class="row">
                <div class="col-md-12 col-12">
                    <div class="f-flex banner-links text-center">
                        <a href="#bannerForm" class="commonBtn linkBtn"><span>{{$t('pages.text8')}}</span></a>
                        <!-- <a href="https://www.zulutrade.com/social-feed" target="_blank" class="commonBtn linkBtn"><span>Join our Community</span></a> -->
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script >
export default {
    name: 'SectionForth'
}
</script>
