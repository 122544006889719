<template>
<div>
  <header>
        <div class="container">
            <div class="row align-items-center">
            <div class="col-12">
                <div class="header-logo">
                <img class="img-fluid" src="../../assets/images/zuluTradeV1images/zulutradeLogo.svg" :alt="$t('leader.text1')">
                </div>
            </div>
            <!-- <div class="col-lg-6 col-md-6 col-6">
                <div class="signUpBtn text-end">
                <a href="#registerForm" class="commonBtn linkBtn btn-minWidth">
                    <span>{{$t('leader.text3')}}</span>
                </a>
                </div>
            </div> -->
            </div>
        </div>
    </header>
    <sectionOne />
    <sectionScnd />
    <sectionThird />
    <sectionForth />
    <sectionFifth />
</div>
</template>
<script>
import { RouterLink, RouterView } from 'vue-router'
import sectionOne from './pageComponents/sectionOne.vue'
import sectionScnd from './pageComponents/sectionScnd.vue'
import sectionThird from './pageComponents/sectionThird.vue'
import sectionForth from './pageComponents/sectionForth.vue'
import sectionFifth from './pageComponents/sectionFifth.vue'
export default {
  components: { sectionOne, sectionScnd, sectionThird, sectionForth, sectionFifth },
}
</script>

