// export const AUTH_BASE_URL = process.env.VUE_APP_AUTH_BASE_URL
export const GLOBAL_BASE_URL = process.env.VUE_APP_API_BASE_URL
export const EP_USER_LOGIN = `${GLOBAL_BASE_URL}auth-server/oauth/token`
export const EP_REGISTRATION = `${GLOBAL_BASE_URL}v2/api/customer/register`
export const EP_RESEND_OTP = `${GLOBAL_BASE_URL}customers/api/customer/verification/email/send`
export const EP_VERIFY_OTP = `${GLOBAL_BASE_URL}customers/api/customer/verification/email/validate`
export const EP_GET_CUSTOMER_DETAIL = `${GLOBAL_BASE_URL}api/customer`
export const EP_GET_CUSTOMER_DETAILS_AFFILIATE = `${GLOBAL_BASE_URL}customers/api/customer/details`
export const EP_GET_LOGIN_FLAVOR_LIST = `${GLOBAL_BASE_URL}flavors/api/flavors/light/secure`
export const EP_GET_FLAVOR_LIST = `${GLOBAL_BASE_URL}flavors/api/flavors/detect/light`
export const EP_GET_TOP_TRADER_LIST = `${GLOBAL_BASE_URL}api/providers/performance/topTraders/search?flavorId={{flavorId}}&accessingFlavorId={{accessingFlavorId}}`
export const EP_GET_COUNTRY_LIST = `${GLOBAL_BASE_URL}utils/api/countries?getOnlyEuMmber=false`
export const EP_AFFILATE_LANDED = `${GLOBAL_BASE_URL}attributions/api/attribution/landed`
export const EP_SEND_SMS_MOBILE = `${GLOBAL_BASE_URL}customers/api/customer/verification/sms/send`
export const EP_VERIFY_MOBILE_OTP = `${GLOBAL_BASE_URL}customers/api/customer/verification/sms/validate`
