<template>
    <div>
        <section class="enjoy-flexible pt80 pb80" id="registerForm">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="sectionDisc text-center">
                            <h2>{{$t('affiliate.text1')}} <span>{{$t('affiliate.text2')}}</span> {{$t('affiliate.text3')}}</h2>
                        </div>
                    </div>
                </div>
                <div class="row align-items-center mt-5">
                    <div class="col-md-4 col-12">
                        <div class="signUp-step">
                            <span>{{$t('affiliate.text4')}}</span>
                            <h3>{{$t('affiliate.text5')}}</h3>
                        </div>
                    </div>
                    <div class="col-md-4 col-12">
                        <div class="signUp-step">
                            <span>{{$t('affiliate.text6')}}</span>
                            <h3>{{$t('affiliate.text7')}}</h3>
                        </div>
                    </div>
                    <div class="col-md-4 col-12">
                        <div class="signUp-step">
                            <span>{{$t('affiliate.text8')}}</span>
                            <h3>{{$t('affiliate.text9')}}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="footerBar">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <p class="zuluDisclaimerTitle">{{$t('third4.text10')}} <a class="underline" target="_blank" :href="`${static_vars.domainURL}/risk-disclaimer`">{{$t('third4.text11')}}</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import {
    required,
    email,
    minLength,
    maxLength,
    sameAs,
    minValue,
    maxValue,
} from "@vuelidate/validators";
import Countrylist from "@/assets/js/country_list.js";
import axios from 'axios';
export default {
    name: 'sectionFifth',
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            submitted: false,
            regerror: "",
            Countrylist: Countrylist,
            email : '',
            password : '',
            confirm_password: '',
            country : "",
            mobile_number :'',
            phonecode : '',
            accType:'1',
            company:'',
            fname:'',  
            lname:'',
            accTypeList:{
                '1':'Individual',
                '2':'Corporate'
            },
            privacyPolicyLink: `${static_vars.domainURL}/privacy-policy`,
            // IBREFERLINK: "",
            otp_error: '',
            otp: '',
        }
    },
    validations() {
        return {
            confirmterms: {
                required
            },
            country: {
                required,
            },
            email: { 
                required, 
                email 
            },
            password: {
                required,
                min: minLength(6) 
            },
            confirm_password: {
                required,
                sameAsPassword: sameAs(this.password) 
            },
            mobile_number: {
                required,
                min: minLength(10) 
            },
            accType: {
                required
            },
            company: {
                required
            },
            fname: {
                required
            },
            lname: {
                required
            }
        };
    },
    methods: {
        onInput(e) {
            this.mobile_number = e.target.value;
        },
        GetCode(e) {
            this.phonecode = e.dialCode;
        },
        create_lead() {
            alert("hiiiiiiiiiiiii")
            const _that = this;
            _that.submitted = true;
            if(_that.confirm_password == _that.password && _that.Checkbox) {
                let input_json = {
                    globalParams: {
                        email: _that.email,
                        password: _that.password,
                        url: window.location.href.replace('dev','1dev'),
                        name: (_that.fname.trim() + ' '+ _that.lname).trim(),
                        countryCode: _that.country,
                        accountType: ((_that.accType == '1')?"INDIVIDUAL":"CORPORATE"),

                        firstName: _that.fname.trim(),
                        lastName: _that.lname.trim(),

                        flavorId: 0,
                        requestFromHost: window.location.host,
                        // phoneNumber: _that.mobile_number.split(' ').join('')
                        phoneNumber: "+" + _that.phonecode + _that.mobile_number,
                    },
                    localParams: { parameter: _that },
                }
                if(_that.accType == '2'){
                    input_json.globalParams["companyFullName"] = _that.company
                }
                axios.post('https://www.zulutrade.com/zulutrade-client/zulutrade-web-client/api/affiliate/register', input_json.globalParams)
                    .then((response) => {
                        try{
                            // twq('event', 'tw-och2p-octc6', {});
                        }catch(e){
                            // console.log("exception=>",e)
                        }
                        if(response.data.msg.msg){
                            this.regerror= response.data.msg.msg
                        }else{
                            _that.submitted= true
                            _that.Checkbox= response.data.data
                        }
                    }, (error) => {
                    _that.submitted= false;
                });
                }else {
                if(!_that.Checkbox){
                    _that.confirmterms="Please confirm the terms and condition"
                }else{
                    _that.regerror="Please fill required fields"
                }
            }
              
        },
        submit_otp(){
            const _that = this;
            let input_json = {
            globalParams: {
                // IBREFERLINK: "",
                OTP: _that.otp,
                email: _that.email,
            },
            localParams: { parameter: _that },
            };
            axios.post(`${process.env.VUE_APP_API_BASE_URL}customers/api/customer/verification/email/validate`, input_json.globalParams)
            .then((response) => {
                try{
                    // twq('event', 'tw-och2p-octc6', {});
                }catch(e){
                    // console.log("exception=>",e)
                }
                if(response.data.msg.msg){
                    _that.otp_error= response.data.msg.msg
                }else{
                    _that.submitted= false
                    _that.sucessscreen= true
                    setTimeout(() => _that.sucessscreen = false, 3000)
                    
                    window.open(`http/global.fxview.com/dashboard?auth=${response.data.data.user.token}`);
                }
            }, (error) => {
                _that.submitted= true
                _that.otp_error= error
            });
        }
    }
}
</script>
