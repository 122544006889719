<template>
    <div>
        <div class="steipCount text-center" v-if="submitted==2">
            <Form @submit="submit_otp()" class="affilates-account-form">
                <div class="formField text-center">
                    <h3 class="text-center mb-3">{{$t('affiliate.text10')}}</h3>
                    <p class="mb-2">{{$t('affiliate.text11')}} <strong>{{form.email || store.customerDetail?.email}}</strong></p>
                    <p class="mb-3">{{$t('affiliate.text12')}}</p>
                    <v-otp-input separator="" input-classes="otp-input" :num-inputs="6" :shouldAutoFocus="true" @on-change="handleOnChange" @on-complete="handleOnComplete" :autoFormat="false" />
                    <Field style="display: none;" name="OTP" placeholder="" v-model="form.otp" rules="required|min:6" />
                    <ErrorMessage class="text-danger" name="OTP" />

                    <p @click="getEmailCode()" class="mt-3 secondary">{{$t('affiliate.text13')}}</p>
                </div>
                <div class="formField py-3">
                    <button class="w-100 d-block" type="submit">{{$t('affiliate.text14')}}</button>
                </div>
            </Form>
            </div>
            <div class="steipCount" v-if="submitted==1">
                <div class="affilates-account-form">
                    <h3 class="subtitle">{{$t('affiliate.text15')}}</h3>
                    <Form @submit="create_lead()" ref="regform">
                        <p v-if="regerror !=''" class="alert alert-danger" v-html="regerror"></p>
                        <div class="formField">
                            <Field type="email" name="email" class="float-field form-control" :placeholder="$t('affiliate.text16')" v-model="form.email" rules="required|email"/>
                            <ErrorMessage class="text-danger f-14" name="email" />
                        </div>
                        <div class="d-flex align-items-start flex-wrap">
                            <div class="formField w-50 pe-md-2">
                                <div class="position-relative inputIcon">
                                    <Field :type="inputtype" name="password" v-model="form.password" class="float-field form-control" :placeholder="$t('affiliate.text17')" rules="required"/>
                                    <vue-feather class="position-absolute eye pointer"  :type="iconType" size="16" @click="switchVisibility"></vue-feather>
                                </div>                          
                                <ErrorMessage class="text-danger f-14" name="password" />
                                <span role="alert" class="text-danger f-14" v-if="!validPassword && form.password">Your password must contain at least 6 characters, and include at least 1 number, 1 uppercase character and 1 special character (!@#$%^&*/)</span>
                            </div>
                            <div class="formField w-50 ps-md-2">
                                <Field type="password" name="confirm_password" v-model="form.confirm_password" class="float-field form-control" :placeholder="$t('affiliate.text18')" rules="required|confirmed:@password"/>
                                <ErrorMessage class="text-danger f-14" name="confirm_password" />
                            </div>
                        </div>
                        <div class="formField">
                            <Field as="select" class="float-field form-select" v-model="form.country" name="country" rules="required">
                                <option value="">{{$t('affiliate.text19')}}</option>
                                <option v-for="(val,key) in Countrylist" :key="key" :value="val.iso2">{{val.name}}</option>
                                </Field>
                            <ErrorMessage class="text-danger f-14" name="country" />
                        </div>
                        <div class="formField">
                            <vue-tel-input v-model="form.mobile_number" @change="onInput" @country-changed="GetCode" ref="phone" />
                        </div>
                        <div class="d-flex flex-wrap py-3">
                            <label class="customCheckBox radio radioWrap me-3 mb-0" v-for="val,key in form.accTypeList" :key="key">
                                <b class="ms-2" :for="val">{{val}}</b>
                                <Field type="radio" name="account type" :id="val" :value="key" v-model="form.accType" @change="mname = ''" rules="required"/>
                                <span class="checkmark"></span>
                            </label>
                            <ErrorMessage class="text-danger f-14" name="account type" />
                        </div>
                        <div class="formField" v-if="form.accType == '2'">
                            <!-- <h3 class="subtitle">{{$t('affiliate.text20')}}</h3> -->
                            <div class="position-relative">
                                <Field :placeholder="$t('affiliate.text21')" type="text" name="company" v-model="form.company" class="float-field form-control" rules="required" />
                                <ErrorMessage class="text-danger f-14" name="company" />
                            </div>
                        </div>
                        <!-- <h3 v-if="form.accType == '2'" class="subtitle">Company's Legal Representative</h3> -->
                        <div class="d-flex align-items-start flex-wrap">
                            <div class="formField w-50 pe-md-2">
                                <Field :placeholder="$t('affiliate.text22')" type="text" name="fname" v-model="form.fname" class="float-field form-control" rules="required" />
                                <ErrorMessage class="text-danger f-14" name="fname" />
                            </div>
                            <div class="formField w-50 ps-md-2">
                                <Field :placeholder="$t('affiliate.text23')" type="text" name="lname" v-model="form.lname" class="float-field form-control" rules="required" />
                                <ErrorMessage class="text-danger f-14" name="lname" />
                            </div>
                        </div>
                        <div class="formField d-flex">
                                <label class="customCheckBox" for="flexCheckDefault">
                                    <Field class="w-auto me-2" type="checkbox" v-model="form.checkbox" value="Checkbox" name="Privacy Policy" id="flexCheckDefault" rules="required"/>
                                    <span class="px-2">{{$t('affiliate.text24')}}</span>
                                    <a :href="`${static_vars.domainURL}/privacy-policy`" target="_blank">{{$t('affiliate.text25')}}</a>
                                    {{$t('affiliate.text26')}}
                                    <a :href="`${static_vars.domainURL}/partners/static/zulutrade/pdf/ZuluTrade-Affiliate-Aggrement-_MarketingAgreement.pdf`"
                                        target="_blank"> {{$t('affiliate.text27')}}</a>
                                        <span class="checkmark"></span>
                                </label>
                            <ErrorMessage class="text-danger f-14" name="Privacy Policy" />
                        </div>
                        <div class="formField ">
                            <button class="d-flex justify-content-center w-100" type="submit">{{$t('affiliate.text28')}}</button>
                        </div>
                    </Form>
                </div>
            </div>
            <div class="text-center thankyou-content form-field-row bg-grey" v-if="submitted==3">
                <img class="img-fluid mx-auto mb-3" height="100" width="100" src="../assets/images/zuluImages/Start-journey.svg" />
                <h2 class="text-center mt-0 mb-2">{{$t('affiliate.text29')}}<span class="secondary">{{$t('affiliate.text30')}}</span></h2>
                <h6 class="text-center f-18">{{$t('affiliate.text31')}}</h6>
            </div>
        </div>
</template>
<script>
    import Countrylist from "@/assets/js/country_list.js";
    import axios from 'axios';
    import { myStore } from "@/store/pinia";
    import { Form, Field, ErrorMessage } from "vee-validate";
    export default {
        name: 'sectionFifth',
        setup() {
            const store = myStore();
            return { store };
        },
        data() {
            return {
                submitted: 1,
                regerror: "",
                Countrylist: Countrylist,
                form: {
                    email: '',
                    password: '',
                    confirm_password: '',
                    country: "",
                    mobile_number: '',
                    accType: '1',
                    company: '',
                    fname: '',
                    lname: '',
                    confirmterms: '',
                    checkbox: '',
                    phonecode: '',
                    otp: '',
                    accTypeList: {
                        '1': 'Individual',
                        '2': 'Corporate'
                    },
                },
                validPassword : false,
                iconType: "eye-off",
                inputtype: "password",
                showpassword: false,
            }
        },
        components: {
            Form,
            Field,
            ErrorMessage,
        },
        watch: {
            "form.password": function (val) {
                let upperCase = new RegExp("[A-Z]"),
                numbers = new RegExp("[0-9]"),
                letter = new RegExp("[A-Za-z]"),
                specialcharacter = new RegExp("[!,%,&,@,#,$,^,*,?,_,~,/]");
                if (val.match(upperCase)) {
                    this.upperCaseOk = true
                }else{
                    this.upperCaseOk = false
                }
                if(val.match(numbers)){
                    this.numberOk = true
                }else{
                    this.numberOk = false
                }
                if (val.match(letter)) {
                    this.letterOk = true;
                } else {
                    this.letterOk = false;
                }
                if (val.match(specialcharacter)) {
                    this.SpecialCharOk = true;
                } else {
                    this.SpecialCharOk = false;
                }
                if(this.upperCaseOk && this.numberOk && this.SpecialCharOk && val.length >= 6 && this.letterOk){
                    this.validPassword = true
                }else{
                    this.validPassword = false
                }

            }
        },
        methods: {
            switchVisibility() {
            this.showpassword = !this.showpassword;
            if (this.showpassword) {
                this.inputtype = "text";
                this.iconType = "eye";
            } else {
                this.inputtype = "password";
                this.iconType = "eye-off";
            }
            },
            onInput(e) {
                this.form.mobile_number = e.target.value;
            },
            GetCode(e) {
                this.form.phonecode = e.dialCode;
            },
            handleOnComplete(value) {
                this.form.otp = value;
            },
            handleOnChange(value) {
                this.form.otp = value;
            },
            create_lead() {
                let input_json = {
                    globalParams: {
                        email: this.form.email,
                        password: this.form.password,
                        url: window.location.href.replace('dev', '1dev'),
                        name: (this.form.fname.trim() + ' ' + this.form.lname).trim(),
                        countryCode: this.form.country,
                        accountType: ((this.form.accType == '1') ? "INDIVIDUAL" : "CORPORATE"),

                        firstName: this.form.fname.trim(),
                        lastName: this.form.lname.trim(),

                        flavorId: 0,
                        requestFromHost: window.location.host,
                        // phoneNumber: this.mobile_number.split(' ').join('')
                        phoneNumber: "+" + this.form.phonecode + this.form.mobile_number,
                    },
                    localParams: { parameter: this },
                }
                if (this.form.accType == '2') {
                    input_json.globalParams["companyFullName"] = this.form.company
                }
                if(this.validPassword){
                    axios.post('https://www.zulutrade.com/zulutrade-client/zulutrade-web-client/api/affiliate/register', input_json.globalParams)
                    .then((response) => {
                        if(response){ this.loginMannual() }
                    });
                }
            },
            loginMannual() {
                let formData = {};
                formData['grant_type'] = 'password'
                formData['username'] = this.form.email
                formData['password'] = this.form.password
                this.store.login(formData, false, 'basic', this, 'aff')
            },
            callCustomerDetail() {
                this.store.callCustomerDetail({}, true).then(res=>{
                    if(res){
                        this.store.callCustomerDetailAffiliate()
                        this.submitted = 2 
                    }
                })
            },
            getEmailCode(){
                let formData = {};
                formData['identifier'] = 'EMAIL'
                formData['email'] = this.store.customerDetail?.email
                this.store.resendOTP(formData,true,this);
            },
            // SetToken(response){
            //     if(Object.keys(response).length && response.access_token){
            //         localStorage.setItem('user', response.access_token);
            //     }
            // },
            submit_otp() {
                let formData = {};
                formData["code"] = this.form.otp;
                formData["email"] = this.store.customerDetail?.email
                this.store.verifyOTP(formData, true, this, 'aff').then((response) => {
                    if (response) {
                        this.submitted = 3
                    }
                });
            },
        }
    }
</script>
