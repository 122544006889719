<template>
    <section class="pt40 pb80">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="section-disc text-center">
                        <!-- <h2>Beat the <span>Odds</span> with Copy Trading!</h2> -->
                        <h2>{{$t('third1.text1')}} <span>{{$t('third1.text2')}}</span> {{$t('third1.text3')}} <span>{{$t('third1.text4')}}</span> {{$t('third1.text5')}}</h2>
                        <p class="sec-sub-heading">{{$t('third1.text6')}}</p>
                        <img class="img-fluid" src="../../assets/images/zulutradeSolutionImages/ZuluTradeCompareImg.png" :alt="$t('third1.text7')"/>
                        <p class="mt-4 pb-0">{{$t('third1.text8')}}</p>
                        <div class="f-flex banner-links">
                            <a href="#bannerForm" class="commonBtn linkBtn btn-minWidth demobtn">
                                {{$t('third1.text9')}}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    export default{
        name: 'sectionScnd'
    }
</script>
