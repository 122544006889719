<template>
  <header class="mainHeader shadow bg-white border-bottom">
    <nav class="navbar navbar-expand-lg">
      <div class="container-fluid max-1370 p-0">
        <a class="navbar-brand d-flex p-0 mx-auto" :href="`${static_vars.domainURL}`">
          <img
            src="../../assets/images/logo.svg"
            :alt="$t('header.text1')"
            :title="$t('header.text2')"
          />
        </a>
      </div>
    </nav>
  </header>
</template>
<script></script>