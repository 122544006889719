<template>
    <section class="why-zuluTrade-section pt80 pb80">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="section-disc text-center">
                        <h2 id="langAR">{{$t('third2.text1')}} <span>{{$t('third2.text2')}}</span></h2>
                    </div>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-md-4 col-12">
                    <div class="why-trade-col text-center">
                        <img class="img-fluid" src="../../assets/images/zulutradeSolutionImages/why-trade-img1.png" :alt="$t('third2.text3')"/>
                        <p>{{$t('third2.text4')}}</p>
                    </div>
                </div>
                <div class="col-md-4 col-12">
                    <div class="why-trade-col text-center">
                        <img class="img-fluid" src="../../assets/images/zulutradeSolutionImages/why-trade-img2.png" :alt="$t('third2.text5')"/>
                        <p id="langAR">{{$t('third2.text6')}}</p>
                    </div>
                </div>
                <div class="col-md-4 col-12">
                    <div class="why-trade-col text-center">
                        <img class="img-fluid" src="../../assets/images/zulutradeSolutionImages/whyjoin-icon3.svg" :alt="$t('third2.text7')"/>
                        <p>{{$t('third2.text8')}}</p>
                    </div>
                </div>
                <div class="col-md-4 col-12">
                    <div class="why-trade-col text-center">
                        <img class="img-fluid" src="../../assets/images/zulutradeSolutionImages/why-trade-img4.png" :alt="$t('third2.text9')"/>
                        <p>{{$t('third2.text10')}}</p>
                    </div>
                </div>
                <div class="col-md-4 col-12">
                    <div class="why-trade-col text-center">
                        <img class="img-fluid" src="../../assets/images/zulutradeSolutionImages/wz-social-tools.png" :alt="$t('third2.text11')"/>
                        <p>{{$t('third2.text12')}}</p>
                    </div>
                </div>
                <div class="col-md-4 col-12">
                    <div class="why-trade-col text-center">
                        <img class="img-fluid" src="../../assets/images/zulutradeSolutionImages/why-trade-img6.png" :alt="$t('third2.text13')"/>
                        <p>{{$t('third2.text14')}}</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    export default {
        name: 'sectionThird'
    }
</script>
