export default {
    locales: {
        ms: "Malay"
    },
    // leader page
    leader: {
        'text1': `ZuluTrade Logo`,
        'text2': `Daftar Sekarang`,
        'text3': `Daftar`,
        'text4': `Daftar sebagai pemimpin di ZuluTrade.`,
        'text5': `Jadikan akaun awam & mulakan dagangan.`,
        'text6': `Pelabur menyalin strategi anda dan anda menerima pendapatan anda.`,
        'text7': `Ketua`,
        'text8': `a`,
        'text9': `Pelabur mengikuti pemimpin selepas menganalisis halaman prestasi mereka.`,
        'text10': `Setiap kali perdagangan dibuat oleh pemimpin, ia disalin ke akaun pelabur juga.`,
        'text11': `Dagangan yang menguntungkan bagi pemimpin juga menguntungkan pelabur.`,
        'text12': `pelabur`,
        'text13': `an`
    },
    pages: {
        'text1': `Sertai Peneraju Dunia`,
        'text2': `Perdagangan Sosial`,
        'text3': `Komuniti!`,
        'text4': `Dapat $5-$10 bagi setiap $100 ribu yang didagangkan. Kos bergantung pada pasangan mata wang yang didagangkan, jenis akaun dan sebagainya.`,
        'text5': `Sertai komuniti global dengan lebih daripada 2 juta pengguna dan 90 ribu pemimpin di platform dagangan sosial teratas.`,
        'text6': `Kongsi strategi anda, dapat komisen bagi dagangan yang disalin. Sertai ZuluTrade sekarang!`,
        'text7': `ZuluTrade Leader`,
        'text8': `Buka Akaun Pemimpin`,
        'text9': `Ketahui Selanjutnya`
    },
    second3: {
        'text1': `Enjoy Flexible`,
        'text2': `Compensation Schemes`,
        'text3': `Our default monetization model is CPA, but we are open to discussing more options for established affiliates, like hybrid and custom deals if they can deliver large volumes of customers. Our CPA playouts are categorized into Tiers, depending on the Geolocation of the accounts referred by our affiliates.`,
        'text4': `Maximize your earnings with our affiliate program. Earn up to 300$ per investor introduced, and claim even more with our hybrid custom CPA model`,
        'text5': `Become an Affiliate`,
        'text6': `Ketahui Selanjutnya`,
        'text7': `Affiliate Zulu Coin`
    },
    header: {
        'text12': `ZuluTrade's`,
    },
    section2: {
        'text1': `Pemimpin Prestasi Teratas`,
        'text2': `Strategi yang mempunyai momentum positif dan bergerak menghampiri ATH (Paras Tinggi Sepanjang Masa)`,
        'text3': `Penyalin`,
        'text4': `Pengikut`,
        'text5': `Sertai mereka yang Terbaik`
    },
    section3: {
        'text1': `Mengapa Menyertai ZuluTrade sebagai`,
        'text2': ` Pemimpin?`,
        'text3': `ZuluTrade licence icon`,
        'text4': `ZuluTrade dikawal selia oleh HCMC di EU, FSA di Jepun, FSC di Mauritius  dan FSCA di Afrika Selatan.`,
        'text5': `ZuluTrade advanced and feature rich icon`,
        'text6': `Platform pelbagai ciri dan termaju untuk disesuaikan dengan pengalaman dagangan anda.`,
        'text7': `ZuluTrade support icon`,
        'text8': `Tingkatkan keberangkalian dengan meja pedagang khusus untuk menarik dan mengekalkan pelabur.`,
        'text9': `ZuluTrade No hidden costs icon`,
        'text10': `Tiada kos tersembunyi atau had bayaran bulanan.`,
        'text11': `ZuluTrade multiple brokers icon`,
        'text12': `Platform dibuat untuk menyokong berbilang broker.`,
        'text13': `ZuluTrade network icon`,
        'text14': `Rangkaian dagangan sosial yang luas dengan lebih 90 ribu Pemimpin.`
    },
    section4: {
        'text1': `Bagaimana`,
        'text2': `ZuluTrade`,
        'text3': `berfungsi untuk`,
        'text4': `Pemimpin?`,
        'text5': `Join ZuluTrade icon`,
        'text6': `Jadi pemimpin sejati di ZuluTrade`,
        'text7': `Sertai ZuluTrade sebagai pemimpin, sambungkan akaun anda dan kongsi strategi dagangan anda yang berjaya!`,
        'text8': `Rankings icon`,
        'text9': `Tonjolkan Diri dan Berjaya`,
        'text10': `Sistem penarafan kami mempertimbangkan prestasi, kestabilan, kelakuan dan penampilan.`,
        'text11': `Earnings icon`,
        'text12': `Pendapatan Tanpa Tekanan`,
        'text13': `Sebagai pemimpin, anda mendapat hasil apabila pelabur menyalin dagangan anda, mengambil kira saiz pelaburan dan toleransi risiko mereka.`
    },
    section5: {
        'text1': `Pemimpin ZuluTrade:`,
        'text2': `Apakah yang mereka lakukan?`,
        'text3': `'Pemimpin' ialah pengguna ZuluTrade yang berkongsi dan membenarkan pengguna lain menyalin dagangan mereka ke dalam akaun pengguna. Setiap tindakan dagangan yang dilakukan dalam akaun Pemimpin kemudiannya dihantar kepada semua Pelabur Pemimpin dalam bentuk isyarat yang disiarkan.`,
        'text4': `Cipta komuniti dan pimpin mereka!`,
        'text5': `research icon`,
        'text6': `Kaji`,
        'text7': `pasaran, berita dan kemas kini syarikat dengan kerap.`,
        'text8': `intract icon`,
        'text9': `Berinteraksi`,
        'text10': `dengan Pelabur dan berikan kemas kini berkenaan strategi dagangan.`,
        'text11': `broadcast icon`,
        'text12': `Siarkan`,
        'text13': `isyarat dagangan kepada Pelabur ZuluTrade dan ditampilkan dalam penarafan di laman web.`
    },
    section6: {
        'text1': `Cara untuk menyertai ZuluTrade sebagai `,
        'text2': `Pemimpin?`,
        'text3': `Langkah 1`,
        'text4': `Daftar sebagai pemimpin di ZuluTrade.`,
        'text5': `Langkah 2`,
        'text6': `Jadikan akaun awam & mula berdagang.`,
        'text7': `Langkah 3`,
        'text8': `Pelabur menyalin strategi anda dan anda menjana pendapatan.`,
        'text9': `Sertai kami di ZuluTrade`,
        'text10': `Autoisi butiran peribadi anda`,
        'text11': `Google icon`,
        'text12': `atau isi secara manual`,
        'text13': `Nama pengguna atau e-mel`,
        'text14': `Masukkan Kata Laluan`,
        'text15': `Buat Akaun`
    },
    second5: {
        'text1': `Bagaimana untuk menjadi`,
        'text2': `ZuluTrade Affiliate`,
        'text3': `in 3 easy steps?`,
        'text4': `1`,
        'text5': `Click on 'Become an Affiliate'.`,
        'text6': `2`,
        'text7': `Complete the registration and get your unique affiliate URL.`,
        'text8': `3`,
        'text9': `Invite your contacts using unique affiliate URL  and start earning.`,
        'text10': `One-Time Pin has been sent to your email. Please enter it below.`,
        'text11': `Enter OTP`,
        'text12': `Submit`,
        'text13': `Affiliates Account Registration`,
        'text14': `Enter Email Address`,
        'text15': `Enter Your Password`,
        'text16': `Confirm Password`,
        'text17': `Pilih Negara Kediaman`,
        'text18': `Company Full Name (For Corporate Accounts)`,
        'text19': `Enter Company Full Name`,
        'text20': `Company's Legal Representative`,
        'text21': `Nama pertama`,
        'text22': `Nama terakhir`,
        'text23': `I agree to the`,
        'text24': `Privacy Policy`,
        'text25': `Terms.`,
        'text26': `Register`,
        'text27': `Back to login`,
        'text28': `Dagangan dalam instrumen kewangan melibatkan risiko kerugian yang ketara. Untuk penafian penuh`,
        'text29': `tekan di sini.`
    },
    affiliate: {
        'text1': `Bagaimana untuk menjadi`,
        'text2': `ZuluTrade Affiliate`,
        'text3': `in 3 easy steps?`,
        'text4': `1`,
        'text5': `Click on 'Become an Affiliate'.`,
        'text6': `2`,
        'text7': `Complete the registration and get your unique affiliate URL.`,
        'text8': `3`,
        'text9': `Invite your contacts using unique affiliate URL and start earning.`,
        'text10': `Verify your Email address`,
        'text11': `Enter the 6-digit verification code that was sent to`,
        'text12': `The code is valid for 10 minutes.`,
        'text13': `Resend Code`,
        'text14': `Submit`,
        'text15': `Affiliates Account Registration`,
        'text16': `Enter Email Address`,
        'text17': `Enter Your Password`,
        'text18': `Confirm Password`,
        'text19': `Pilih Negara Kediaman`,
        'text20': `Company Full Name (For Corporate Accounts)`,
        'text21': `Enter Company Full Name`,
        'text22': `Nama pertama`,
        'text23': `Nama terakhir`,
        'text24': `I agree to the`,
        'text25': `Privacy Policy`,
        'text26': `&`,
        'text27': `Terms.`,
        'text28': `Register`,
        'text29': `Thank`,
        'text30': `you!`,
        'text31': `Your account has created successfully.`,
        'text32': `Dagangan dalam instrumen kewangan melibatkan risiko kerugian yang ketara. Untuk penafian penuh`,
        'text33': `tekan di sini.`,
    },
    zulutrade: {
        'text1': `Cara untuk menyertai ZuluTrade sebagai `,
        'text2': `Langkah 1`,
        'text3': `Langkah 2`,
        'text4': `Langkah 3`,
        'text5': `Sertai kami di ZuluTrade`,
        'text6': `Autoisi butiran peribadi anda`,
        'text7': `atau isi secara manual`,
        'text8': `E-mel`,
        'text9': `Kata laluan`,
        'text10': `Kekuatan Kata Laluan`,
        'text11': `1 uppercase character`,
        'text12': `8 or more characters`,
        'text13': `at least 1 number`,
        'text14': `at least 1 letter`,
        'text15': `at least 1 special character`,
        'text16': `Loading...`,
        'text17': `Buat Akaun`,
        'text18': `Dagangan dalam instrumen kewangan melibatkan risiko kerugian yang ketara. Untuk penafian penuh`,
        'text19': `tekan di sini.`,
        'text20': `Nama pertama`,
        'text21': `Nombor telefon`,
        'text22': `Nama terakhir`,
        'text23': `Pilih Negara Kediaman`,
        'text24': `Cari di sini..`,
        'text25': `Negara diperlukan`,
        'text26': `Nombor telefon diperlukan`,
    },
    // Investor page

    banner: {
        'text1': `Pelaburan dipermudah dengan`,
        'text2': `CopyTrading dan Dagangan Sosial!`,
        'text3': `Ayuh Zulu!`,
        'text4': `ZuluTrade ialah salah satu platform dagangan sosial terkemuka di dunia dengan lebih 90K+ pemimpin pro dan 2J+ pengguna aktif di platform. Temui pemimpin teratas dari seluruh dunia, salin strategi mereka dan tiru dagangan mereka dalam masa nyata ke akaun pelaburan anda.`,
        'text5': `Mula Melabur`,
        'text6': `ZuluTrade Banner Image`
    },
    third1: {
        'text1': `Ikut`,
        'text2': `pakar`,
        'text3': `dan mengalahkan`,
        'text4': `kemungkinan`,
        'text5': `dalam dunia perdagangan dengan Copy Trading`,
        'text6': `Tingkatkan perjalanan dagangan anda ke tahap seterusnya dengan ZuluTrade di sisi anda.`,
        'text7': `ZuluTrade Compare Leaders Image`,
        'text8': `Buka akaun Demo dan gunakannya untuk belajar!`,
        'text9': `DEMO`,
        'text10': `Become an Affiliate`,
    },
    third2: {
        'text1': `Mengapa`,
        'text2': `ZuluTrade?`,
        'text3': `ZuluTrade transparent copy trading platform icon`,
        'text4': `Platform dagangan salinan tertua dan paling telus.`,
        'text5': `ZuluTrade license icon`,
        'text6': `ZuluTrade dikawal selia oleh HCMC di EU, FSA di Jepun, FSC di Mauritius dan FSCA di Afrika Selatan.`,
        'text7': `ZuluTrade investors profit icon`,
        'text8': `Boleh hubungi Khidmat Pelanggan kami yang dinilai tinggi 24/5 melalui sembang, telefon atau e-mel!`,
        'text9': `ZuluTrade serves globally icon`,
        'text10': `Berkhidmat secara global di lebih 150 negara.`,
        'text11': `Average winning ratio`,
        'text12': `Alat sosial membolehkan anda berinteraksi dengan pemimpin dan rakan pelabur.`,
        'text13': `vast community`,
        'text14': `Komuniti luas 2J+ pengguna di seluruh dunia.`
    },
    third3: {
        'text1': `Apakah yang menjadikan ZuluTrade`,
        'text2': `unik?`,
        'text3': `ZuluTrade Unique Features Image`,
        'text4': `ZuluGuard`,
        'text5': `TM`,
        'text6': `Alat dagangan sosial yang pintar, selamat untuk pelaburan anda. ZuluGuard melindungi modal pelaburan anda dengan sentiasa memantau akaun anda, melindungi keuntungan dan mengurangkan potensi kerugian.`,
        'text7': `Berbilang Platform`,
        'text8': `Anda boleh memilih untuk berdagang daripada berbilang platform yang tersedia:`,
        'text9': `MT4, MT5, ActTrader, X Open Hub, Match-Trader.`,
        'text10': `Suapan Sosial`,
        'text11': `Berinteraksi dengan rakan pelabur dan pemimpin di seluruh dunia. Beri komen, nilai dan ambil bahagian dalam perbincangan dan kuasai pasaran menggunakan kebijaksanaan komuniti sosial.`,
        'text12': `Mula CopyTrading Sekarang`
    },
    third4: {
        'text1': `Cara untuk menyertai ZuluTrade sebagai`,
        'text2': `Pelabur?`,
        'text3': `Langkah 1`,
        'text4': `Pelabur mengikuti pemimpin selepas menganalisis halaman prestasi mereka.`,
        'text5': `Langkah 2`,
        'text6': `Setiap kali dagangan dibuat oleh pemimpin, ia disalin ke akaun pelabur juga.`,
        'text7': `Langkah 3`,
        'text8': `Dagangan yang menguntungkan bagi pemimpin juga menguntungkan pelabur.`,
        'text9': `Buat Akaun`,
        'text10': `Dagangan dalam instrumen kewangan melibatkan risiko kerugian yang ketara. Untuk penafian penuh`,
        'text11': `tekan di sini.`
    }
}