import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import Toast, { POSITION } from "vue-toastification";
import { defineRule } from 'vee-validate';
import * as AllRules from '@vee-validate/rules';
import VueFloatLabel from 'vue-float-label'
import staticVars from './staticVars'
import VOtpInput from "vue3-otp-input";
import GoogleSignInPlugin from "vue3-google-signin"
import App from './App.vue'
import router from './router'
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import { createI18n } from 'vue-i18n';
import './assets/css/style.css';
import 'feather-icons/dist/feather.min.js'
import VueFeather from 'vue-feather';
import './assets/css/style-landing-page.css';
import './assets/css/zuluTrade-solution-style.css';
import './assets/css/zuluTrade-affiliate.css';
import VueTelInput from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'
import 'vue-toastification/dist/index.css';
import en from './assets/locales/en.js'
import es from './assets/locales/es.js'
import vi from './assets/locales/vi.js'
import ar from './assets/locales/ar.js'
import de from './assets/locales/de.js'
import pt from './assets/locales/pt.js'
import pl from './assets/locales/pl.js'
import it from './assets/locales/it.js'
import ms from './assets/locales/ms.js'
import fil from './assets/locales/fil.js'

const app = createApp(App)
const pinia = createPinia()

//Internationalization
const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  warnHtmlInMessage: 'off',
  messages: {
    en,
    es,
    vi,
    ar,
    de,
    pt,
    pl,
    it,
    fil,
    ms
  },
})

Object.keys(AllRules).forEach(rule => {
  defineRule(rule, AllRules[rule]);
});
defineRule('required', (value, t, ctx) => {
  if (!value || (value + '').trim() == '') {
    return ctx.field + ' field is required';
  }
  return true;
});
defineRule('onlyLetters', value => {
  if (!value || /^[A-Za-z]+$/.test(value)) {
    return true;
  }
  return 'This field must contain only alphabets';
});
const filterBeforeCreate = (toast, toasts) => {
  if (toasts.filter(t => t.type === toast.type).length !== 0) {
    return false;
  }
  return toast;
}

app.config.globalProperties.static_vars = staticVars
app.component(VueFeather.name, VueFeather);
app.use(router)
app.use(VueTelInput)
app.use(pinia)
pinia.use(piniaPluginPersistedstate)
app.use(Toast, { filterBeforeCreate, position: POSITION.TOP_CENTER, transition: "Vue-Toastification__fade", });
app.use(VueFloatLabel)
app.component('v-otp-input', VOtpInput)
app.use(GoogleSignInPlugin, {
  clientId: '1085291535935-2pih5gmomh2qjv0h373l6aqcmnr1p2rn.apps.googleusercontent.com',
})
app.use(i18n)
app.mount('#app')
