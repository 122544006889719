<template>
    <div class="join-up-form p-2-1">
        <h2 class="mb-1" id="langAR">{{$t('zulutrade.text5')}}</h2>
        <div class="form-field-row pt-0 mb-0" v-if="step == 1"><!--bg-grey -->
            <!-- <p class="text-center">{{$t('zulutrade.text6')}}</p> -->
            <!-- <div class="google-icon mt-3">
                <GoogleSignInButton @success="handleLoginSuccess" @error="handleLoginError" size="medium">
                </GoogleSignInButton>
            </div> 
            <h3 class="fillManually"><span>{{$t('zulutrade.text7')}}</span></h3>-->
            <Form @submit="register">
                <!-- <float-label class="form-field-col">
                    <label>{{$t('zulutrade.text8')}}</label>
                    <Field name="Email Address" autofill="off" autocomplete="username" v-model="form.username"
                        placeholder=" " rules="required|email" />
                    <ErrorMessage class="text-danger f-14" name="Email Address" />
                </float-label> -->
                <div class="d-flex flex-wrap">
                    <float-label class="form-field-col w-50 pe-md-2">
                        <label>{{$t('zulutrade.text20')}}</label>
                        <Field name="First name" placeholder=" "  autofill="off" autocomplete="off" v-model="form.fname" rules="required|onlyLetters"/>
                        <ErrorMessage class="text-danger f-14" name="First name" />
                    </float-label>
                    <float-label class="form-field-col w-50 ps-md-2">
                        <label>{{$t('zulutrade.text22')}}</label>
                        <Field name="Last name" autofill="off" autocomplete="off" v-model="form.lname"
                            placeholder=" " rules="required|onlyLetters"/>
                        <ErrorMessage class="text-danger f-14" name="Last name" />
                    </float-label>
                </div>
                <div class="form-field-col">
                    <label>{{$t('zulutrade.text8')}}</label>
                    <Field name="Email Address" autofill="off" autocomplete="username" v-model="form.username"
                    placeholder=" " rules="required|email" />
                    <ErrorMessage class="text-danger f-14" name="Email Address" />
                </div>
                <div class="form-field-col">
                    <label>{{$t('zulutrade.text23')}}</label>
                    <div class="countrySelect form-group position-relative filter-dropdown"  :class="[{'selected' : country.name}]">
                        <a @click="selectButton = !selectButton" href="javascript:void(0)"
                            class="selectButton d-flex align-items-center justify-content-between"><span
                                class="d-flex align-items-center" v-if="country.id && country.name"><img class="me-2" :src="'/lp/assets/images/country_flag/'+country.name.toLowerCase()+'.webp'"
                                    :alt="'Icon'" :title="'Icon'" loading="lazy" width="20" height="20"/>{{country.name}}</span>
                                    <span v-else>&nbsp;</span>
                            <vue-feather size="20" type="chevron-down"></vue-feather>
                        </a>
                        <ul class="dropdown_menu_animated scrollable" :class="selectButton ? 'show' : ''" > 
                            <li class="form-group mb-1">
                                <input type="text" :placeholder="$t('zulutrade.text24')" class="form-control radius-0" v-model="countrysearch" />
                            </li>
                            <li v-for="item,key in getCountries" :key="key">
                                <a href="javascript:void(0)" class="d-flex align-items-center" @click="country = item;selectButton = false;countrysearch = '';changecountry(item)" ><img class="me-2"
                                        :src="'/lp/assets/images/country_flag/'+item.name.toLowerCase()+'.webp'" :alt="'Icon'" :title="item.name" loading="lazy" width="20" height="20"/>
                                    {{item.name}}</a>
                            </li>
                        </ul>
                    </div>
                    <span v-if="isCountry" role="alert" class="text-danger">{{$t('zulutrade.text25')}}</span>
                </div>
                <div class="form-field-col">
                    <label>{{$t('zulutrade.text21')}}</label>
                    <Field
                        v-slot="{ field }"
                        name="Phone Number"
                        v-model="form.phone" >
                        <vue-tel-input
                        v-bind="field"
                        v-model="form.phone"
                        @change="onInput"
                        @country-changed="GetCode"
                        :onlyCountries ="getAllowCountry()"
                        ref="phone"
                        :custom-validate="form.phone!=''? new RegExp(/^[0-9]+$/):false"
                        ></vue-tel-input>
                    </Field>
                </div>
                <span v-if="isPhone" role="alert" class="text-danger">{{$t('zulutrade.text26')}}</span>
                <float-label class="form-field-col mb-1">
                    <label>{{$t('zulutrade.text9')}}</label>
                    <div class="position-relative iconInput">
                        <Field :type="inputtype" autofill="off" name="Password" v-model="form.password"
                            rules="required|min:2" placeholder=" " autocomplete="new-password" />
                        <ErrorMessage class="text-danger f-14" name="Password" />
                        <vue-feather class="position-absolute eye pointer" :type="iconType" size="15"
                            @click="switchVisibility"></vue-feather>
                    </div>
                    <div class="toolMob d-flex align-items-center justify-content-end" :class="
                        !validPassword && form.password
                        ? 'orange'
                        : validPassword && form.password
                        ? 'green'
                        : ''
                    ">
                        <p class="f-10 mb-0">{{$t('zulutrade.text10')}}</p>
                        <div class="tooltipbutton w-auto">
                            <span class="tooltiptext">
                                <div class="strenghtPassword m-0">
                                    <ul class="passwordStrenth mb-0">
                                        <li :class="upperCaseOk ? 'green' : 'red'">
                                            {{$t('zulutrade.text11')}}
                                        </li>
                                        <li :class="form.password.length >= 8 ? 'green' : 'red'">
                                            {{$t('zulutrade.text12')}}
                                        </li>
                                        <li :class="numberOk ? 'green' : 'red'">
                                            {{$t('zulutrade.text13')}}
                                        </li>
                                        <li :class="letterOk ? 'green' : 'red'">
                                            {{$t('zulutrade.text14')}}
                                        </li>
                                        <li :class="SpecialCharOk ? 'green' : 'red'">
                                            {{$t('zulutrade.text15')}}
                                        </li>
                                    </ul>
                                </div>
                            </span>
                            <vue-feather class="mx-1 alertType" :type="
                            !validPassword && form.password
                            ? 'alert-circle'
                            : validPassword && form.password
                            ? 'check-circle'
                            : 'alert-circle'
                        " size="15"></vue-feather>
                        </div>
                        <span class="barPass"></span>
                    </div>
                </float-label>
                <div class="buttonElement mt-3 form-field-col text-center w-100">
                    <button type="submit" class="commonBtn w-100"
                        :class="!validPassword || !captchaToken ? 'disabled' : ''">
                        <div class="loaderButton spinner-border primary" role="status" v-if="loader">
                            <span class="visually-hidden">{{$t('zulutrade.text16')}}</span>
                        </div>
                        <span class="" v-else>{{$t('zulutrade.text17')}}</span>
                    </button>
                </div>
            </Form>
        </div>
        <OTP v-else-if="step == 2"></OTP>
    </div>
</template>
<script>
    import { myStore } from "@/store/pinia";
    import { Form, Field, ErrorMessage } from "vee-validate";
    import FloatLabel from "vue-float-label/components/FloatLabel";
    import { load } from "recaptcha-v3";
    import OTP from "@/components/get_otp.vue";
    import $ from "jquery";
    export default {
        setup() {
            const store = myStore();
            const Crypto = require("crypto-js");
            return { store, Crypto }; //Crypto
        },
        data() {
            return {
                form: {
                    username: "",
                    password: "",
                    fname : "",
                    lname : "",
                    phone : "",
                    phonecode : ""
                },
                captchaToken: "",
                showpassword: false,
                iconType: "eye-off",
                inputtype: "password",
                upperCaseOk: false,
                numberOk: false,
                SpecialCharOk: false,
                letterOk: "",
                validPassword: false,
                step: 1,
                loader: false,
                loginTokenType: 1,
                isCountry : false,
                isPhone : false,
                selectButton: false,
                countrysearch : '',
                country : {},
            };
        },
        watch: {
            "form.password": function (val) {
                let upperCase = new RegExp("[A-Z]"),
                    numbers = new RegExp("[0-9]"),
                    letter = new RegExp("[A-Za-z]"),
                    specialcharacter = new RegExp("[!,%,&,@,#,$,^,*,?,_,~,/]");
                if (val.match(upperCase)) {
                    this.upperCaseOk = true;
                } else {
                    this.upperCaseOk = false;
                }
                if (val.match(numbers)) {
                    this.numberOk = true;
                } else {
                    this.numberOk = false;
                }
                if (val.match(letter)) {
                    this.letterOk = true;
                } else {
                    this.letterOk = false;
                }
                if (val.match(specialcharacter)) {
                    this.SpecialCharOk = true;
                } else {
                    this.SpecialCharOk = false;
                }
                if (
                    this.upperCaseOk &&
                    this.numberOk &&
                    this.SpecialCharOk &&
                    val.length >= 8 &&
                    this.letterOk
                ) {
                    this.validPassword = true;
                } else {
                    this.validPassword = false;
                }
            },
            "form.phone"(){
                if(this.form.phone && this.form.phone.length >= 7){
                    this.isPhone = false
                }
            },
            "store.flavordata"(){
                if(Object.keys(this.store.countryList).length && this.store.flavordata?.userCountry?.id){
                    if(Object.keys(this.store.countryList).includes(this.store.flavordata?.userCountry?.id.toString())){
                        this.country = this.store.countryList[this.store.flavordata?.userCountry?.id]
                        this.changecountry(this.country)
                    }else{
                        this.country = Object.values(this.store.countryList)[0]
                        this.changecountry(this.country)
                    }
                }
            },
            "store.countryList"(){
                if(Object.keys(this.store.countryList).length && this.store.flavordata?.userCountry?.isoCode){
                    if(Object.keys(this.store.countryList).includes(this.store.flavordata?.userCountry?.id.toString())){
                        this.country = this.store.countryList[this.store.flavordata?.userCountry?.id]
                        this.changecountry(this.country)
                    }else{
                        this.country = Object.values(this.store.countryList)[0]
                        this.changecountry(this.country)
                    }
                }
            }
        },
        computed : { 
            getCountries(){
                if(Object.keys(this.store.countryList).length > 0){
                    let data = Object.values(this.store.countryList);
                    if(this.countrysearch){
                        return data.filter(item => item.name.toLowerCase().includes(this.countrysearch.toLowerCase()))
                    }else{
                        return data
                    }

                }else{
                    return []
                }
            }
        },
        components: { OTP, FloatLabel, Form, Field, ErrorMessage },
        methods: {
            changecountry(item) {
                this.$refs.phone.choose(item.isoCode);
            },
            onInput(e) {
                this.form.phone = e.target.value;
            },
            GetCode(e) {
                this.form.phonecode = e.dialCode;
            },
            register(type, response) {
                load(`${this.static_vars.recaptchaKey}`, { useRecaptchaNet: true }).then(
                    (recaptcha) => {
                        recaptcha.execute("login").then((token) => {
                            this.captchaToken = token;
                            if (this.captchaToken) {
                                let formData = {};
                                if (this.validPassword) {
                                    if (this.validEmail()) {
                                        if(Object.keys(this.country).length){
                                            this.isCountry = false
                                        }else{
                                            this.isCountry = true
                                        }
                                        if(this.form.phone){
                                        this.isPhone = false
                                        }else{
                                        this.isPhone = true
                            }
                                        formData["email"] = this.form.username;
                                        formData["password"] = this.form.password;
                                        formData["firstname"] = this.form.fname;
                                        formData["lastname"] = this.form.lname
                                        formData["tokenType"] = "EMAIL";
                                        if(this.form.phone){
                                            formData["phoneNumber"] = this.form.phone
                                            formData["phoneCountryCode"] = this.form.phonecode
                                        }
                                        if(this.country?.id){
                                            formData['countryId'] = this.country.id
                                        }
                                    } 
                                    /*else {
                                        formData["username"] = this.form.username;
                                        formData["password"] = this.form.password;
                                        formData["tokenType"] = "USERNAME";
                                    }*/
                                    if (this.captchaToken) {
                                        formData["captcha"] = {};
                                        formData["captcha"]["response"] = this.captchaToken;
                                    }
                                    if(!this.isCountry && !this.isPhone){
                                        this.store.register(formData, true, this, "");
                                    }
                                } else {
                                    if (type) {
                                        formData["tokenType"] = "GOOGLE";
                                        formData["grant_type"] = "password";
                                        formData["token"] = response;
                                        if (this.captchaToken) {
                                            formData["captcha"] = {};
                                            formData["captcha"]["response"] = this.captchaToken;
                                        }
                                        this.store.register(formData, true, this, type);
                                    }
                                }
                            }
                        });
                    }
                );
            },
            CretioFunc() {
                try {
                    // window.gtag('event', 'email_verify');
                    if (
                        Object.keys(this.store.flavordata).length &&
                        this.store.flavordata.userCountry &&
                        Object.keys(this.store.flavordata.userCountry).length &&
                        this.store.flavordata.userCountry.isoCode
                    ) {
                        for (var key in this.static_vars.cretioCountryJson) {
                            if (key == this.store.flavordata.userCountry.isoCode) {
                                var s = document.createElement("script");
                                s.type = "text/javascript";
                                s.async = true;
                                s.src = `//dynamic.criteo.com/js/ld/ld.js?a=${this.static_vars.cretioCountryJson[key]}`;
                                $("head").append(s);
                                window.criteo_q = window.criteo_q || [];
                                var deviceType = /iPad/.test(navigator.userAgent)
                                    ? "t"
                                    : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
                                        navigator.userAgent
                                    )
                                        ? "m"
                                        : "d";
                                window.criteo_q.push(
                                    { event: "setaccount", account: this.static_vars.cretioCountryJson[key] }, // You should never update this line
                                    { event: "setSiteType", type: deviceType },
                                    {
                                        event: "setemail",
                                        email: this.Crypto.SHA256("1").toString(),
                                        hash_method: "sha256",
                                    },
                                    {
                                        event: "viewBasket",
                                        user_segment: 1,
                                        item: [
                                            { id: (Math.random() * 10000).toString(), price: 1, quality: 1 },
                                        ],
                                    }
                                );
                            }
                        }
                    }
                } catch (e) {
                    console.log("cretio ex", e);
                }
            },
            getAllowCountry(){
                return Object.values(this.store.countryList).map((val) => val.isoCode) || [];
            },
            CretioGoogleFunc() {
                try {
                    window.gtag("event", "Gen_Lead_2.0");
                    window.gtag("event", "test_verify");
                    // window.fbq('trackCustom', 'email_verify', {'Event ID' : `${this.store.customerDetail?.id}_email_verify` ,'email' : this.store.customerDetail?.email,'value' : '20','country' : this.store.flavordata?.userCountry?.name }, {eventID: `${this.store.customerDetail?.id}_email_verify`});
                    window.fbq('track','email_verify', {'email' : this.store.customerDetail?.email,'value' : '20','country' : this.store.flavordata?.userCountry?.name }, {eventID: `${this.store.customerDetail?.id}_email_verify`});
                    if (
                        Object.keys(this.store.flavordata).length &&
                        this.store.flavordata.userCountry &&
                        Object.keys(this.store.flavordata.userCountry).length &&
                        this.store.flavordata.userCountry.isoCode
                    ) {
                        for (var key in this.static_vars.cretioCountryJson) {
                            if (key == this.store.flavordata.userCountry.isoCode) {
                                var s = document.createElement("script");
                                s.type = "text/javascript";
                                s.async = true;
                                s.src = `//dynamic.criteo.com/js/ld/ld.js?a=${this.static_vars.cretioCountryJson[key]}`;
                                $("head").append(s);
                                window.criteo_q = window.criteo_q || [];
                                var deviceType = /iPad/.test(navigator.userAgent)
                                    ? "t"
                                    : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
                                        navigator.userAgent
                                    )
                                        ? "m"
                                        : "d";
                                window.criteo_q.push(
                                    { event: "setaccount", account: this.static_vars.cretioCountryJson[key] }, // You should never update this line
                                    { event: "setSiteType", type: deviceType },
                                    {
                                        event: "setemail",
                                        email: this.Crypto.SHA256("1").toString(),
                                        hash_method: "sha256",
                                    },
                                    //{ event: "setEmail", email: "1"  },
                                    // { event: "viewBasket", user_segment : 1, item: [
                                    //      {id : (Math.random()*10000).toString(), price:1, quality : 1}
                                    // ]},
                                    {
                                        event: "trackTransaction",
                                        id: Math.random() * 50000,
                                        item: [
                                            { id: (Math.random() * 10000).toString(), price: 1, quality: 1 },
                                        ],
                                    }
                                );
                            }
                        }
                    }
                } catch (e) {
                    console.log("cretio ex", e);
                }
            },
            handleLoginSuccess(response) {
                if (response.credential) {
                    this.register("Google", response.credential);
                }
            },
            handleLoginError() {
                console.error("Login failed");
            },
            loginMannual(data) {
                let formData = {};
                formData["grant_type"] = "password";
                formData["username"] = this.form.username;
                formData["password"] = this.form.password;
                formData["tokenType"] = data;
                this.store.login(formData, false, "basic", this, "web").then((res) => {
                    if (res) {
                        this.step = 2;
                    }
                });
            },
            loginWithGoogle(data) {
                let formData = {};
                formData["grant_type"] = "password";
                formData["googleToken"] = data;
                this.store.login(formData, false, "basic", this);
            },
            callCustomerDetail() {
                this.store.callCustomerDetail({}, true);
            },
            validEmail() {
                let regexp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                return regexp.test(this.form.username.toLowerCase());
            },
            switchVisibility() {
                this.showpassword = !this.showpassword;
                if (this.showpassword) {
                    this.inputtype = "text";
                    this.iconType = "eye";
                } else {
                    this.inputtype = "password";
                    this.iconType = "eye-off";
                }
            },
            getEmailCode() {
                let formData = {};
                formData["identifier"] = "EMAIL";
                formData["email"] = this.store.customerDetail.email;
                // this.store.resendOTP(formData,true,this);
            },
            SetToken(response) {
                if (Object.keys(response).length && response.access_token) {
                    localStorage.setItem("zulutoken", response.access_token);
                    if (response.refresh_token) {
                        localStorage.setItem("zulurefreshToken", response.refresh_token);
                    }
                }
            }
        },
        mounted() {
            load(`${this.static_vars.recaptchaKey}`, { useRecaptchaNet: true }).then(
                (recaptcha) => {
                    recaptcha.execute("login").then((token) => {
                        this.captchaToken = token;
                    });
                }
            );
            const _that = this;
            $(document).on("click", function (event) {
                if (!$(event.target).closest(".filter-dropdown").length) {
                    _that.selectButton = false;
                }
            });
        },
        created() {
            this.CretioFunc();
        },
    };
</script>
