module.exports = [
    {
        // other:{
        //     title:"Trade Forex at zero commission -  AAAFx",
        //     description:"Trade fx, commodities, stocks, indices, and more with the most transparent broker. Trade on raw spreads from 0.0 pips and zero commission.",
        //     keywords:"mt4, myforexfunds, metatrader 5, mql5, conversor de divisas, margin calculator, day trading, mt5, gbpjpy, free vps, position size calculator, mt4 download, meta trader, metatrader 4 download, lot size calculator, trading online, spreads, meta trader 4, metatrader 5 download, forex calculator, pips calculator, mt5 download, xagusd, forex market hours ,meta tBest forex broker, Fx broker, how to trade forex, AAAFx forex broker, AAAFx, Open account with AAAFx, open forex account, AAAFx fx broker, best regulated forex broker, fx broker to trade,  AAAFx broker rader 5, forex trader, metatrader4, pips meaning, forex profit calculator, bitcoin usdt, trading quotes, forex broker, metatrader5",
        //     h1:"",
        // },
        'zuluTrade-affiliate': {
            title: "Become a ZuluTrade Affiliate and Increase your earning potential | Social Trading with ZuluTrade",
            description: "ZuluTrade - the award-winning social trading platform. One of the most competitive affiliate programs in the industry, with high conversion rates, flexible compensation schemes, and a dedicated support team.",
            keywords: "social trading platforms, earning potential, award-winning, social trading, refer and earn, earn forex, affiliate forex, best affiliate program forex",
            h1: "",
        },
        investors: {
            title: "Copy top-performing leaders on ZuluTrade | More than 2M+ active users",
            description: "The average winning ratio can reach up to 80% when copy trading with ZuluTrade. Join the world's largest social trading community and copy the trades of 90k+ prol traders.",
            keywords: "social trading platforms, automator, social trading, copy trading, social trading platform, social trading network, best social trading platforms, social trading copy, social trading investment, social trading tools, copy trader, how to earn money by trading, copy trading forex",
            h1: "",
        },
        leaders: {
            title: "Become a leader on ZuluTrade. Share trades and get paid",
            description: "Join the Most Advanced Social Trading Community and earn by Sharing Your Trades with 2M+ users. Trade Forex, stocks, commodities and cryptocurrencies.",
            keywords: "social trading, share trades, social trading platforms, copy trade forex, forex copy trading, copytrading, trading social, copy forex trades, earn by copy trading, share trades and earn, ways to earn other than trading",
            h1: "",
        },
    },
];