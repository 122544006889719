export default {
    locales: {
        pl: "Polish"
    },
    // leader page
    leader: {
        'text1': `ZuluTrade Logo`,
        'text2': `Zarejestruj się już teraz`,
        'text3': `Zarejestruj się`,
        'text4': `Zarejestruj się jako lider w ZuluTrade.`,
        'text5': `Upublicznij konto i zacznij handlować.`,
        'text6': `Inwestorzy kopiują Twoją strategię, a Ty otrzymujesz swoje zarobki.`,
        'text7': `Lider`,
        'text8': `A`,
        'text9': `Inwestorzy śledzą liderów po analizie ich wyników na stronie.`,
        'text10': `Za każdym razem, gdy lider dokonuje transakcji, jest ona również kopiowana na konto inwestora.`,
        'text11': `Transakcje, które są opłacalne dla lidera, są również opłacalne dla inwestora.`,
        'text12': `Inwestor`,
        'text13': `jakiś`
    },
    pages: {
        'text1': `Dołącz do światowej czołówki`,
        'text2': `Handel społecznościowy`,
        'text3': `Wspólnota!`,
        'text4': `Zarabiaj 5-10 $ za 100k $ wolumenu transakcji. Koszt zależy od par walutowych, rodzaju konta itd.`,
        'text5': `Zostań częścią globalnej społeczności liczącej 2 mln+ użytkowników i 90k + liderów na wiodącej platformie do handlu społecznościowego.`,
        'text6': `Udostępniaj swoje strategie, zarabiaj prowizje za kopiowane transakcje. Dołącz do ZuluTrade już teraz!`,
        'text7': `ZuluTrade Leader`,
        'text8': `Otwórz Konto Lidera`,
        'text9': `Dowiedz się więcej`
    },
    second3: {
        'text1': `Enjoy Flexible`,
        'text2': `Compensation Schemes`,
        'text3': `Our default monetization model is CPA, but we are open to discussing more options for established affiliates, like hybrid and custom deals if they can deliver large volumes of customers. Our CPA playouts are categorized into Tiers, depending on the Geolocation of the accounts referred by our affiliates.`,
        'text4': `Maximize your earnings with our affiliate program. Earn up to 300$ per investor introduced, and claim even more with our hybrid custom CPA model`,
        'text5': `Become an Affiliate`,
        'text6': `Dowiedz się więcej`,
        'text7': `Affiliate Zulu Coin`
    },
    section2: {
        'text1': `Najlepsi Liderzy`,
        'text2': `Strategie, które mają dodatni impet i zbliżają do okolic historycznych szczytów`,
        'text3': `Kopiujący`,
        'text4': `Obserwujący`,
        'text5': `Dołącz do najlepszych z nich`
    },
    header: {
        'text12': `ZuluTrade's`,
    },
    section3: {
        'text1': `Dlaczego warto dołączyć do ZuluTrade jako `,
        'text2': `Líder?`,
        'text3': `ZuluTrade licence icon`,
        'text4': `ZuluTrade jest objęta nadzorem przez HCMC w UE, FSA w Japonii, FSC na Mauritiusie i FSCA w RPA.`,
        'text5': `ZuluTrade advanced and feature rich icon`,
        'text6': `Zaawansowana i wyposażona w liczne funkcjonalności platforma, która spersonalizuje Twoje doświadczenie handlu.`,
        'text7': `ZuluTrade support icon`,
        'text8': `Zwiększ zyskowność dzięki dedykowanemu centrum transakcyjnemu, aby przyciągnąć i utrzymać inwestorów.`,
        'text9': `ZuluTrade No hidden costs icon`,
        'text10': `Brak ukrytych kosztów lub miesięcznych limitów wypłaty.`,
        'text11': `ZuluTrade multiple brokers icon`,
        'text12': `Platforma stworzona do obsługi wielu brokerów.`,
        'text13': `ZuluTrade network icon`,
        'text14': `Szeroka sieć handlu społecznościowego z 90k+ Liderów.`
    },
    section4: {
        'text1': `Jak`,
        'text2': `ZuluTrade`,
        'text3': `pracuje dla`,
        'text4': `Lider?`,
        'text5': `Join ZuluTrade icon`,
        'text6': `Zostań prawdziwym Liderem na ZuluTrade`,
        'text7': `Dołącz do ZuluTrade jako Lider, powiąż swoje konto i udostępniaj swoje zwycięskie strategie handlowe!`,
        'text8': `Rankings icon`,
        'text9': `Wyróżnij się i osiągnij sukces`,
        'text10': `Nasz system rankingowy uwzględnia wyniki, stabilność, zachowanie i perspektywy`,
        'text11': `Earnings icon`,
        'text12': `Zyski bez stresu`,
        'text13': `Jako Lider zarabiasz, kiedy inwestorzy kopiują Twoje transakcje, z uwzględnieniem wielkości ich inwestycji i ich tolerancji na ryzyko.`
    },
    section5: {
        'text1': `Liderzy ZuluTrade:`,
        'text2': `Czym się zajmują?`,
        'text3': `„Lider” to użytkownik ZuluTrade, który udostępnia swoje transakcje i pozwala innym użytkownikom kopiować je na ich koncie. Każda aktywność handlowa na koncie Lidera jest przesyłana do wszystkich Inwestorów Lidera w postaci nadawanego sygnału.`,
        'text4': `Stwórz społeczność i poprowadź ją!`,
        'text5': `research icon`,
        'text6': `Badaj`,
        'text7': `rynek, aktualności i publikacje spółek regularnie.`,
        'text8': `intract icon`,
        'text9': `Nawiązuj interakcje`,
        'text10': `z Inwestorami i przekazuj im aktualne informacje dotyczące strategii handlowej.`,
        'text11': `broadcast icon`,
        'text12': `Nadawaj`,
        'text13': `sygnały handlowe dla Inwestorów ZuluTrade i zapewnij sobie promowanie w rankingach strony internetowej.`
    },
    section6: {
        'text1': `Jak dołączyć do ZuluTrade jako`,
        'text2': ` Lider?`,
        'text3': `Krok 1`,
        'text4': `Zarejestruj się jako Lider na ZuluTrade.`,
        'text5': `Krok 2`,
        'text6': `Upublicznij konto i zacznij handlować.`,
        'text7': `Krok 3`,
        'text8': `Inwestorzy kopiują Twoją strategię, a Ty realizujesz zyski.`,
        'text9': `Dołącz do nas na ZuluTrade`,
        'text10': `Automatycznie uzupełnij swoje dane za pomocą`,
        'text11': `Google icon`,
        'text12': `lub wprowadź je ręcznie`,
        'text13': `Nazwa użytkownika lub adres e-mail`,
        'text14': `Podaj hasło`,
        'text15': `Utwórz konto`
    },
    second5: {
        'text1': `How to become`,
        'text2': `ZuluTrade Affiliate`,
        'text3': `in 3 easy steps?`,
        'text4': `1`,
        'text5': `Click on 'Become an Affiliate'.`,
        'text6': `2`,
        'text7': `Complete the registration and get your unique affiliate URL.`,
        'text8': `3`,
        'text9': `Invite your contacts using unique affiliate URL  and start earning.`,
        'text10': `One-Time Pin has been sent to your email. Please enter it below.`,
        'text11': `Enter OTP`,
        'text12': `Submit`,
        'text13': `Affiliates Account Registration`,
        'text14': `Enter Email Address`,
        'text15': `Enter Your Password`,
        'text16': `Confirm Password`,
        'text17': `Wybierz kraj zamieszkania`,
        'text18': `Company Full Name (For Corporate Accounts)`,
        'text19': `Enter Company Full Name`,
        'text20': `Company's Legal Representative`,
        'text21': `First Name`,
        'text22': `Last Name`,
        'text23': `I agree to the`,
        'text24': `Privacy Policy`,
        'text25': `Terms.`,
        'text26': `Register`,
        'text27': `Back to login`,
        'text28': `Obrót instrumentami finansowymi wiąże się ze znacznym ryzykiem straty. Aby uzyskać pełne wyłączenie odpowiedzialności`,
        'text29': `Kliknij tutaj.`
    },
    affiliate: {
        'text1': `How to become`,
        'text2': `ZuluTrade Affiliate`,
        'text3': `in 3 easy steps?`,
        'text4': `1`,
        'text5': `Click on 'Become an Affiliate'.`,
        'text6': `2`,
        'text7': `Complete the registration and get your unique affiliate URL.`,
        'text8': `3`,
        'text9': `Invite your contacts using unique affiliate URL and start earning.`,
        'text10': `Verify your Email address`,
        'text11': `Enter the 6-digit verification code that was sent to`,
        'text12': `The code is valid for 10 minutes.`,
        'text13': `Resend Code`,
        'text14': `Submit`,
        'text15': `Affiliates Account Registration`,
        'text16': `Enter Email Address`,
        'text17': `Enter Your Password`,
        'text18': `Confirm Password`,
        'text19': `Wybierz kraj zamieszkania`,
        'text20': `Company Full Name (For Corporate Accounts)`,
        'text21': `Enter Company Full Name`,
        'text22': `First Name`,
        'text23': `Last Name`,
        'text24': `I agree to the`,
        'text25': `Privacy Policy`,
        'text26': `&`,
        'text27': `Terms.`,
        'text28': `Register`,
        'text29': `Thank`,
        'text30': `you!`,
        'text31': `Your account has created successfully.`,
        'text32': `Obrót instrumentami finansowymi wiąże się ze znacznym ryzykiem straty. Aby uzyskać pełne wyłączenie odpowiedzialności`,
        'text33': `Kliknij tutaj.`,
    },
    zulutrade: {
        'text1': `Jak dołączyć do ZuluTrade`,
        'text2': `Krok 1`,
        'text3': `Krok 2`,
        'text4': `Krok 3`,
        'text5': `Dołącz do nas na ZuluTrade`,
        'text6': `Automatycznie uzupełnij swoje dane za pomocą`,
        'text7': `lub wprowadź je ręcznie`,
        'text8': `E-mail`,
        'text9': `Hasło`,
        'text10': `Siła hasła`,
        'text11': `1 uppercase character`,
        'text12': `8 or more characters`,
        'text13': `at least 1 number`,
        'text14': `at least 1 letter`,
        'text15': `at least 1 special character`,
        'text16': `Loading...`,
        'text17': `Utwórz konto`,
        'text18': `Obrót instrumentami finansowymi wiąże się ze znacznym ryzykiem straty. Aby uzyskać pełne wyłączenie odpowiedzialności`,
        'text19': `Kliknij tutaj.`,
        'text20': `Imię`,
        'text21': `Numer telefonu`,
        'text22': `Nazwisko`,
        'text23': `Wybierz kraj zamieszkania`,
        'text24': `Szukaj tutaj..`,
        'text25': `Kraj jest wymagany`,
        'text26': `Numer telefonu jest wymagany`,
    },

    // Investor page

    banner: {
        'text1': `Łatwe inwestowanie dzięki`,
        'text2': `CopyTrading i handel społecznościowy!`,
        'text3': `Do Zulu!`,
        'text4': `ZuluTrade to jedna z najlepszych platform do handlu społecznościowego na świecie, z ponad 90k+ profesjonalnych liderów i 2 mln+ aktywnych użytkowników platformy. Odkryj najlepszych liderów z całego świata, kopiuj ich strategie i powielaj ich transakcje w czasie rzeczywistym na swoim koncie inwestycyjnym.`,
        'text5': `Zacznij inwestować`,
        'text6': `ZuluTrade Banner Image`
    },
    third1: {
        'text1': `Podążaj za`,
        'text2': `eksperci`,
        'text3': `i pokonać`,
        'text4': `szanse`,
        'text5': `w świecie handlu z Copy Trading`,
        'text6': `Przenieś swoją podróż handlową na kolejny poziom z ZuluTrade u Twego boku.`,
        'text7': `ZuluTrade Compare Leaders Image`,
        'text8': `Otwórz konto Demo i ucz się przez zabawę!`,
        'text9': `DEMO`,
        'text10': `Become an Affiliate`,
    },
    third2: {
        'text1': `Dlaczego`,
        'text2': `ZuluTrade?`,
        'text3': `ZuluTrade transparent copy trading platform icon`,
        'text4': `Najstarsza i najbardziej transparentna platforma do Copy Tradingu.`,
        'text5': `ZuluTrade license icon`,
        'text6': `ZuluTrade jest objęta nadzorem przez HCMC w UE, FSA w Japonii, FSC na Mauritiusie i FSCA w RPA.`,
        'text7': `ZuluTrade investors profit icon`,
        'text8': `Z naszą wysoko ocenianą obsługą klienta można kontaktować się 24/5 poprzez czat, telefonicznie lub mailowo.`,
        'text9': `ZuluTrade serves globally icon`,
        'text10': `Świadczy usługi w ponad 150 krajach na całym świecie.`,
        'text11': `Average winning ratio`,
        'text12': `Narzędzia społecznościowe umożliwiają interakcje z liderami i innymi inwestorami.`,
        'text13': `vast community`,
        'text14': `Duża społeczność, licząca 2 mln+ użytkowników na całym świecie.`
    },
    third3: {
        'text1': `Co sprawia, że ​​ZuluTrade`,
        'text2': `unikalny?`,
        'text3': `ZuluTrade Unique Features Image`,
        'text4': `ZuluGuard`,
        'text5': `TM`,
        'text6': `Inteligentne, bezpieczne narzędzie do handlu społecznościowego, które zabezpiecza Twoje inwestycje. ZuluGuard chroni Twój kapitał inwestycyjny poprzez nieustanne monitorowanie Twojego konta, zabezpieczanie zysków i ograniczanie potencjalnych strat.`,
        'text7': `Wiele platform`,
        'text8': `Możesz handlować na jednej z wielu dostępnych platform:`,
        'text9': `MT4, MT5, ActTrader, X Open Hub, Match-Trader.`,
        'text10': `Feed społecznościowy`,
        'text11': `Nawiązuj interakcje z innymi inwestorami i liderami na całym świecie. Komentuj, oceniaj, uczestnicz w dyskusjach i okiełznaj rynek, wykorzystując do tego mądrość społeczności.`,
        'text12': `Zacznij kopiować transakcje już teraz`
    },
    third4: {
        'text1': `Jak dołączyć do ZuluTrade jako`,
        'text2': `Inwestor?`,
        'text3': `Krok 1`,
        'text4': `Inwestorzy obserwują liderów po przeanalizowaniu ich wyników.`,
        'text5': `Krok 2`,
        'text6': `Za każdym razem kiedy lider zawiera transakcję, jest ona kopiowana na koncie inwestorów.`,
        'text7': `Krok 3`,
        'text8': `Transakcje, które są zyskowne dla lidera, są również zyskowne dla inwestora.`,
        'text9': `Utwórz konto`,
        'text10': `Obrót instrumentami finansowymi wiąże się ze znacznym ryzykiem straty. Aby uzyskać pełne wyłączenie odpowiedzialności`,
        'text11': `Kliknij tutaj.`
    }
}