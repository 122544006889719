<template>
    <div>
    <section class="become-zulutrade-leader pt80 pb80" id="registerForm">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="section-disc text-center mb-4">
              <h2 id="langAR">
                {{$t('zulutrade.text1')}} {{ a_an }} <span>{{ pageType }}?</span>
              </h2>
            </div>
          </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-4 mb-4 mb-md-0">
              <div class="signUp-step cardBox">
                <h4>{{$t('zulutrade.text2')}}</h4>
                <h3>{{ stepOne }}</h3>
              </div>
            </div>
            <div class="col-12 col-md-4 mb-4 mb-md-0">
              <div class="signUp-step cardBox">
                <h4>{{$t('zulutrade.text3')}}</h4>
                <h3>{{ stepTwo }}</h3>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="signUp-step cardBox">
                <h4>{{$t('zulutrade.text4')}}</h4>
                <h3>{{ stepThree }}</h3>
              </div>
            </div>
            <!-- <div class="col-12 realAccount text-center mb-3">
                            <a target="_blank" class="commonBtn" href="https://www.zulutrade.com/register/"> Create an Account</a>
                        </div> -->
        </div>
      </div>
    </section>
    <div class="footer-bar">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <p class="zuluDisclaimerTitle">
                {{$t('zulutrade.text18')}}
              <a :href="`${static_vars.domainURL}/risk-disclaimer`" target="_blank"
                >{{$t('zulutrade.text19')}}</a
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    props: ["stepOne", "stepTwo", "stepThree", "pageType", "a_an"],
    name: 'sectionFifth'
}
</script>
