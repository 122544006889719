<template>
    <div class="d-flex align-items-center justify-content-center flex-column">
        <div class="ContentBlock d-flex align-items-center w-100"
            style="max-width: 508px; min-width: 259px; border-radius: 8px;">
            <Form @submit="verifyEmailOTP" class="personalDetails w-100" v-if="successStep==1">
                <div class="form-field-row bg-grey mb-4">
                    <div class="form-field-col" v-if="$parent.loginTokenType==2">
                        <div class="mainTitle mb-4">
                            <h3 class="text-center mb-3">{{$t('otp.text1')}}</h3>
                        </div>
                        <label>{{$t('otp.text2')}}</label>
                        <Field name="email" placeholder=" " v-model="form.email" rules="required"
                            :disabled="(store.customerDetail.email && store.customerDetail.emailVerified) ? true : false" />
                        <ErrorMessage class="text-danger" name="email" />
                        <a href="javascript:void(0)" class="button w-100 fillBtn zulu_btn semibold mt-4"
                            :class="[{'disabled': !(!loader && validMail)}]" @click="SubmitData('Email')">
                            <div class="loaderButton spinner-border primary" role="status"
                                v-if="loader && !showEmailCode">
                                <span class="visually-hidden">{{$t('otp.text3')}}</span>
                            </div>
                            <span v-else>{{ !showEmailCode ? 'Get Code' : 'Resend Code'}}</span>
                        </a>
                        <!-- <span class="verifyed semibold" v-if="store.customerDetail.emailVerified && store.customerDetail.email">VERIFIED</span> -->
                    </div>
                    <float-label class="form-field-col pdataEmail position-relative text-center"
                        v-if="$parent.loginTokenType==1">
                        <div class="mainTitle mb-md-5 mb-4">
                            <h3 class="text-center mb-3">{{$t('otp.text4')}}</h3>
                            <p class="mb-2">{{$t('otp.text5')}} <strong>{{form.email ||
                                    store.customerDetail?.email}}</strong></p>
                            <p>{{$t('otp.text6')}}</p>
                        </div>
                        <v-otp-input separator="" input-classes="otp-input" :num-inputs="6" :should-auto-focus="true"
                            @on-change="handleOnChange" @on-complete="handleOnComplete" :autoFormat="false" value = "" :is-input-num="true" :conditionalClass="['one', 'two', 'three', 'four', 'five', 'six']"/>
                        <Field style="display: none;" name="otp" placeholder="" v-model="form.emailcode"
                            rules="required|min:6" />
                        <ErrorMessage class="text-danger" name="otp" />
                        <label class="customcheckBox line-4 my-4 ps-4">
                          {{$t('otp.text7')}} <a target="_blank" class="semibold underline"
                                :href="`${static_vars.domainURL}/terms-of-service`">{{$t('otp.text8')}}</a>,
                            <a class="semibold underline" :href="`${static_vars.domainURL}/privacy-policy`"
                                target="_blank">{{$t('otp.text9')}}</a>,
                            <a class="semibold underline" :href="`${static_vars.domainURL}/conflict-of-interest-policy`"
                                target="_blank">{{$t('otp.text10')}}</a>,
                            <a class="semibold underline" :href="`${static_vars.domainURL}/declaration-of-consent`"
                                target="_blank">{{$t('otp.text11')}}</a> {{$t('otp.text12')}}
                            <a class="semibold underline" :href="`${static_vars.domainURL}/risk-disclaimer`"
                                target="_blank">{{$t('otp.text13')}}</a>
                            <input type="checkbox" v-model="form.terms" />
                            <Field type="hidden" name="Terms" rules="required" v-if="!form.terms" />
                            <span class="checkmark"></span>
                        </label>
                        <ErrorMessage name="Terms" class="text-danger" />
                        <button type="submit"
                            class="button fillBtn w-100 zulu_btn semibold mt-4 position-relative ltoRd">
                            <div class="loaderButton spinner-border primary" role="status"
                                v-if="loader && showEmailCode">
                                <span class="visually-hidden">{{$t('otp.text3')}}</span>
                            </div>
                            <span class="" v-else>{{$t('otp.text14')}}</span>
                        </button>
                    </float-label>
                </div>
            </Form>
            <div class="text-center thankyou-content form-field-row bg-grey" v-else>
                <img class="img-fluid mx-auto mb-3" height="100" width="100"
                    src="../assets/images/zuluImages/Start-journey.svg" />
                <h2 class="text-center mt-0 mb-2">Thank<span class="secondary">you!</span></h2>
                <h6 class="text-center f-18">Your account has created successfully. Redirecting to Dashboard</h6>
            </div>
        </div>
    </div>
</template>

<script>
    import { myStore } from "@/store/pinia";
    import FloatLabel from "vue-float-label/components/FloatLabel";
    import { Form, Field, ErrorMessage } from "vee-validate";
    import $ from "jquery";
    import { nextTick } from 'vue';
    export default {
        setup() {
            const store = myStore();
            const Crypto = require("crypto-js");
            return { store, Crypto };
        },
        data() {
            return {
                form: {
                    emailcode: "",
                    email: "",
                    terms: false
                },
                successStep: 1,
                showEmailCode: false,
                validMail: false,
                loader: false,
            };
        },
        watch: {
            "form.email"() {
                if (this.validEmail()) {
                    this.validMail = true;
                } else {
                    this.validMail = false;
                }
            },
            "store.customerDetail"() {
                if (Object.keys(this.store.customerDetail).length && this.store.customerDetail?.email) {
                    this.getEmailCode()
                }
            }
        },
        components: {
            FloatLabel,
            Form,
            Field,
            ErrorMessage,
        },
        methods: {
            CretioFunc() {
                try {
                    window.gtag("event", "test_verify");
                    window.fbq('track','email_verify', {'email' : this.store.customerDetail?.email,'value' : '20','country' : this.store.flavordata?.userCountry?.name }, {eventID: `${this.store.customerDetail?.id}_email_verify`});

                    // window.fbq('track', 'LEAD',{'email' : this.store.customerDetail?.email,'value' : '20','country' : this.store.flavordata?.userCountry?.name }, {eventID: `${this.store.customerDetail?.id}_email_verify`});

                    if (Object.keys(this.store.flavordata).length && this.store.flavordata.userCountry && Object.keys(this.store.flavordata.userCountry).length && this.store.flavordata.userCountry.isoCode) {
                        for (var key in this.static_vars.cretioCountryJson) {
                            if (key == this.store.flavordata.userCountry.isoCode) {
                                var s = document.createElement("script");
                                s.type = "text/javascript";
                                s.async = true;
                                s.src = `//dynamic.criteo.com/js/ld/ld.js?a=${this.static_vars.cretioCountryJson[key]}`;
                                $("head").append(s);
                                window.criteo_q = window.criteo_q || [];
                                var deviceType = /iPad/.test(navigator.userAgent) ? "t" : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? "m" : "d";
                                window.criteo_q.push(
                                    { event: "setaccount", account: this.static_vars.cretioCountryJson[key] }, // You should never update this line
                                    { event: "setSiteType", type: deviceType },
                                    //{ event: "setemail", email: this.Crypto.SHA256(this.store.customerDetail.email).toString() , hash_method: "sha256" }, 
                                    { event: "setemail", email: this.Crypto.SHA256('1').toString(), hash_method: "sha256" },
                                    {
                                        event: "trackTransaction", id: (Math.random() * 50000), item: [
                                            { id: Math.ceil(Math.random() * 10300 * Math.random() * 50000), price: 1, quality: 1 }
                                        ]
                                    }
                                );
                            }
                        }
                    }
                } catch (e) {
                    console.log("cretio ex", e);
                }
            },
            getEmailCode() {
                // let formData = {};
                // formData["identifier"] = "EMAIL";
                // formData["email"] = this.form.email ? this.form.email : (this.store.customerDetail?.email);
                // this.store.resendOTP(formData, true, this).then(() => {
                this.showEmailCode = true;
                this.$parent.loginTokenType = 1
                // });
            },
            validEmail() {
                let regexp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                return regexp.test(this.form.email.toLowerCase());
            },
            SubmitData(type) {
                if (!this.store.customerDetail.email) {
                    // let formData = {};
                    // if (type == "Email" && !this.store.customerDetail.emailVerified) {
                    //     formData["email"] = this.form.email;
                    // }
                    this.getEmailCode()
                }
            },
            reset() {
                this.form.code = "";
            },
            handleOnComplete(value) {
                this.form.emailcode = value;
            },
            handleOnChange(value) {
                this.form.emailcode = value;
            },
            resendOTP() {
                this.reset();
                let formData = {};
                formData["identifier"] = "EMAIL";
                formData["email"] = this.form.email ? this.form.email : (this.store.customerDetail?.email);
                this.store.resendOTP(formData, true, this);
            },
            verifyEmailOTP() {
                if (this.form.emailcode) {
                    let formData = {};
                    formData["code"] = this.form.emailcode;
                    formData["email"] = this.form.email ? this.form.email : (this.store.customerDetail?.email);
                    this.store.verifyOTP(formData, true, this, 'web').then((response) => {
                        if (response === true) {
                            this.successStep = 2;
                        }
                    });
                }
            },
            // verifyOTP() {
            //     console.log('verifyOTP')
            //     let formData = {};
            //     formData["code"] = this.form.code;
            //     formData["email"] = this.store.customerDetail.email;
            //     this.store.verifyOTP(formData, true, this).then(() => {
            //         let data = this.store.customerDetail;
            //         data.emailVerified = true;
            //         this.store.$patch({ customerDetail: data });
            //     });
            // },
        },
        mounted() {
            nextTick(() => {
                const phoneInput = this.$el.querySelector('.otp-input.one')
                if (phoneInput) {
                    phoneInput.focus();
                }
            })
        },
    };
</script>
<style>
    .otp-input {
        width: 50px;
        height: 50px;
        padding: 5px;
        margin: 0 10px;
        font-size: 20px;
        border-radius: 4px;
        border: 1px solid rgb(0 0 0 / 15%);
        text-align: center;
    }

    .pdataEmail.position-relative {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    /* Background colour of an input field with value */
    .otp-input.is-complete {
        background-color: #e4e4e4;
    }

    .otp-input::-webkit-inner-spin-button,
    .otp-input::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
</style>
