export default {
    locales: {
        de: "German"
    },
    // leader page
    leader: {
        'text1': `ZuluTrade Logo`,
        'text2': `Jetzt registrieren`,
        'text3': `Inicie sesión`,
        'text4': `Melden Sie sich als Führungskraft bei ZuluTrade an.`,
        'text5': `Machen Sie Ihr Konto öffentlich und beginnen Sie mit dem Handel.`,
        'text6': `Investoren kopieren Ihre Strategie und Sie erhalten Ihre Erträge.`,
        'text7': `Führerin`,
        'text8': `A`,
        'text9': `Investoren folgen Führungskräften, nachdem sie deren Leistungsseite analysiert haben.`,
        'text10': `Jedes Mal, wenn ein Marktführer einen Trade tätigt, wird dieser auch auf das Konto des Anlegers kopiert.`,
        'text11': `Trades, die für den Marktführer profitabel sind, sind auch für den Investor profitabel.`,
        'text12': `Investorin`,
        'text13': `ein`
    },
    pages: {
        'text1': `Treten Sie der Weltspitze bei`,
        'text2': `Sozialer Handel`,
        'text3': `Gemeinschaft!`,
        'text4': `Sie können pro gehandelten 100.000 USD 5–10 USD verdienen. Die Kosten hängen ab von den gehandelten Währungspaaren, dem Kontotyp usw.`,
        'text5': `Werden Sie Teil einer globalen Community mit mehr als 2 Millionen Nutzern und 90.000+ Leadern auf der besten Social-Trading-Plattform.`,
        'text6': `Teilen Sie Ihre Strategien und verdienen Sie Provisionen durch kopierte Trades. Treten Sie ZuluTrade jetzt bei!`,
        'text7': `ZuluTrade Leader`,
        'text8': `Leader-Konto eröffnen`,
        'text9': `Mehr erfahren`
    },
    second3: {
        'text1': `Enjoy Flexible`,
        'text2': `Compensation Schemes`,
        'text3': `Our default monetization model is CPA, but we are open to discussing more options for established affiliates, like hybrid and custom deals if they can deliver large volumes of customers. Our CPA playouts are categorized into Tiers, depending on the Geolocation of the accounts referred by our affiliates.`,
        'text4': `Maximize your earnings with our affiliate program. Earn up to 300$ per investor introduced, and claim even more with our hybrid custom CPA model`,
        'text5': `Become an Affiliate`,
        'text6': `Mehr erfahren`,
        'text7': `Affiliate Zulu Coin`
    },
    header: {
        'text12': `ZuluTrade's`,
    },
    section2: {
        'text1': `Top-Führungskräfte`,
        'text2': `Strategien, die eine positive Dynamik aufweisen und sich nahe ihres Allzeithochs bewegen`,
        'text3': `Kopierer`,
        'text4': `Follower`,
        'text5': `Schließen Sie sich den Besten unter ihnen an`
    },
    section3: {
        'text1': `Warum sollten Sie sich ZuluTrade anschließen?`,
        'text2': `Führerin?`,
        'text3': `ZuluTrade licence icon`,
        'text4': `ZuluTrade wird von der HCMC in der EU, von der FSA in Japan, von der FSC in Mauritius und von der FSCA in Südafrika reguliert.`,
        'text5': `ZuluTrade advanced and feature rich icon`,
        'text6': `Fortgeschrittene und multifunktionale Plattform, um Ihr Handelserlebnis zu personalisieren.`,
        'text7': `ZuluTrade support icon`,
        'text8': `Verbesserung der Profitabilität durch einen speziellen Traders Desk, um Anleger zu gewinnen und zu binden.`,
        'text9': `ZuluTrade No hidden costs icon`,
        'text10': `Keine versteckten Kosten oder monatlichen Auszahlungslimits.`,
        'text11': `ZuluTrade multiple brokers icon`,
        'text12': `Die Plattform wurde konzipiert, um mehrere Broker zu unterstützen.`,
        'text13': `ZuluTrade network icon`,
        'text14': `Umfassendes Social-Trading-Netzwerk mit mehr als 90.000 Leadern.`
    },
    section4: {
        'text1': `Wie`,
        'text2': `ZuluTrade`,
        'text3': `funktioniert für`,
        'text4': `Führerin?`,
        'text5': `Join ZuluTrade icon`,
        'text6': `Werden Sie ein echter Leader auf ZuluTrade`,
        'text7': `Schließen Sie sich ZuluTrade als Leader an, verbinden Sie Ihr Konto und teilen Sie Ihre erfolgreichen Handelsstrategien!`,
        'text8': `Rankings icon`,
        'text9': `Heben Sie sich ab durch Ihren Erfolg`,
        'text10': `Unser Bewertungssystem berücksichtigt Leistung, Stabilität, Verhalten und Ausblick.`,
        'text11': `Earnings icon`,
        'text12': `Einkünfte ohne Stress`,
        'text13': `Als Leader erzielen Sie Einkünfte, wenn Anleger Ihre Trades kopieren unter Berücksichtigung ihrer Anlagegröße und Risikotoleranz.`
    },
    section5: {
        'text1': `ZuluTrade Leader:`,
        'text2': `Was tun sie eigentlich?`,
        'text3': `Ein „Leader“ ist ein ZuluTrade-Nutzer, der seine Trades teilt und anderen Nutzern erlaubt, seine Trades in ihr Konto zu kopieren. Jede Handelsaktion, die ein Leader in seinem Konto durchführt, wird an alle Anleger des Leaders geschickt in Form eines Sendesignals.`,
        'text4': `Gründen Sie eine Community und führen Sie sie an!`,
        'text5': `research icon`,
        'text6': `Sondieren`,
        'text7': `Sie den Markt, die Nachrichten und die Unternehmensmeldungen regelmäßig.`,
        'text8': `intract icon`,
        'text9': `Interagieren`,
        'text10': `Sie mit Anlegern und geben Sie ihnen Updates zu Ihrer Handelsstrategie.`,
        'text11': `broadcast icon`,
        'text12': `Übertragen`,
        'text13': `Sie Handelssignale an die ZuluTrade-Anleger und platzieren Sie sich in den Website-Rankings.`
    },
    section6: {
        'text1': `So treten Sie ZuluTrade als bei`,
        'text2': `Führerin?`,
        'text3': `Schritt 1`,
        'text4': `Registrieren Sie sich als Leader bei ZuluTrade.`,
        'text5': `Schritt 2`,
        'text6': `Machen Sie Ihr Konto öffentlich und beginnen Sie zu handeln.`,
        'text7': `Schritt 3`,
        'text8': `Anleger kopieren Ihre Strategie und Sie erhalten Ihre Erträge.`,
        'text9': `Schließen Sie sich ZuluTrade an`,
        'text10': `Füllen Sie Ihre persönlichen Daten automatisch mit aus`,
        'text11': `Google icon`,
        'text12': `oder manuell befüllen`,
        'text13': `Benutzername oder E-Mail:`,
        'text14': `Passwort eingeben`,
        'text15': `Konto erstellen`
    },
    second5: {
        'text1': `How to become`,
        'text2': `ZuluTrade Affiliate`,
        'text3': `in 3 easy steps?`,
        'text4': `1`,
        'text5': `Click on 'Become an Affiliate'.`,
        'text6': `2`,
        'text7': `Complete the registration and get your unique affiliate URL.`,
        'text8': `3`,
        'text9': `Invite your contacts using unique affiliate URL  and start earning.`,
        'text10': `One-Time Pin has been sent to your email. Please enter it below.`,
        'text11': `Enter OTP`,
        'text12': `Submit`,
        'text13': `Affiliates Account Registration`,
        'text14': `Enter Email Address`,
        'text15': `Enter Your Password`,
        'text16': `Confirm Password`,
        'text17': `Select Residence Country`,
        'text18': `Company Full Name (For Corporate Accounts)`,
        'text19': `Enter Company Full Name`,
        'text20': `Company's Legal Representative`,
        'text21': `Vorname`,
        'text22': `Familienname, Nachname`,
        'text23': `I agree to the`,
        'text24': `Privacy Policy`,
        'text25': `Terms.`,
        'text26': `Register`,
        'text27': `Back to login`,
        'text28': `Der Handel mit Finanzinstrumenten birgt erhebliche Verlustrisiken. Für den vollständigen Haftungsausschluss`,
        'text29': `klicken Sie hier.`
    },
    affiliate: {
        'text1': `How to become`,
        'text2': `ZuluTrade Affiliate`,
        'text3': `in 3 easy steps?`,
        'text4': `1`,
        'text5': `Click on 'Become an Affiliate'.`,
        'text6': `2`,
        'text7': `Complete the registration and get your unique affiliate URL.`,
        'text8': `3`,
        'text9': `Invite your contacts using unique affiliate URL and start earning.`,
        'text10': `Verify your Email address`,
        'text11': `Enter the 6-digit verification code that was sent to`,
        'text12': `The code is valid for 10 minutes.`,
        'text13': `Resend Code`,
        'text14': `Submit`,
        'text15': `Affiliates Account Registration`,
        'text16': `Enter Email Address`,
        'text17': `Enter Your Password`,
        'text18': `Confirm Password`,
        'text19': `Select Residence Country`,
        'text20': `Company Full Name (For Corporate Accounts)`,
        'text21': `Enter Company Full Name`,
        'text22': `Vorname`,
        'text23': `Familienname, Nachname`,
        'text24': `I agree to the`,
        'text25': `Privacy Policy`,
        'text26': `&`,
        'text27': `Terms.`,
        'text28': `Register`,
        'text29': `Thank`,
        'text30': `you!`,
        'text31': `Your account has created successfully.`,
        'text32': `Der Handel mit Finanzinstrumenten birgt erhebliche Verlustrisiken. Für den vollständigen Haftungsausschluss`,
        'text33': `klicken Sie hier.`,
    },
    zulutrade: {
        'text1': `Wie können Sie sich ZuluTrade als`,
        'text2': `Schritt 1`,
        'text3': `Schritt 2`,
        'text4': `Schritt 3`,
        'text5': `Schließen Sie sich ZuluTrade an`,
        'text6': `Füllen Sie Ihre persönlichen Daten automatisch mit aus`,
        'text7': `oder manuell befüllen`,
        'text8': `Email`,
        'text9': `Passwort`,
        'text10': `Passwortstärke`,
        'text11': `1 uppercase character`,
        'text12': `8 or more characters`,
        'text13': `at least 1 number`,
        'text14': `at least 1 letter`,
        'text15': `at least 1 special character`,
        'text16': `Loading...`,
        'text17': `Konto erstellen`,
        'text18': `Der Handel mit Finanzinstrumenten birgt erhebliche Verlustrisiken. Für den vollständigen Haftungsausschluss`,
        'text19': `klicken Sie hier.`,
        'text20': `Vorname`,
        'text21': `Telefonnummer`,
        'text22': `Familienname, Nachname`,
        'text23': `Wählen Sie Wohnsitzland`,
        'text24': `Suche hier..`,
        'text25': `Land ist erforderlich`,
        'text26': `Telefonnummer ist erforderlich`,
    },
    // Investor page
    
    banner: {
        'text1': `Investieren leicht gemacht mit`,
        'text2': `CopyTrading und Social Trading!`,
        'text3': `Geh Zulu!`,
        'text4': `ZuluTrade ist eine der weltweit führenden Social-Trading-Plattformen mit mehr als 90.000 Pro-Leadern und mehr als 2 Mio. aktiven Nutzern auf der Plattform. Entdecken Sie Top-Leader aus der ganzen Welt, kopieren Sie ihre Strategie und vollziehen Sie ihre Trades in Echtzeit in Ihrem Anlagekonto nach.`,
        'text5': `Jetzt investieren`,
    },
    third1: {
        'text1': `Folge dem`,
        'text2': `Experten`,
        'text3': `und schlage die`,
        'text4': `Chancen`,
        'text5': `in der Welt des Handels mit Copy Trading`,
        'text6': `Erschließen Sie sich eine neue Dimension des Handels mit ZuluTrade an Ihrer Seite.`,
        'text7': `ZuluTrade Compare Leaders Image`,
        'text8': `Eröffnen Sie ein Demo-Konto und lernen Sie spielerisch!`,
        'text9': `DEMO`,
        'text10': `Become an Affiliate`,
    },
    third2: {
        'text1': `Was spricht für `,
        'text2': `ZuluTrade?`,
        'text3': `ZuluTrade transparent copy trading platform icon`,
        'text4': `Die älteste und transparenteste Copy-Trading-Plattform.`,
        'text5': `ZuluTrade license icon`,
        'text6': `ZuluTrade wird von der HCMC in der EU, von der FSA in Japan, von der FSC in Mauritius und von der FSCA in Südafrika reguliert.`,
        'text7': `ZuluTrade investors profit icon`,
        'text8': `Unser hoch bewerteter Kundensupport ist für Sie rund um die Uhr an fünf Tagen per Chat, Telefon und E-Mail erreichbar!`,
        'text9': `ZuluTrade serves globally icon`,
        'text10': `Betreuung von Kunden in mehr als 150 Ländern.`,
        'text11': `Average winning ratio`,
        'text12': `Mit sozialen Tools können Sie mit Leadern und anderen Anlegern in Kontakt treten.`,
        'text13': `vast community`,
        'text14': `Eine umfassende Community von mehr als 2 Mio. Nutzern weltweit.`
    },
    third3: {
        'text1': `Was macht ZuluTrade`,
        'text2': ` einzigartig?`,
        'text3': `ZuluTrade Unique Features Image`,
        'text4': `ZuluGuard`,
        'text5': `TM`,
        'text6': `Ein intelligentes, sicheres Social-Trading-Tool für Ihre Anlagen. ZuluGuard schützt Ihr Anlagekapital, indem es Ihr Konto ständig überwacht, Gewinne sichert und mögliche Verluste reduziert.`,
        'text7': `Mehrere Plattformen`,
        'text8': `Sie haben die Wahl unter mehreren Plattformen, was die Durchführung von Handelsgeschäften betrifft:`,
        'text9': `MT4, MT5, ActTrader, X Open Hub, Match-Trader.`,
        'text10': `Sozialer Feed`,
        'text11': `Interagieren Sie mit anderen Anlegern und Leadern weltweit. Kommentieren, bewerten, beteiligen Sie sich an Diskussionen und zähmen Sie den Markt mithilfe der Weisheit der Social Community.`,
        'text12': `Jetzt mit CopyTrading starten`
    },
    third4: {
        'text1': `So treten Sie ZuluTrade als bei`,
        'text2': `Investorin?`,
        'text3': `Schritt 1`,
        'text4': `Anleger folgen Leadern nach der Analyse ihrer Performance-Seite.`,
        'text5': `Schritt 2`,
        'text6': `Jedes Mal, wenn ein Leader einen Trade durchführt, wird dieser in die Anlegerkonten kopiert.`,
        'text7': `Schritt 3`,
        'text8': `Trades, die für den Leader profitabel sind, sind auch für Anleger profitabel.`,
        'text9': `Konto erstellen`,
        'text10': `Der Handel mit Finanzinstrumenten birgt erhebliche Verlustrisiken. Für den vollständigen Haftungsausschluss`,
        'text11': `klicken Sie hier.`
    }
}
