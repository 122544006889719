<template>
    <section class="pt80 pb80">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="section-disc text-center">
                        <h2 id="langAR">{{$t('third3.text1')}} <span>{{$t('third3.text2')}}</span></h2>
                    </div>
                </div>
            </div>
            <div class="row align-items-center mt-5">
                <div class="col-md-6 col-12">
                    <div class="zulutrade-unique-img">
                        <img class="img-fluid" src="../../assets/images/zulutradeSolutionImages/makes-unique.png" :alt="$t('third3.text3')"/>
                    </div>
                </div>
                <div class="col-md-6 col-12">
                    <div class="zulutrade-Info-col">
                        <div class="headSec d-flex align-items-center mb-2">
                            <img class="img-fluid" src="../../assets/images/zulutradeSolutionImages/zulugaurd.svg" :alt="$t('third3.text3')"/>
                            <h3 id="langAR" class="mb-0">{{$t('third3.text4')}}<sup>{{$t('third3.text5')}}</sup></h3>
                        </div>
                        <p>{{$t('third3.text6')}}</p>
                    </div>
                    <div class="zulutrade-Info-col">
                        <div class="headSec d-flex align-items-center mb-2">
                            <img class="img-fluid" src="../../assets/images/zulutradeSolutionImages/multip.svg" :alt="$t('third3.text3')"/>
                            <h3 class="mb-0">{{$t('third3.text7')}}</h3>
                        </div> 
                        <p> {{$t('third3.text8')}}<br> {{$t('third3.text9')}}</p>
                    </div>
                    <div class="zulutrade-Info-col">
                        <div class="headSec d-flex align-items-center mb-2">
                            <img class="img-fluid" src="../../assets/images/zulutradeSolutionImages/feed.svg" :alt="$t('third3.text3')"/>
                            <h3 class="mb-0">{{$t('third3.text10')}}</h3>
                        </div>
                        <p>{{$t('third3.text11')}}</p>
                    </div>
                </div>
            </div>
            <div class="f-flex banner-links text-center">
                <a href="#bannerForm" class="commonBtn linkBtn btn-minWidth"><span>{{$t('third3.text12')}}</span></a>
            </div>
        </div>
    </section>
</template>
<script>
    export default {
        name: 'sectionForth'
    }
</script>
