export default {
    domainURL: `${process.env.VUE_APP_DOMAIN_URL}`,
    imageURL: `${process.env.VUE_APP_IMAGE_URL}`,
    CLIENT_ID: `${process.env.VUE_APP_CLIENT_ID}`,
    CLIENT_SECRET: `${process.env.VUE_APP_CLIENT_SECRET}`,
    // /* sandbox */ CHECKOUT_PUBLIC_API_KEY: 'pk_sbox_jwvr24ls3dgmosm5hf2kwgd6qam',
    /* production */ CHECKOUT_PUBLIC_API_KEY: 'pk_diflje6hb4a3y6mfdcxtsxmumqy',
    recaptchaKey : '6LdwGp0aAAAAAPtOmYIr6Mu9eOqXoJU1qR28JZRn',
    setCookie:(cname,cvalue,exdays)=>{
      const d = new Date();
      d.setTime(d.getTime() + (exdays*24*60*60*1000));
      let expires = "expires=" + ((cvalue)?d.toUTCString():new Date());
      document.cookie = cname + "=" + encodeURIComponent(cvalue) + ";" + expires + ";path=/";
    },
    getCookie:(cname)=>{
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    },
    delete_cookie:(name) => {
      document.cookie = name +'=; path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    },
    cretioCountryJson: {
        'AE': '104592',
        'AR': '104593',
        'AT': '104594',
        'AU': '103297',
        'BR': '104595',
        'CA': '104596',
        'CH': '104597',
        'CZ': '104598',
        'DE': '103300',
        'ES': '103296',
        'GR': '103294',
        'ID': '104599',
        'IT': '103060',
        'MY': '104600',
        'NL': '103308',
        'PH': '104601',
        'PL': '103295',
        'QA': '104602',
        'SA': '104603',
        'SG': '104604',
        'TH': '104605',
        'ZA': '104606',
    },
}