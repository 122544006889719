<template>
    <div class="d-flex align-items-center justify-content-center flex-column">
        <div class="ContentBlock newGetOTP d-flex align-items-center w-100"
            style="max-width: 508px; min-width: 259px; border-radius: 8px;">
            <Form class="personalDetails w-100" v-if="successStep==1">
                <!-- @submit="verifyEmailOTP"  -->
                <div class="form-field-row p-2">
                    <div class="form-field-col" v-if="$parent.loginTokenType==2">
                        <div class="mainTitle mb-4">
                            <h6 class="text-center mb-3">{{$t('otp.text1')}}</h6>
                        </div>
                        <label>{{$t('otp.text2')}}</label>
                        <Field name="email" placeholder=" " v-model="form.email" rules="required"
                            :disabled="(store.customerDetail.email && store.customerDetail.emailVerified) ? true : false" />
                        <ErrorMessage class="text-danger text-start d-block f-14" name="email" />
                        <a href="javascript:void(0)" class="button w-100 fillBtn zulu_btn semibold mt-4"
                            :class="[{'disabled': !(!loader && validMail)}]" @click="SubmitData('Email')">
                            <div class="loaderButton spinner-border primary" role="status"
                                v-if="loader && !showEmailCode">
                                <span class="visually-hidden">{{$t('otp.text3')}}</span>
                            </div>
                            <span v-else>{{ !showEmailCode ? 'Get Code' : 'Resend Code'}}</span>
                        </a>
                        <!-- <span class="verifyed semibold" v-if="store.customerDetail.emailVerified && store.customerDetail.email">VERIFIED</span> -->
                    </div>
                    <float-label class="form-field-col pdataEmail position-relative text-center"
                        v-if="$parent.loginTokenType==1">
                        <div class="mainTitle mb-4">
                            <h5 class="text-center mb-3">{{$t('otp.text4')}}</h5>
                            <p class="mb-3 line-4">{{$t('otp.text5')}} <strong>{{store.customerDetail?.email}}</strong> and <strong>{{store.customerDetail?.phoneNumber}}</strong></p>
                            <p class="validOTP" v-html="$t('otp.text6')"></p>
                        </div>
                        <div class="w-100" v-if="!store.customerDetail.emailVerified">
                            <!-- <v-otp-input separator="" input-classes="otp-input" :num-inputs="6" :shouldAutoFocus="true"
                                @on-change="handleOnChange" @on-complete="handleOnComplete" :autoFormat="false" value = "" />
                            <Field style="display: none;" name="otp" placeholder="" v-model="form.emailcode"
                                rules="required|min:6" />
                            <ErrorMessage class="text-danger text-start d-block f-14" name="otp" /> -->
                            <float-label class="text-start m-0 form-field-col w-100 pe-md-2">
                                <label class="mb-1">Email OTP</label>
                                <div class="position-relative">
                                    <Field type="number" name="email otp" v-model="form.emailcode" rules="required" />
                                    <a href="javascript:void(0)" :class="{'disabled' : !form.emailcode}" class="getCode position-absolute" v-if="!store.singleLoading && !store.customerDetail.emailVerified" @click="verifyEmailOTP()">
                                        <span class="loaderButton spinner-border primary" role="status" v-if="loader && !store.customerDetail.emailVerified">
                                            <span class="visually-hidden">{{$t('otp.text3')}}</span>
                                        </span>
                                        <span v-else>Verify</span>
                                    </a>
                                </div>
                                <!-- <ErrorMessage class="text-danger text-start d-block f-14" name="email otp" /> -->
                                <div :class="emailError?'flex-between mt-1':'text-end mt-1'">
                                    <span v-if="emailError" role="alert" class="text-danger text-start d-block f-14">Otp is required.</span>
                                    <a href="javascript:void(0)" class="link f-12" v-if="!store.singleLoading && !store.customerDetail.emailVerified" @click="resendOTP()">Resend Code</a>
                                </div>
                               
                            </float-label>
                            
                        </div>
                        <div class="verifyed inputField d-flex align-items-center w-100 mb-3" v-if="store.customerDetail.emailVerified">
                            <span>{{store.customerDetail.email}}</span>
                            <span class="d-flex align-items-center verifyed position-absolute semibold" v-if="store.customerDetail.emailVerified && store.customerDetail.email"><vue-feather type="check-circle" class="me-2" size="19"></vue-feather> Verified</span>
                        </div>
                        <div class="w-100" v-if="!store.customerDetail.phoneVerified">
                            <float-label class="text-start m-0 form-field-col w-100 transparent border-0 position-relative" :class="{'disabled' : !store.customerDetail.emailVerified}">
                                <label class="mb-1">Phone OTP</label>
                                <div class="position-relative">
                                    <Field type="number" name="phone otp" v-model="form.phonecode" rules="required" />
                                    <a href="javascript:void(0)" :class="{'disabled' : !form.phonecode}" class="getCode position-absolute" v-if="!store.singleLoading && !store.customerDetail.phoneVerified" @click="verifySMSOTP()">
                                        <span class="loaderButton spinner-border primary" role="status" v-if="loader && !store.customerDetail.phoneVerified && store.customerDetail.emailVerified">
                                            <span class="visually-hidden">{{$t('otp.text3')}}</span>
                                        </span>
                                        <span v-else>Verify</span>
                                    </a>
                                </div>
                                <!-- <a class="countTime" href="javascript:void(0)" :class="(!showSMSCode) ? '' : ''" v-if="!store.customerDetail.phoneVerified" @click="resendPhoneOTP()">
                                    <span>{{(!showSMSCode ? '' : (showOtp) ? `${countTime}s`  : '')}}</span>
                                </a> -->
                                <div :class="isPhone?'flex-between mt-1':'text-end mt-1'">
                                    <span v-if="isPhone" role="alert" class="text-danger text-start d-block f-14">OTP is required.</span>
                                    <a href="javascript:void(0)" class="link f-12" v-if="!store.singleLoading && !store.customerDetail.phoneVerified && !showOtp" @click="resendPhoneOTP()">Resend Code</a>
                                    <span v-if="!store.singleLoading && !store.customerDetail.phoneVerified && showOtp" class="secondary">{{(showOtp) ? `${countTime}s`  : ''}}</span>
                                </div>
                                <!-- <ErrorMessage class="text-danger text-start d-block f-14" name="phone otp" /> -->
                                <!-- <a href="javascript:void(0)" class="getCode semibold" v-if="!store.singleLoading && !store.customerDetail.phoneVerified" @click="SubmitData('Phone')" :class="{'disabled' : !store.customerDetail.emailVerified}">Resend Code</a> -->

                               
                                
                                <span class="d-flex align-items-center verifyed position-absolute semibold" v-if="store.customerDetail?.phoneVerified"><vue-feather type="check-circle" class="me-1" size="15"></vue-feather> Verified</span>
                            </float-label>
                            
                        </div>
                        <div class="verifyed inputField d-flex align-items-center w-100" v-if="store.customerDetail.phoneVerified">
                            <span>{{store.customerDetail.phoneNumber}}</span>
                            <span class="d-flex align-items-center verifyed position-absolute semibold" v-if="store.customerDetail?.phoneVerified"><vue-feather type="check-circle" class="me-1" size="15"></vue-feather> Verified</span>
                        </div>
                        <label class="customcheckBox line-4 my-4 ps-4">
                          {{$t('otp.text7')}} <a target="_blank" class="semibold underline"
                                :href="`${static_vars.domainURL}/terms-of-service`">{{$t('otp.text8')}}</a>,
                            <a class="semibold underline" :href="`${static_vars.domainURL}/privacy-policy`"
                                target="_blank">{{$t('otp.text9')}}</a>,
                            <a class="semibold underline" :href="`${static_vars.domainURL}/conflict-of-interest-policy`"
                                target="_blank">{{$t('otp.text10')}}</a>,
                            <a class="semibold underline" :href="`${static_vars.domainURL}/declaration-of-consent`"
                                target="_blank">{{$t('otp.text11')}}</a>{{$t('otp.text12')}}
                            <a class="semibold underline" :href="`${static_vars.domainURL}/risk-disclaimer`"
                                target="_blank">{{$t('otp.text13')}}</a>
                            <input type="checkbox" v-model="form.terms" />
                            <Field type="hidden" name="Terms" rules="required" v-if="!form.terms" />
                            <span class="checkmark"></span>
                        </label>
                        <ErrorMessage name="Terms" class="text-danger text-start d-block f-14" />
                        <button type="submit"
                            class="button fillBtn w-100 zulu_btn semibold mt-4 position-relative ltoRd" :class="{'disabled' : !store.customerDetail.emailVerified || !store.customerDetail.phoneVerified || !form.terms}" @click.prevent="Continue()">
                            <!-- <div class="loaderButton spinner-border primary" role="status"
                                v-if="loader">
                                <span class="visually-hidden" :class="{'disabled' : !store.customerDetail.emailVerified && !store.customerDetail.phoneVerified}">Continue</span>
                            </div> -->
                            <span class="">Continue</span>
                        </button>
                    </float-label>
                </div>
            </Form>
            <div class="text-center thankyou-content form-field-row" v-else>
                <img class="img-fluid mx-auto mb-3" height="100" width="100"
                    src="../assets/images/zuluImages/Start-journey.svg" />
                <h2 class="text-center mt-0 mb-2">Thank<span class="secondary">you!</span></h2>
                <h6 class="text-center f-18">Your account has created successfully.</h6>
                <h6>Redirecting to Dashboard</h6>
            </div>
        </div>
    </div>
</template>

<script>
    import { myStore } from "@/store/pinia";
    import FloatLabel from "vue-float-label/components/FloatLabel";
    import { Form, Field, ErrorMessage } from "vee-validate";
    import $ from "jquery";
    import { load } from "recaptcha-v3";
    export default {
        setup() {
            const store = myStore();
            const Crypto = require("crypto-js");
            return { store, Crypto };
        },
        data() {
            return {
                form: {
                    emailcode: "",
                    phonecode : "",
                    email: "",
                    terms: false
                },
                successStep: 1,
                showEmailCode: false,
                validMail: false,
                loader: false,
                captchaToken : '',
                PhoneInstruction : false,
                countTime : 60,
                showOtp : false,
                emailError : false,
                isPhone : false,
                showSMSCode : false,
            };
        },
        watch: {
            "form.email"() {
                if (this.validEmail()) {
                    this.validMail = true;
                } else {
                    this.validMail = false;
                }
            },
            "store.customerDetail"() {
                if (Object.keys(this.store.customerDetail).length && this.store.customerDetail?.email) {
                    this.getEmailCode()
                }
            },
            "form.emailcode"(){
                if(this.form.emailcode){
                    this.emailError = false
                }else{
                    this.emailError = true
                }
            },
            "form.phonecode"(){
                if(this.form.phonecode){
                    this.isPhone = false
                }else{
                    this.isPhone = true
                }
            }
        },
        components: {
            FloatLabel,
            Form,
            Field,
            ErrorMessage,
        },
        methods: {
            CretioFunc() {
                try {
                    window.gtag("event", "test_verify");
                    window.fbq('track','email_verify', {'email' : this.store.customerDetail?.email,'value' : '20','country' : this.store.flavordata?.userCountry?.name }, {eventID: `${this.store.customerDetail?.id}_email_verify`});

                    // window.fbq('track', 'LEAD',{'email' : this.store.customerDetail?.email,'value' : '20','country' : this.store.flavordata?.userCountry?.name }, {eventID: `${this.store.customerDetail?.id}_email_verify`});

                    if (Object.keys(this.store.flavordata).length && this.store.flavordata.userCountry && Object.keys(this.store.flavordata.userCountry).length && this.store.flavordata.userCountry.isoCode) {
                        for (var key in this.static_vars.cretioCountryJson) {
                            if (key == this.store.flavordata.userCountry.isoCode) {
                                var s = document.createElement("script");
                                s.type = "text/javascript";
                                s.async = true;
                                s.src = `//dynamic.criteo.com/js/ld/ld.js?a=${this.static_vars.cretioCountryJson[key]}`;
                                $("head").append(s);
                                window.criteo_q = window.criteo_q || [];
                                var deviceType = /iPad/.test(navigator.userAgent) ? "t" : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? "m" : "d";
                                window.criteo_q.push(
                                    { event: "setaccount", account: this.static_vars.cretioCountryJson[key] }, // You should never update this line
                                    { event: "setSiteType", type: deviceType },
                                    //{ event: "setemail", email: this.Crypto.SHA256(this.store.customerDetail.email).toString() , hash_method: "sha256" }, 
                                    { event: "setemail", email: this.Crypto.SHA256('1').toString(), hash_method: "sha256" },
                                    {
                                        event: "trackTransaction", id: (Math.random() * 50000), item: [
                                            { id: Math.ceil(Math.random() * 10300 * Math.random() * 50000), price: 1, quality: 1 }
                                        ]
                                    }
                                );
                            }
                        }
                    }
                } catch (e) {
                    console.log("cretio ex", e);
                }
            },
            getEmailCode() {
                // let formData = {};
                // formData["identifier"] = "EMAIL";
                // formData["email"] = this.form.email ? this.form.email : (this.store.customerDetail?.email);
                // this.store.resendOTP(formData, true, this).then(() => {
                this.showEmailCode = true;
                this.$parent.loginTokenType = 1
                // });
            },
            Continue(){
                if(this.form.terms){
                    this.successStep = 2
                    window.open(
                  `${static_vars.domainURL}/dashboard/?token=${this.store.user.access_token}&refresh_token=${this.store.user.refresh_token}`,
                  "_self"
                );
                }
            },
            validEmail() {
                let regexp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                return regexp.test(this.form.email.toLowerCase());
            },
            SubmitData(type){
                load(`${this.static_vars.recaptchaKey}`, { useRecaptchaNet: true }).then((recaptcha) => {
                    recaptcha.execute("login").then((token) => {
                        this.captchaToken = token;
                        if(type == 'Email'){
                            this.resendOTP()
                        }else if(type == 'Phone'){
                            this.resendPhoneOTP()
                        }                      
                        
                    });
                });
            },
            reset() {
                this.form.emailcode = "";
            },
            handleOnComplete(value) {
                this.form.emailcode = value;
            },
            handleOnChange(value) {
                this.form.emailcode = value;
            },
            resendOTP() {
                load(`${this.static_vars.recaptchaKey}`, { useRecaptchaNet: true }).then((recaptcha) => {
                    recaptcha.execute("login").then((token) => {
                        // console.log(token) // Will print the token
                        this.captchaToken = token;
                        if(this.captchaToken){
                            this.reset();
                            let formData = {};
                            formData["identifier"] = "EMAIL";
                            formData["email"] = this.form.email ? this.form.email : (this.store.customerDetail?.email);
                            this.store.resendOTP(formData, true, this);
                        }
                    });
                });
            },
            verifyEmailOTP() {
                load(`${this.static_vars.recaptchaKey}`, { useRecaptchaNet: true }).then((recaptcha) => {
                        recaptcha.execute("login").then((token) => {
                            // console.log(token) // Will print the token
                            this.captchaToken = token;
                            if(this.captchaToken){
                                if (this.form.emailcode) {
                                    this.emailError = false
                                    let formData = {};
                                    formData["code"] = this.form.emailcode;
                                    formData["email"] = this.form.email ? this.form.email : (this.store.customerDetail?.email);
                                    this.store.verifyOTP(formData, true, this, 'web1').then((response) => {
                                        if (response === true) {
                                            // this.successStep = 2;
                                            let data = this.store.customerDetail
                                            data.emailVerified = true
                                            this.store.$patch({customerDetail : data});
                                        }
                                    });
                                }else{
                                    this.emailError = true
                                }
                            }
                        });
                    });
            },
            resendPhoneOTP(){
                load(`${this.static_vars.recaptchaKey}`, { useRecaptchaNet: true }).then((recaptcha) => {
                    recaptcha.execute("login").then((token) => {
                        // console.log(token) // Will print the token
                        this.captchaToken = token;
                        if(this.captchaToken){
                            let formData = {};
                            if(this.captchaToken){
                                formData['captcha'] = {};
                                formData['captcha']['response'] = this.captchaToken
                            }
                            this.store.sendOtpMobile(formData,true).then((response) =>{
                                if(response === 6966){
                                    this.PhoneInstruction = true
                                }else if (response === true) {
                                    this.showSMSCode = true;
                                    this.showOtp = true
                                    this.PhoneInstruction = false
                                    this.getCount()
                                }
                            })
                        }
                    });
                });
            },
            verifySMSOTP(){
                load(`${this.static_vars.recaptchaKey}`, { useRecaptchaNet: true }).then((recaptcha) => {
                    recaptcha.execute("login").then((token) => {
                        // console.log(token) // Will print the token
                        this.captchaToken = token;
                        if(this.form.phonecode && this.captchaToken){
                            this.isPhone = false
                            let formData = {};
                            formData["code"] = this.form.phonecode
                            //formData['email'] = this.store.customerDetail.email
                            if(this.captchaToken){
                                formData['captcha'] = {};
                                formData['captcha']['response'] = this.captchaToken
                            }
                            this.store.verifyMobileOTP(formData,true,this).then((response) =>{
                                if(response === true){
                                    window.gtag("event", "phone_verify");
                                    this.showSMSCode = false;
                                    this.showOtp = false;
                                    this.validPhone = false;
                                    this.PhoneInstruction = false;
                                    this.type = ''
                                    this.form.smscode = ''
                                    let data = this.store.customerDetail
                                    data.phoneVerified = true
                                    this.store.$patch({customerDetail : data});
                                }
                            })
                        }else{
                            this.isPhone = true
                        }
                    });
                });
            },
            getCount(){
                if(this.countTime > 0){
                    var interval = setInterval(()=>{
                        if(this.countTime > 0){
                            this.countTime = this.countTime-1
                        }else{
                            clearInterval(interval);
                            this.showOtp = false
                            this.showSMSCode = true
                            this.countTime = 60
                        }
                    },1000)
                }

            },
            // verifyOTP() {
            //     console.log('verifyOTP')
            //     let formData = {};
            //     formData["code"] = this.form.code;
            //     formData["email"] = this.store.customerDetail.email;
            //     this.store.verifyOTP(formData, true, this).then(() => {
            //         let data = this.store.customerDetail;
            //         data.emailVerified = true;
            //         this.store.$patch({ customerDetail: data });
            //     });
            // },
        },
        // mounted() {
        //     if (Object.keys(this.store.customerDetail).length && this.store.customerDetail?.email) {
        //         this.getEmailCode()
        //     }
        // },
    };
</script>
<style>
    .otp-input {
        width: 50px;
        height: 50px;
        padding: 5px;
        margin: 0 10px;
        font-size: 20px;
        border-radius: 4px;
        border: 1px solid rgb(0 0 0 / 15%);
        text-align: center;
    }

    .pdataEmail.position-relative {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    /* Background colour of an input field with value */
    .otp-input.is-complete {
        background-color: #e4e4e4;
    }

    .otp-input::-webkit-inner-spin-button,
    .otp-input::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0; 
    }
</style>
