<template>
<div>
     <section class="pt40 pb40">
        <div class="container">
            <div class="row">
                <div class="col-md-3 col-6">
                    <div class="growCounter-col">
                        <h2>{{$t('second1.text7')}} <span>{{$t('second1.text8')}}</span></h2>
                    </div>
                </div>
                <div class="col-md-3 col-6">
                    <div class="growCounter-col">
                        <h2>{{$t('second1.text9')}} <span>{{$t('second1.text10')}}</span></h2>
                    </div>
                </div>
                <div class="col-md-3  col-6">
                    <div class="growCounter-col">
                        <h2>{{$t('second1.text11')}} <span>{{$t('second1.text12')}}</span></h2>
                    </div>
                </div>
                <div class="col-md-3 col-6">
                    <div class="growCounter-col">
                        <h2>{{$t('second1.text13')}} <span>{{$t('second1.text14')}}</span></h2>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="pt40 pb80">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="sectionDisc text-center">
                        <h2>{{$t('second2.text1')}} <span>{{$t('second2.text2')}}</span></h2>
                        <p>{{$t('second2.text3')}}</p>
                    </div>
                    <div class="f-flex banner-links mt-4 text-center">
                        <a href="#bannerForm" class="commonBtn linkBtn btn-minWidth mt-0 mb-4"><span>{{$t('second2.text4')}}</span></a>
                    </div>
                </div>
            </div>
            <div class="row fiveColumnWrap justify-content-center">
                <div class="col">
                    <div class="bestAwards">
                        <img src="../../../assets/images/zuluTradeV1images/fifthAward.svg" :alt="$t('second2.text13')" class="img-fluid">
                        <p>{{$t('second2.text13')}}</p>
                    </div>
                </div>
                <div class="col">
                    <div class="bestAwards">
                        <img src="../../../assets/images/zuluTradeV1images/exp-icon3.svg" :alt="$t('second2.text9')" class="img-fluid">
                        <p>{{$t('second2.text10')}}</p>
                    </div>
                </div>
                <div class="col">
                    <div class="bestAwards">
                        <img src="../../../assets/images/zuluTradeV1images/exp-icon4.svg" :alt="$t('second2.text11')" class="img-fluid">
                        <p>{{$t('second2.text12')}}</p>
                    </div>
                </div>
                <div class="col">
                    <div class="bestAwards">
                        <img src="../../../assets/images/zuluTradeV1images/exp-icon1.svg" :alt="$t('second2.text5')" class="img-fluid">
                        <p class="px-md-3">{{$t('second2.text6')}}</p>
                    </div>
                </div>
                <div class="col">
                    <div class="bestAwards">
                        <img src="../../../assets/images/zuluTradeV1images/exp-icon2.svg" :alt="$t('second2.text7')" class="img-fluid">
                        <p>{{$t('second2.text8')}}</p>
                    </div>
                </div>
                
            </div>
        </div>
    </section>
</div>
</template>
<script>
export default {
    name: 'sectionScnd'
}
</script>
