<template>
    <section id="bannerForm" class="py-5 bodyColor">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-7 col-12 mb-4 mb-lg-0">
                    <div class="sectionDisc mobText-center maxCustom">
                        <h1>{{$t('second1.text1')}} <span>{{$t('second1.text2')}}</span> {{$t('second1.text3')}}</h1>
                        <p class="mb-4" v-html="$t('second1.text4')"></p>
                        <!-- <a target="_blank" href="https://www.zulutrade.com/partners/register" class="commonBtn linkBtn btn-minWidth demobtn"> {{$t('third1.text10')}} </a> -->
                    </div>
                    <!-- <div class="f-flex banner-links mt-4 text-center">
                        <a href="#registerForm" class="commonBtn linkBtn btn-minWidth"><span>{{$t('second1.text5')}}</span></a>
                    </div> -->
                  <!-- <div class="row justify-content-center">
                    <div class="col-md-8 col-12">
                        <div class="banner-img">
                        <img src="../../../assets/images/zuluTradeV1images/banner-img.png" :alt="$t('second1.text6')" class="img-fluid">
                    </div>
                    </div>
                  </div> -->
                </div>
                <div class="col-lg-5 col-12">
                    <joinAffiliateForm />
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import joinAffiliateForm from "@/components/joinAffiliateForm.vue";
export default {
  name: "sectionOne",
  components: { joinAffiliateForm },
};
</script>
