<template>
    <section class="why-join-zulutrade pb80">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="section-disc text-center">
                        <h2 id="langAR">{{$t('section3.text1')}}<span>{{$t('section3.text2')}}</span></h2>
                    </div>
                        <div class="row zuluTrade-leader-row">
                            <div class="col-md-4 col-12">
                                <div class="zulutrade-join-col">
                                   <div class="zuluTrade-join-img">
                                    <img class="img-fluid" src="../../assets/images/zuluImages/whyjoin-icon1.svg" :alt="$t('section3.text3')">
                                   </div>
                                    <p>{{$t('section3.text4')}}</p>
                                </div>
                            </div>
                            <div class="col-md-4 col-12">
                                <div class="zulutrade-join-col">
                                    <div class="zuluTrade-join-img">
                                    <img class="img-fluid" src="../../assets/images/zuluImages/whyjoin-icon2.svg" :alt="$t('section3.text5')">
                                    </div>
                                    <p>{{$t('section3.text6')}}</p>
                                </div>
                            </div>
                            <div class="col-md-4 col-12">
                                <div class="zulutrade-join-col">
                                    <div class="zuluTrade-join-img">
                                    <img class="img-fluid" src="../../assets/images/zuluImages/whyjoin-icon3.svg" :alt="$t('section3.text7')">
                                    </div>
                                    <p>{{$t('section3.text8')}}</p>
                                </div>
                            </div>
                            <div class="col-md-4 col-12">
                                <div class="zulutrade-join-col">
                                    <div class="zuluTrade-join-img">
                                    <img class="img-fluid" src="../../assets/images/zuluImages/whyjoin-icon4.svg" :alt="$t('section3.text9')">
                                    </div>
                                    <p>{{$t('section3.text10')}}</p>
                                </div>
                            </div>
                            <div class="col-md-4 col-12">
                                <div class="zulutrade-join-col">
                                    <div class="zuluTrade-join-img">
                                    <img class="img-fluid" src="../../assets/images/zuluImages/whyjoin-icon5.svg" :alt="$t('section3.text11')">
                                    </div>
                                    <p>{{$t('section3.text12')}}</p>
                                </div>
                            </div>
                            <div class="col-md-4 col-12">
                                <div class="zulutrade-join-col">
                                    <div class="zuluTrade-join-img">
                                    <img class="img-fluid" src="../../assets/images/zuluImages/whyjoin-icon6.svg" :alt="$t('section3.text13')">
                                    </div>
                                    <p>{{$t('section3.text14')}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="f-flex banner-links text-center">
                            <a href="#bannerForm" class="commonBtn linkBtn"><span>{{$t('pages.text8')}}</span></a>
                             <!-- <a href="https://www.zulutrade.com/social-feed" target="_blank" class="commonBtn linkBtn"><span>Join our Community</span></a> -->
                        </div>
                   
                </div>
            </div>
        </div>
    </section>
</template>
<script>

</script>
