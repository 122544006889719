<template>
    <section class="pt80 pb80">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="sectionDisc text-center">
                    <h2>{{$t('second4.text1')}} <span>{{$t('second4.text2')}}</span></h2>
                   </div>
                </div>
            </div>
            <div class="row mt-5 pb-4">
                <div class="col-md-4 col-12">
                    <div class="works-benifit-col">
                        <div class="worksBenifit-icon">
                        <img src="../../../assets/images/zuluTradeV1images/worksBenifitsIcon1.png" :alt="$t('second4.text3')" class="img-fluid">
                        </div>
                        <h3>{{$t('second4.text4')}}</h3>
                        <p>{{$t('second4.text5')}} <span class="textPrimary fw-semibold">{{$t('second4.text6')}}</span></p>
                    </div>
                </div>
                <div class="col-md-4 col-12">
                    <div class="works-benifit-col">
                        <div class="worksBenifit-icon">
                        <img src="../../../assets/images/zuluTradeV1images/worksBenifitsIcon2.png" :alt="$t('second4.text7')" class="img-fluid">
                        </div>
                        <h3>{{$t('second4.text8')}}</h3>
                        <p>{{$t('second4.text9')}}</p>
                    </div>
                </div>
                <div class="col-md-4 col-12">
                    <div class="works-benifit-col">
                      <div class="worksBenifit-icon">
                        <img src="../../../assets/images/zuluTradeV1images/worksBenifitsIcon1.png" :alt="$t('second4.text10')" class="img-fluid">
                      </div>
                        <h3>{{$t('second4.text11')}}</h3>
                        <p>{{$t('second4.text12')}}</p>
                    </div>
                </div>
            </div>
            <div class="f-flex banner-links mt-5 text-center">
                <a href="#bannerForm" class="commonBtn linkBtn btn-minWidth demobtn"> {{$t('third1.text10')}} </a>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: 'sectionForth'
}
</script>
