import { createRouter, createWebHistory } from 'vue-router'
// import CopyTrading from '../views/copy-trading.vue'
// import WelcomeA from '../views/welcome-a.vue'
// import WelcomeC from '../views/welcome-c.vue'
import SocialTrading from '../views/leaders.vue'
import zuluLanding from '../views/investors.vue'
import zuluTradeAffiliate from '../views/zulu-affiliate/zuluTrade-affiliate.vue'
import LeadWidget from '../views/widgets/lead-widgets.vue'
import SocialTrading1 from '../views/leaders.vue'
import zuluLanding1 from '../views/investors.vue'
// const cssurl = ""
const routes = [
  { path: '/', redirect: '/leaders' },
  // {
  //   path:  '/welcome1'+cssurl,
  //   name: "welcome b",
  //   component: () => import("@/views/welcome-b.vue"),
  // },
  // {
  //   path: '/how-to-copy-trade'+cssurl,
  //   name: "Copy trading",
  //   component: CopyTrading
  // },
  // {
  //   path: '/social-trading'+cssurl,
  //   name: "welcome a",
  //   component: WelcomeA
  // },
  // {
  //   path: '/Invest-with-the-best'+cssurl,
  //   name: 'Invest with the best', 
  //   component: WelcomeC
  // },
  {
    path: '/leaders',
    name: 'SocialTrading',
    component: SocialTrading
  },
  {
    path: '/investors',
    name: 'zuluLanding',
    component: zuluLanding
  },
  {
    path: '/zuluTrade-affiliate',
    name: 'zuluTradeAffiliate',
    component: zuluTradeAffiliate
  },
  {
    path: '/widget',
    name: 'LeadWidget',
    component: LeadWidget
  },
  {
    path: '/leaders1',
    name: 'SocialTrading1',
    component: SocialTrading
  },
  {
    path: '/investors1',
    name: 'zuluLanding1',
    component: zuluLanding
  },
]
const router = createRouter({
  mode: 'history',
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to) => {
  if(to.name=='LeadWidget'){
    document.querySelector('body').classList.add("hideChatPage");
  }else {
    document.querySelector('body').classList.remove("hideChatPage");
  }
});

export default router
