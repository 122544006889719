<template>
  <NavBar v-if="$route.path == '/lp/social-trading' || $route.path == '/lp/Invest-with-the-best'"></NavBar>
  <!-- <Header v-else></Header> -->
  <router-view/>
  <!-- <FooterBar></FooterBar> -->
</template>
<script>
import NavBar from './components/shared/childheader.vue'
import Header from './components/shared/header.vue'
import FooterBar from './components/shared/footer.vue'
import seoData from "./assets/js/seo.js";
import { myStore } from "@/store/pinia.js";
export default {
  setup() {
    const store = myStore();
    return { store };
  },
  name: 'app',
  watch: {
    $route() {
      if (this.$route.path) {
        let seoJson = {};
        seoJson = seoData;
        let key = this.$route.path.split("/")[1];
        if(key == 'global'){
          key = this.$route.path.split("/")[2];
        }
        let getSeoPath = seoJson[0][key];
        if (key == undefined || getSeoPath == undefined) {
          getSeoPath = seoJson[0].other;
        }
        if (getSeoPath) {
          document.title = getSeoPath.title;
          document
            .querySelector('meta[name="description"]')
            .setAttribute("content", getSeoPath.description);
          document
            .querySelector('meta[name="keywords"]')
            .setAttribute("content", getSeoPath.keywords);
        }
      }
    },
  },
  components: {
      Header,
      NavBar,
      FooterBar,
    },
    created(){
    this.store.affilateLanded({},false);
    this.store.getFlavorID({},false);
    this.store.getCountryList({},false);
  }
}
</script>