export default {
    locales: {
        pt: "Portuguese"
    },
    // leader page
    leader: {
        'text1': `ZuluTrade Logo`,
        'text2': `Inscreva-se agora`,
        'text3': `Inscreva-se`,
        'text4': `Inscreva-se como líder na ZuluTrade.`,
        'text5': `Torne a conta pública e comece a negociar.`,
        'text6': `Os investidores copiam sua estratégia e você recebe seus ganhos.`,
        'text7': `Líder`,
        'text8': `uma`,
        'text9': `Os investidores seguem os líderes depois de analisar sua página de desempenho.`,
        'text10': `Cada vez que uma negociação é feita por um líder, ela também é copiada para a conta do investidor.`,
        'text11': `Negociações lucrativas para o líder também são lucrativas para o investidor.`,
        'text12': `Investidor`,
        'text13': `um`
    },
    header: {
        'text12': `ZuluTrade's`,
    },
    pages: {
        'text1': `Junte-se aos líderes mundiais`,
        'text2': `Negociação Social`,
        'text3': `Comunidade!`,
        'text4': `Ganhe $5-$10 por $100 mil negociados. O custo depende dos pares de moedas negociados, do tipo de conta, etc.`,
        'text5': `Faça parte de uma comunidade global de mais de 2 milhões de usuários e mais de 90 mil líderes na principal plataforma de social trading.`,
        'text6': `Compartilhe suas estratégias, ganhe comissões em negociações copiadas. Una-se à ZuluTrade agora!`,
        'text7': `ZuluTrade Leader`,
        'text8': `Abra uma conta de Líder`,
        'text9': `Saiba mais`
    },
    second3: {
        'text1': `Enjoy Flexible`,
        'text2': `Compensation Schemes`,
        'text3': `Our default monetization model is CPA, but we are open to discussing more options for established affiliates, like hybrid and custom deals if they can deliver large volumes of customers. Our CPA playouts are categorized into Tiers, depending on the Geolocation of the accounts referred by our affiliates.`,
        'text4': `Maximize your earnings with our affiliate program. Earn up to 300$ per investor introduced, and claim even more with our hybrid custom CPA model`,
        'text5': `Become an Affiliate`,
        'text6': `Saiba mais`,
        'text7': `Affiliate Zulu Coin`
    },
    section2: {
        'text1': `Líderes de Melhor Desempenho`,
        'text2': `Estratégias que têm impulso positivo e se aproximam das ATHs (Máximas históricas)`,
        'text3': `Copiadores`,
        'text4': `Seguidores`,
        'text5': `Junte-se aos melhores deles`
    },
    section3: {
        'text1': `Por que entrar para a ZuluTrade como`,
        'text2': `Líder?`,
        'text3': `ZuluTrade licence icon`,
        'text4': `A ZuluTrade é regulamentada pela HCMC na UE, FSA no Japão, FSC na República de Maurício e FSCA na África do Sul.`,
        'text5': `ZuluTrade advanced and feature rich icon`,
        'text6': `Plataforma avançada e rica em recursos para personalizar sua experiência de trading.`,
        'text7': `ZuluTrade support icon`,
        'text8': `Aumente a lucratividade com um balcão de traders exclusivo para atrair e reter investidores.`,
        'text9': `ZuluTrade No hidden costs icon`,
        'text10': `Sem custos ocultos ou limites de pagamento mensal.`,
        'text11': `ZuluTrade multiple brokers icon`,
        'text12': `Plataforma feita para suportar múltiplas corretoras.`,
        'text13': `ZuluTrade network icon`,
        'text14': `Vasta rede de social trading com mais de 90 mil líderes.`
    },
    section4: {
        'text1': `Como`,
        'text2': `ZuluTrade`,
        'text3': `trabalha para`,
        'text4': `Líderes?`,
        'text5': `Join ZuluTrade icon`,
        'text6': `Torne-se um verdadeiro líder na ZuluTrade`,
        'text7': `Junte-se à ZuluTrade como líder, conecte sua conta e compartilhe suas estratégias de trading vencedoras!`,
        'text8': `Rankings icon`,
        'text9': `Destaque-se e tenha sucesso`,
        'text10': `Nosso sistema de classificação considera desempenho, estabilidade, comportamento e perspectivas.`,
        'text11': `Earnings icon`,
        'text12': `Ganhos sem estresse`,
        'text13': `Como líder, você ganha quando os investidores copiam suas negociações, levando em consideração o tamanho do investimento e a tolerância ao risco.`
    },
    section5: {
        'text1': `Líderes da ZuluTrade:`,
        'text2': `O que eles fazem?`,
        'text3': `Um "Líder" é um usuário ZuluTrade, que compartilha e permite que outros usuários copiem suas negociações em suas contas. Cada ação de trading realizada na conta do Líder é enviada a todos os investidores dos líderes na forma de um sinal transmitido.`,
        'text4': `Crie uma comunidade e lidere-os!`,
        'text5': `research icon`,
        'text6': `Pesquise`,
        'text7': `o mercado, notícias e atualizações da empresa regularmente.`,
        'text8': `intract icon`,
        'text9': `Interaja`,
        'text10': `com os investidores e comunique atualizações sobre a estratégia de trading.`,
        'text11': `broadcast icon`,
        'text12': `Transmita`,
        'text13': `sinais de trading para os investidores da ZuluTrade e seja destaque no ranking do site.`
    },
    section6: {
        'text1': `Como ingressar na ZuluTrade como um`,
        'text2': `Líder?`,
        'text3': `Passo 1`,
        'text4': `Inscreva-se como líder na ZuluTrade.`,
        'text5': `Passo 2`,
        'text6': `Torne a conta pública e comece a negociar.`,
        'text7': `Passo 3`,
        'text8': `Os investidores copiam sua estratégia e você recebe seus ganhos.`,
        'text9': `Junte-se a nós na ZuluTrade`,
        'text10': `Preencha automaticamente seus dados pessoais usando`,
        'text11': `Google icon`,
        'text12': `ou preencha manualmente`,
        'text13': `Nome de usuário ou e-mail`,
        'text14': `Digite a senha`,
        'text15': `Criar uma conta`
    },
    second5: {
        'text1': `How to become`,
        'text2': `ZuluTrade Affiliate`,
        'text3': `in 3 easy steps?`,
        'text4': `1`,
        'text5': `Click on 'Become an Affiliate'.`,
        'text6': `2`,
        'text7': `Complete the registration and get your unique affiliate URL.`,
        'text8': `3`,
        'text9': `Invite your contacts using unique affiliate URL  and start earning.`,
        'text10': `One-Time Pin has been sent to your email. Please enter it below.`,
        'text11': `Enter OTP`,
        'text12': `Submit`,
        'text13': `Affiliates Account Registration`,
        'text14': `Enter Email Address`,
        'text15': `Enter Your Password`,
        'text16': `Confirm Password`,
        'text17': `Selecione o país de residência`,
        'text18': `Company Full Name (For Corporate Accounts)`,
        'text19': `Enter Company Full Name`,
        'text20': `Company's Legal Representative`,
        'text21': `Primeiro nome`,
        'text22': `Sobrenome`,
        'text23': `I agree to the`,
        'text24': `Privacy Policy`,
        'text25': `Terms.`,
        'text26': `Register`,
        'text27': `Back to login`,
        'text28': `A negociação de instrumentos financeiros envolve risco significativo de perda. Para isenção de responsabilidade completa`,
        'text29': `Clique aqui.`
    },
    affiliate: {
        'text1': `How to become`,
        'text2': `ZuluTrade Affiliate`,
        'text3': `in 3 easy steps?`,
        'text4': `1`,
        'text5': `Click on 'Become an Affiliate'.`,
        'text6': `2`,
        'text7': `Complete the registration and get your unique affiliate URL.`,
        'text8': `3`,
        'text9': `Invite your contacts using unique affiliate URL and start earning.`,
        'text10': `Verify your Email address`,
        'text11': `Enter the 6-digit verification code that was sent to`,
        'text12': `The code is valid for 10 minutes.`,
        'text13': `Resend Code`,
        'text14': `Submit`,
        'text15': `Affiliates Account Registration`,
        'text16': `Enter Email Address`,
        'text17': `Enter Your Password`,
        'text18': `Confirm Password`,
        'text19': `Selecione o país de residência`,
        'text20': `Company Full Name (For Corporate Accounts)`,
        'text21': `Enter Company Full Name`,
        'text22': `Primeiro nome`,
        'text23': `Sobrenome`,
        'text24': `I agree to the`,
        'text25': `Privacy Policy`,
        'text26': `&`,
        'text27': `Terms.`,
        'text28': `Register`,
        'text29': `Thank`,
        'text30': `you!`,
        'text31': `Your account has created successfully.`,
        'text32': `A negociação de instrumentos financeiros envolve risco significativo de perda. Para isenção de responsabilidade completa`,
        'text33': `Clique aqui.`,
    },
    zulutrade: {
        'text1': `Como ingressar na ZuluTrade como`,
        'text2': `Passo 1`,
        'text3': `Passo 2`,
        'text4': `Passo 3`,
        'text5': `Junte-se a nós na ZuluTrade`,
        'text6': `Preencha automaticamente seus dados pessoais usando`,
        'text7': `ou preencha manualmente`,
        'text8': `E-mail`,
        'text9': `Senha`,
        'text10': `Força da senha`,
        'text11': `1 uppercase character`,
        'text12': `8 or more characters`,
        'text13': `at least 1 number`,
        'text14': `at least 1 letter`,
        'text15': `at least 1 special character`,
        'text16': `Loading...`,
        'text17': `Criar uma conta`,
        'text18': `A negociação de instrumentos financeiros envolve risco significativo de perda. Para isenção de responsabilidade completa`,
        'text19': `Clique aqui.`,
        'text20': `Primeiro nome`,
        'text21': `Número de telefone`,
        'text22': `Sobrenome`,
        'text23': `Selecione o país de residência`,
        'text24': `Procure aqui..`,
        'text25': `O país é obrigatório`,
        'text26': `O número de telefone é obrigatório`,
    },


    // Investor page
    
    banner: {
        'text1': `Investir fácil com`,
        'text2': `CopyTrading e Social Trading!`,
        'text3': `Vai Zulu!`,
        'text4': `A ZuluTrade é uma das principais plataformas de social trading do mundo, com mais de 90 mil líderes profissionais e mais de 2 milhões de usuários ativos na plataforma. Descubra os principais líderes de todo o mundo, copie sua estratégia e replique suas negociações em tempo real para sua conta de investimento.`,
        'text5': `Comece a investir`,
        'text6': `ZuluTrade Banner Image`
    },
    third1: {
        'text1': `Segue o`,
        'text2': `especialistas`,
        'text3': `e bater o`,
        'text4': `chances`,
        'text5': `no mundo do trading com Copy Trading`,
        'text6': `Leve sua jornada de trading para o próximo nível com a ZuluTrade ao seu lado.`,
        'text7': `ZuluTrade Compare Leaders Image`,
        'text8': `Abra uma conta de demonstração e divirta-se aprendendo!`,
        'text9': `DEMONSTRAÇÃO`,
        'text10': `Become an Affiliate`,
    },
    third2: {
        'text1': `Por que`,
        'text2': `ZuluTrade?`,
        'text3': `ZuluTrade transparent copy trading platform icon`,
        'text4': `A plataforma de copy trading mais antiga e transparente.`,
        'text5': `ZuluTrade license icon`,
        'text6': `A ZuluTrade é regulamentada pela HCMC na UE, FSA no Japão, FSC na República de Maurício e FSCA na África do Sul.`,
        'text7': `ZuluTrade investors profit icon`,
        'text8': `Entre em contato com nosso atendimento ao cliente altamente capacitado 24 horas por dia, 5 dias por semana, via chat, telefone ou e-mail!`,
        'text9': `ZuluTrade serves globally icon`,
        'text10': `Atua globalmente em mais de 150 países.`,
        'text11': `Average winning ratio`,
        'text12': `As ferramentas sociais permitem que você interaja com líderes e outros investidores.`,
        'text13': `vast community`,
        'text14': `Uma vasta comunidade de mais de 2 milhões de usuários em todo o mundo.`
    },
    third3: {
        'text1': `O que torna a ZuluTrade`,
        'text2': `exclusiva?`,
        'text3': `ZuluTrade Unique Features Image`,
        'text4': `ZuluGuard`,
        'text5': `TM`,
        'text6': `Uma ferramenta de social trading inteligente e segura para o seu investimento. ZuluGuard protege seu capital de investimento monitorando constantemente sua conta, garantindo lucros e reduzindo perdas potenciais.`,
        'text7': `Múltiplas plataformas`,
        'text8': `Você pode optar por negociar em várias plataformas disponíveis:`,
        'text9': `MT4, MT5, ActTrader, X Open Hub, Match-Trader.`,
        'text10': `Feed social`,
        'text11': `Interaja com outros investidores e líderes em todo o mundo. Comente, avalie, participe da discussão e domine o mercado usando a sabedoria da comunidade social.`,
        'text12': `Inicie o CopyTrading agora`
    },
    third4: {
        'text1': `Como ingressar na ZuluTrade como um`,
        'text2': `Investidora?`,
        'text3': `Passo 1`,
        'text4': `Os investidores seguem os líderes depois de analisar sua página de desempenho.`,
        'text5': `Passo 2`,
        'text6': `Cada vez que uma negociação é feita por um líder, ela também é copiada para a conta do investidor.`,
        'text7': `Passo 3`,
        'text8': `Negociações lucrativas para o líder também são lucrativas para o investidor.`,
        'text9': `Criar uma conta`,
        'text10': `A negociação de instrumentos financeiros envolve risco significativo de perda. Para isenção de responsabilidade completa`,
        'text11': `Clique aqui.`
    }
}