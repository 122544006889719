export default {
	"0": { "name": "Afghanistan", "iso2":"AF", "iso3": "AFG" },
	"1": { "name": "Albania", "iso2":"AL", "iso3": "ALB" },
	"2": { "name": "Algeria", "iso2":"DZ", "iso3": "DZA" },
	"3": { "name": "American Samoa", "iso2":"AS", "iso3": "ASM" },
	"4": { "name": "Andorra", "iso2":"AD", "iso3": "AND" },
	"5": { "name": "Angola", "iso2":"AO", "iso3": "AGO" },
	"6": { "name": "Anguilla", "iso2":"AI", "iso3": "AIA" },
	"7": { "name": "Antarctica", "iso2":"AQ", "iso3": "ATA" },
	"8": { "name": "Antigua and Barbuda", "iso2":"AG", "iso3": "ATG" },
	"9": { "name": "Argentina", "iso2":"AR", "iso3": "ARG" },
	"10": { "name": "Armenia", "iso2":"AM", "iso3": "ARM" },
	"11": { "name": "Aruba", "iso2":"AW", "iso3": "ABW" },
	"12": { "name": "Australia", "iso2":"AU", "iso3": "AUS" },
	"13": { "name": "Austria", "iso2":"AT", "iso3": "AUT" },
	"14": { "name": "Azerbaijan", "iso2":"AZ", "iso3": "AZE" },
	"15": { "name": "Bahamas (the)", "iso2":"BS", "iso3": "BHS" },
	"16": { "name": "Bahrain", "iso2":"BH", "iso3": "BHR" },
	"17": { "name": "Bangladesh", "iso2":"BD", "iso3": "BGD" },
	"18": { "name": "Barbados", "iso2":"BB", "iso3": "BRB" },
	"19": { "name": "Belarus", "iso2":"BY", "iso3": "BLR" },
	"20": { "name": "Belgium", "iso2":"BE", "iso3": "BEL" },
	"21": { "name": "Belize", "iso2":"BZ", "iso3": "BLZ" },
	"22": { "name": "Benin", "iso2":"BJ", "iso3": "BEN" },
	"23": { "name": "Bermuda", "iso2":"BM", "iso3": "BMU" },
	"24": { "name": "Bhutan", "iso2":"BT", "iso3": "BTN" },
	"25": { "name": "Bolivia (Plurinational State of)", "iso2":"BO", "iso3": "BOL" },
	"26": { "name": "Bonaire, Sint Eustatius and Saba", "iso2":"BQ", "iso3": "BES" },
	"27": { "name": "Bosnia and Herzegovina", "iso2":"BA", "iso3": "BIH" },
	"28": { "name": "Botswana", "iso2":"BW", "iso3": "BWA" },
	"29": { "name": "Bouvet Island", "iso2":"BV", "iso3": "BVT" },
	"30": { "name": "Brazil", "iso2":"BR", "iso3": "BRA" },
	"31": { "name": "British Indian Ocean Territory (the)", "iso2":"IO", "iso3": "IOT" },
	"32": { "name": "Brunei Darussalam", "iso2":"BN", "iso3": "BRN" },
	"33": { "name": "Bulgaria", "iso2":"BG", "iso3": "BGR" },
	"34": { "name": "Burkina Faso", "iso2":"BF", "iso3": "BFA" },
	"35": { "name": "Burundi", "iso2":"BI", "iso3": "BDI" },
	"36": { "name": "Cabo Verde", "iso2":"CV", "iso3": "CPV" },
	"37": { "name": "Cambodia", "iso2":"KH", "iso3": "KHM" },
	"38": { "name": "Cameroon", "iso2":"CM", "iso3": "CMR" },
	"39": { "name": "Canada", "iso2":"CA", "iso3": "CAN" },
	"40": { "name": "Cayman Islands (the)", "iso2":"KY", "iso3": "CYM" },
	"41": { "name": "Central African Republic (the)", "iso2":"CF", "iso3": "CAF" },
	"42": { "name": "Chad", "iso2":"TD", "iso3": "TCD" },
	"43": { "name": "Chile", "iso2":"CL", "iso3": "CHL" },
	"44": { "name": "China", "iso2":"CN", "iso3": "CHN" },
	"45": { "name": "Christmas Island", "iso2":"CX", "iso3": "CXR" },
	"46": { "name": "Cocos (Keeling) Islands (the)", "iso2":"CC", "iso3": "CCK" },
	"47": { "name": "Colombia", "iso2":"CO", "iso3": "COL" },
	"48": { "name": "Comoros (the)", "iso2":"KM", "iso3": "COM" },
	"49": { "name": "Congo (the Democratic Republic of the)", "iso2":"CD", "iso3": "COD" },
	"50": { "name": "Congo (the)", "iso2":"CG", "iso3": "COG" },
	"51": { "name": "Cook Islands (the)", "iso2":"CK", "iso3": "COK" },
	"52": { "name": "Costa Rica", "iso2":"CR", "iso3": "CRI" },
	"53": { "name": "Croatia", "iso2":"HR", "iso3": "HRV" },
	"54": { "name": "Cuba", "iso2":"CU", "iso3": "CUB" },
	"55": { "name": "Curaçao", "iso2":"CW", "iso3": "CUW" },
	"56": { "name": "Cyprus", "iso2":"CY", "iso3": "CYP" },
	"57": { "name": "Czechia", "iso2":"CZ", "iso3": "CZE" },
	"58": { "name": "Côte d'Ivoire", "iso2":"CI", "iso3": "CIV" },
	"59": { "name": "Denmark", "iso2":"DK", "iso3": "DNK" },
	"60": { "name": "Djibouti", "iso2":"DJ", "iso3": "DJI" },
	"61": { "name": "Dominica", "iso2":"DM", "iso3": "DMA" },
	"62": { "name": "Dominican Republic (the)", "iso2":"DO", "iso3": "DOM" },
	"63": { "name": "Ecuador", "iso2":"EC", "iso3": "ECU" },
	"64": { "name": "Egypt", "iso2":"EG", "iso3": "EGY" },
	"65": { "name": "El Salvador", "iso2":"SV", "iso3": "SLV" },
	"66": { "name": "Equatorial Guinea", "iso2":"GQ", "iso3": "GNQ" },
	"67": { "name": "Eritrea", "iso2":"ER", "iso3": "ERI" },
	"68": { "name": "Estonia", "iso2":"EE", "iso3": "EST" },
	"69": { "name": "Eswatini", "iso2":"SZ", "iso3": "SWZ" },
	"70": { "name": "Ethiopia", "iso2":"ET", "iso3": "ETH" },
	"71": { "name": "Falkland Islands (the) [Malvinas]", "iso2":"FK", "iso3": "FLK" },
	"72": { "name": "Faroe Islands (the)", "iso2":"FO", "iso3": "FRO" },
	"73": { "name": "Fiji", "iso2":"FJ", "iso3": "FJI" },
	"74": { "name": "Finland", "iso2":"FI", "iso3": "FIN" },
	"75": { "name": "France", "iso2":"FR", "iso3": "FRA" },
	"76": { "name": "French Guiana", "iso2":"GF", "iso3": "GUF" },
	"77": { "name": "French Polynesia", "iso2":"PF", "iso3": "PYF" },
	"78": { "name": "French Southern Territories (the)", "iso2":"TF", "iso3": "ATF" },
	"79": { "name": "Gabon", "iso2":"GA", "iso3": "GAB" },
	"80": { "name": "Gambia (the)", "iso2":"GM", "iso3": "GMB" },
	"81": { "name": "Georgia", "iso2":"GE", "iso3": "GEO" },
	"82": { "name": "Germany", "iso2":"DE", "iso3": "DEU" },
	"83": { "name": "Ghana", "iso2":"GH", "iso3": "GHA" },
	"84": { "name": "Gibraltar", "iso2":"GI", "iso3": "GIB" },
	"85": { "name": "Greece", "iso2":"GR", "iso3": "GRC" },
	"86": { "name": "Greenland", "iso2":"GL", "iso3": "GRL" },
	"87": { "name": "Grenada", "iso2":"GD", "iso3": "GRD" },
	"88": { "name": "Guadeloupe", "iso2":"GP", "iso3": "GLP" },
	"89": { "name": "Guam", "iso2":"GU", "iso3": "GUM" },
	"90": { "name": "Guatemala", "iso2":"GT", "iso3": "GTM" },
	"91": { "name": "Guernsey", "iso2":"GG", "iso3": "GGY" },
	"92": { "name": "Guinea", "iso2":"GN", "iso3": "GIN" },
	"93": { "name": "Guinea-Bissau", "iso2":"GW", "iso3": "GNB" },
	"94": { "name": "Guyana", "iso2":"GY", "iso3": "GUY" },
	"95": { "name": "Haiti", "iso2":"HT", "iso3": "HTI" },
	"96": { "name": "Heard Island and McDonald Islands", "iso2":"HM", "iso3": "HMD" },
	"97": { "name": "Holy See (the)", "iso2":"VA", "iso3": "VAT" },
	"98": { "name": "Honduras", "iso2":"HN", "iso3": "HND" },
	"99": { "name": "Hong Kong", "iso2":"HK", "iso3": "HKG" },
	"100": { "name": "Hungary", "iso2":"HU", "iso3": "HUN" },
	"101": { "name": "Iceland", "iso2":"IS", "iso3": "ISL" },
	"102": { "name": "India", "iso2":"IN", "iso3": "IND" },
	"103": { "name": "Indonesia", "iso2":"ID", "iso3": "IDN" },
	"104": { "name": "Iran (Islamic Republic of)", "iso2":"IR", "iso3": "IRN" },
	"105": { "name": "Iraq", "iso2":"IQ", "iso3": "IRQ" },
	"106": { "name": "Ireland", "iso2":"IE", "iso3": "IRL" },
	"107": { "name": "Isle of Man", "iso2":"IM", "iso3": "IMN" },
	"108": { "name": "Israel", "iso2":"IL", "iso3": "ISR" },
	"109": { "name": "Italy", "iso2":"IT", "iso3": "ITA" },
	"110": { "name": "Jamaica", "iso2":"JM", "iso3": "JAM" },
	"111": { "name": "Japan", "iso2":"JP", "iso3": "JPN" },
	"112": { "name": "Jersey", "iso2":"JE", "iso3": "JEY" },
	"113": { "name": "Jordan", "iso2":"JO", "iso3": "JOR" },
	"114": { "name": "Kazakhstan", "iso2":"KZ", "iso3": "KAZ" },
	"115": { "name": "Kenya", "iso2":"KE", "iso3": "KEN" },
	"116": { "name": "Kiribati", "iso2":"KI", "iso3": "KIR" },
	"117": { "name": "Korea (the Democratic People's Republic of)", "iso2":"KP", "iso3": "PRK" },
	"118": { "name": "Korea (the Republic of)", "iso2":"KR", "iso3": "KOR" },
	"119": { "name": "Kuwait", "iso2":"KW", "iso3": "KWT" },
	"120": { "name": "Kyrgyzstan", "iso2":"KG", "iso3": "KGZ" },
	"121": { "name": "Lao People's Democratic Republic (the)", "iso2":"LA", "iso3": "LAO" },
	"122": { "name": "Latvia", "iso2":"LV", "iso3": "LVA" },
	"123": { "name": "Lebanon", "iso2":"LB", "iso3": "LBN" },
	"124": { "name": "Lesotho", "iso2":"LS", "iso3": "LSO" },
	"125": { "name": "Liberia", "iso2":"LR", "iso3": "LBR" },
	"126": { "name": "Libya", "iso2":"LY", "iso3": "LBY" },
	"127": { "name": "Liechtenstein", "iso2":"LI", "iso3": "LIE" },
	"128": { "name": "Lithuania", "iso2":"LT", "iso3": "LTU" },
	"129": { "name": "Luxembourg", "iso2":"LU", "iso3": "LUX" },
	"130": { "name": "Macao", "iso2":"MO", "iso3": "MAC" },
	"131": { "name": "Madagascar", "iso2":"MG", "iso3": "MDG" },
	"132": { "name": "Malawi", "iso2":"MW", "iso3": "MWI" },
	"133": { "name": "Malaysia", "iso2":"MY", "iso3": "MYS" },
	"134": { "name": "Maldives", "iso2":"MV", "iso3": "MDV" },
	"135": { "name": "Mali", "iso2":"ML", "iso3": "MLI" },
	"136": { "name": "Malta", "iso2":"MT", "iso3": "MLT" },
	"137": { "name": "Marshall Islands (the)", "iso2":"MH", "iso3": "MHL" },
	"138": { "name": "Martinique", "iso2":"MQ", "iso3": "MTQ" },
	"139": { "name": "Mauritania", "iso2":"MR", "iso3": "MRT" },
	"140": { "name": "Mauritius", "iso2":"MU", "iso3": "MUS" },
	"141": { "name": "Mayotte", "iso2":"YT", "iso3": "MYT" },
	"142": { "name": "Mexico", "iso2":"MX", "iso3": "MEX" },
	"143": { "name": "Micronesia (Federated States of)", "iso2":"FM", "iso3": "FSM" },
	"144": { "name": "Moldova (the Republic of)", "iso2":"MD", "iso3": "MDA" },
	"145": { "name": "Monaco", "iso2":"MC", "iso3": "MCO" },
	"146": { "name": "Mongolia", "iso2":"MN", "iso3": "MNG" },
	"147": { "name": "Montenegro", "iso2":"ME", "iso3": "MNE" },
	"148": { "name": "Montserrat", "iso2":"MS", "iso3": "MSR" },
	"149": { "name": "Morocco", "iso2":"MA", "iso3": "MAR" },
	"150": { "name": "Mozambique", "iso2":"MZ", "iso3": "MOZ" },
	"151": { "name": "Myanmar", "iso2":"MM", "iso3": "MMR" },
	"152": { "name": "Namibia", "iso2":"NA", "iso3": "NAM" },
	"153": { "name": "Nauru", "iso2":"NR", "iso3": "NRU" },
	"154": { "name": "Nepal", "iso2":"NP", "iso3": "NPL" },
	"155": { "name": "Netherlands (the)", "iso2":"NL", "iso3": "NLD" },
	"156": { "name": "New Caledonia", "iso2":"NC", "iso3": "NCL" },
	"157": { "name": "New Zealand", "iso2":"NZ", "iso3": "NZL" },
	"158": { "name": "Nicaragua", "iso2":"NI", "iso3": "NIC" },
	"159": { "name": "Niger (the)", "iso2":"NE", "iso3": "NER" },
	"160": { "name": "Nigeria", "iso2":"NG", "iso3": "NGA" },
	"161": { "name": "Niue", "iso2":"NU", "iso3": "NIU" },
	"162": { "name": "Norfolk Island", "iso2":"NF", "iso3": "NFK" },
	"163": { "name": "North Macedonia", "iso2":"MK", "iso3": "MKD" },
	"164": { "name": "Northern Mariana Islands (the)", "iso2":"MP", "iso3": "MNP" },
	"165": { "name": "Norway", "iso2":"NO", "iso3": "NOR" },
	"166": { "name": "Oman", "iso2":"OM", "iso3": "OMN" },
	"167": { "name": "Pakistan", "iso2":"PK", "iso3": "PAK" },
	"168": { "name": "Palau", "iso2":"PW", "iso3": "PLW" },
	"169": { "name": "Palestine, State of", "iso2":"PS", "iso3": "PSE" },
	"170": { "name": "Panama", "iso2":"PA", "iso3": "PAN" },
	"171": { "name": "Papua New Guinea", "iso2":"PG", "iso3": "PNG" },
	"172": { "name": "Paraguay", "iso2":"PY", "iso3": "PRY" },
	"173": { "name": "Peru", "iso2":"PE", "iso3": "PER" },
	"174": { "name": "Philippines (the)", "iso2":"PH", "iso3": "PHL" },
	"175": { "name": "Pitcairn", "iso2":"PN", "iso3": "PCN" },
	"176": { "name": "Poland", "iso2":"PL", "iso3": "POL" },
	"177": { "name": "Portugal", "iso2":"PT", "iso3": "PRT" },
	"178": { "name": "Puerto Rico", "iso2":"PR", "iso3": "PRI" },
	"179": { "name": "Qatar", "iso2":"QA", "iso3": "QAT" },
	"180": { "name": "Romania", "iso2":"RO", "iso3": "ROU" },
	"181": { "name": "Russian Federation (the)", "iso2":"RU", "iso3": "RUS" },
	"182": { "name": "Rwanda", "iso2":"RW", "iso3": "RWA" },
	"183": { "name": "Réunion", "iso2":"RE", "iso3": "REU" },
	"184": { "name": "Saint Barthélemy", "iso2":"BL", "iso3": "BLM" },
	"185": { "name": "Saint Helena, Ascension and Tristan da Cunha", "iso2":"SH", "iso3": "SHN" },
	"186": { "name": "Saint Kitts and Nevis", "iso2":"KN", "iso3": "KNA" },
	"187": { "name": "Saint Lucia", "iso2":"LC", "iso3": "LCA" },
	"188": { "name": "Saint Martin (French part)", "iso2":"MF", "iso3": "MAF" },
	"189": { "name": "Saint Pierre and Miquelon", "iso2":"PM", "iso3": "SPM" },
	"190": { "name": "Saint Vincent and the Grenadines", "iso2":"VC", "iso3": "VCT" },
	"191": { "name": "Samoa", "iso2":"WS", "iso3": "WSM" },
	"192": { "name": "San Marino", "iso2":"SM", "iso3": "SMR" },
	"193": { "name": "Sao Tome and Principe", "iso2":"ST", "iso3": "STP" },
	"194": { "name": "Saudi Arabia", "iso2":"SA", "iso3": "SAU" },
	"195": { "name": "Senegal", "iso2":"SN", "iso3": "SEN" },
	"196": { "name": "Serbia", "iso2":"RS", "iso3": "SRB" },
	"197": { "name": "Seychelles", "iso2":"SC", "iso3": "SYC" },
	"198": { "name": "Sierra Leone", "iso2":"SL", "iso3": "SLE" },
	"199": { "name": "Singapore", "iso2":"SG", "iso3": "SGP" },
	"200": { "name": "Sint Maarten (Dutch part)", "iso2":"SX", "iso3": "SXM" },
	"201": { "name": "Slovakia", "iso2":"SK", "iso3": "SVK" },
	"202": { "name": "Slovenia", "iso2":"SI", "iso3": "SVN" },
	"203": { "name": "Solomon Islands", "iso2":"SB", "iso3": "SLB" },
	"204": { "name": "Somalia", "iso2":"SO", "iso3": "SOM" },
	"205": { "name": "South Africa", "iso2":"ZA", "iso3": "ZAF" },
	"206": { "name": "South Georgia and the South Sandwich Islands", "iso2":"GS", "iso3": "SGS" },
	"207": { "name": "South Sudan", "iso2":"SS", "iso3": "SSD" },
	"208": { "name": "Spain", "iso2":"ES", "iso3": "ESP" },
	"209": { "name": "Sri Lanka", "iso2":"LK", "iso3": "LKA" },
	"210": { "name": "Sudan (the)", "iso2":"SD", "iso3": "SDN" },
	"211": { "name": "Suriname", "iso2":"SR", "iso3": "SUR" },
	"212": { "name": "Svalbard and Jan Mayen", "iso2":"SJ", "iso3": "SJM" },
	"213": { "name": "Sweden", "iso2":"SE", "iso3": "SWE" },
	"214": { "name": "Switzerland", "iso2":"CH", "iso3": "CHE" },
	"215": { "name": "Syrian Arab Republic (the)", "iso2":"SY", "iso3": "SYR" },
	"216": { "name": "Taiwan (Province of China)", "iso2":"TW", "iso3": "TWN" },
	"217": { "name": "Tajikistan", "iso2":"TJ", "iso3": "TJK" },
	"218": { "name": "Tanzania, the United Republic of", "iso2":"TZ", "iso3": "TZA" },
	"219": { "name": "Thailand", "iso2":"TH", "iso3": "THA" },
	"220": { "name": "Timor-Leste", "iso2":"TL", "iso3": "TLS" },
	"221": { "name": "Togo", "iso2":"TG", "iso3": "TGO" },
	"222": { "name": "Tokelau", "iso2":"TK", "iso3": "TKL" },
	"223": { "name": "Tonga", "iso2":"TO", "iso3": "TON" },
	"224": { "name": "Trinidad and Tobago", "iso2":"TT", "iso3": "TTO" },
	"225": { "name": "Tunisia", "iso2":"TN", "iso3": "TUN" },
	"226": { "name": "Turkey", "iso2":"TR", "iso3": "TUR" },
	"227": { "name": "Turkmenistan", "iso2":"TM", "iso3": "TKM" },
	"228": { "name": "Turks and Caicos Islands (the)", "iso2":"TC", "iso3": "TCA" },
	"229": { "name": "Tuvalu", "iso2":"TV", "iso3": "TUV" },
	"230": { "name": "Uganda", "iso2":"UG", "iso3": "UGA" },
	"231": { "name": "Ukraine", "iso2":"UA", "iso3": "UKR" },
	"232": { "name": "United Arab Emirates (the)", "iso2":"AE", "iso3": "ARE" },
	"233": { "name": "United Kingdom of Great Britain and Northern Ireland (the)", "iso2":"GB", "iso3": "GBR" },
	"234": { "name": "United States Minor Outlying Islands (the)", "iso2":"UM", "iso3": "UMI" },
	"235": { "name": "United States of America (the)", "iso2":"US", "iso3": "USA" },
	"236": { "name": "Uruguay", "iso2":"UY", "iso3": "URY" },
	"237": { "name": "Uzbekistan", "iso2":"UZ", "iso3": "UZB" },
	"238": { "name": "Vanuatu", "iso2":"VU", "iso3": "VUT" },
	"239": { "name": "Venezuela (Bolivarian Republic of)", "iso2":"VE", "iso3": "VEN" },
	"240": { "name": "Viet Nam", "iso2":"VN", "iso3": "VNM" },
	"241": { "name": "Virgin Islands (British)", "iso2":"VG", "iso3": "VGB" },
	"242": { "name": "Virgin Islands (U.S.)", "iso2":"VI", "iso3": "VIR" },
	"243": { "name": "Wallis and Futuna", "iso2":"WF", "iso3": "WLF" },
	"244": { "name": "Western Sahara*", "iso2":"EH", "iso3": "ESH" },
	"245": { "name": "Yemen", "iso2":"YE", "iso3": "YEM" },
	"246": { "name": "Zambia", "iso2":"ZM", "iso3": "ZMB" },
	"247": { "name": "Zimbabwe", "iso2":"ZW", "iso3": "ZWE" },
	"248": { "name": "Åland Islands", "iso2":"AX", "iso3": "ALA" }         
}