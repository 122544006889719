<template>
    <section class="enjoy-flexible pt80 pb80">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-6 col-12">
                    <div class="sectionDisc">
                    <h2>{{$t('second3.text1')}} <br><span>{{$t('second3.text2')}}</span></h2>
                    <p class="darkText">{{$t('second3.text3')}}</p>
                    <p class="darkText">{{$t('second3.text4')}}</p>
                    <div class="f-flex banner-links mt-5">
                        <a href="#bannerForm" class="commonBtn linkBtn btn-minWidth me-2"><span>{{$t('second3.text5')}}</span></a>
                        <a :href="`${static_vars.domainURL}/affiliate-guide/`" class="commonBtn linkBtn btn-minWidth" target="_blank"><span>{{$t('second3.text6')}}</span></a>
                    </div>
                    </div>
                </div>
                <div class="col-md-6 col-12">
                    <div class="dolorIcon">
                        <div class="dolorIconBgLayer">
                            <img src="../../../assets/images/zuluTradeV1images/affilateDolorIcon.png" :alt="$t('second3.text1')" class="img-fluid">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: 'sectionThird'
}
</script>
