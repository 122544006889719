export default {
    locales: {
        en: "English"
    },

    // leader and investor page
    header: {
        'text1': `Logo`,
        'text2': `ZuluTrade`,
        'text3': `Invest`,
        'text4': `Icon`,
        'text5': `CopyTrading`,
        'text6': `Copy Trading`,
        'text7': `New to trading? Start investing by copying successful`,
        'text8': `trading strategies`,
        'text9': `Traders`,
        'text10': `Combos`,
        'text11': `Features`,
        'text12': `ZuluTrade's`,
        'text13': `Benefit from ZuluTrade's unique features.`,
        'text14': `ZuluGuard™`,
        'text15': `Automator`,
        'text16': `Markets`,
        'text17': `Instruments`,
        'text18': `trading instruments`,
        'text19': `Learn more about our available trading instruments.`,
        'text20': `Forex`,
        'text21': `Stocks`,
        'text22': `Crypto CFD`,
        'text23': `Commodities`,
        'text24': `Indices`,
        'text25': `Tools`,
        'text26': `tools to help`,
        'text27': `Make use of these tools to help with your investments.`,
        'text28': `Live Prices`,
        'text29': `Charts`,
        'text30': `Zuluscripts`,
        'text31': `Calculators`,
        'text32': `Calendar`,
        'text33': `Learn`,
        'text34': `Help`,
        'text35': `help centre`,
        'text36': `Find out more from our help centre or watch our tutorials to get you started.`,
        'text37': `Help Center`,
        'text38': `Tutorials`,
        'text39': `Blog`,
        'text40': `Check out the latest news`,
        'text41': `Check out the latest news from the markets and expand your knowledge from our blogs.`,
        'text42': `Market News`,
        'text43': `Knowledge Crunch`,
        'text44': `New Features`,
        'text45': `Trading Floor`,
        'text46': `Forum`,
        'text47': `Investors and Traders`,
        'text48': `Join our community, start a conversation and learn from fellow Investors and Traders!`,
        'text49': `Beginners Talk`,
        'text50': `Traders Performance`,
        'text51': `Trading Discussions`,
        'text52': `About`,
        'text53': `About Us`,
        'text54': `how you can join us`,
        'text55': `Have a look at what we're about and how you can join us.`,
        'text56': `Company`,
        'text57': `Careers`,
        'text58': `Partnerships`,
        'text59': `Partnerships with us`,
        'text60': `Affiliate Program`,
        'text61': `Trader Program`,
        'text62': `Broker Partnerships`,
        'text63': `Feed`,
        'text64': `Flag`,
        'text65': `English`,
        'text66': `Language`,
        'text67': `русский`,
        'text68': `日本語`,
        'text69': `Deutsch`,
        'text70': `中文`,
        'text71': `Español`,
        'text72': `Français`,
        'text73': `Italiano`,
        'text74': `Polski`,
        'text75': `العربية`,
        'text76': `български`,
        'text77': `čeština`,
        'text78': `Dansk`,
        'text79': `Ελληνικά`,
        'text80': `Suomi`,
        'text81': `עברית`,
        'text82': `Magyar`,
        'text83': `한국어`,
        'text84': `Bahasa melayu`,
        'text85': `Nederlands`,
        'text86': `Norsk`,
        'text87': `Português`,
        'text88': `Slovenski`,
        'text89': `Svenska`,
        'text90': `ไทย`,
        'text91': `Tiếng việt`,
        'text92': `Login`,
        'text93': `Register`
    },
    leader: {
        'text1': `ZuluTrade Logo`,
        'text2': `Sign Up Now`,
        'text3': `Sign Up`,
        'text4': `Sign up as a leader at ZuluTrade.`,
        'text5': `Make account public & start trading.`,
        'text6': `Investors copy your strategy and you receive your earnings.`,
        'text7': `Leader`,
        'text8': `a`,
        'text9': `Investors follow leaders after analysing their performance page.`,
        'text10': `Each time a trade is made by a leader, it is copied to the investors account as well.`,
        'text11': `Trades that are profitable for the leader are also profitable for the investor.`,
        'text12': `Investor`,
        'text13': `an`
    },
    pages: {
        'text1': `Join the World's Leading`,
        'text2': `Social Trading`,
        'text3': `Community!`,
        'text4': `Earn $5-$10 per $100K traded. The cost depends on the currency pairs traded, type of account etc.`,
        'text5': `Be part of a global community of 2 million+ users and 90k+ leaders on the top social trading platform.`,
        'text6': `Share your strategies, earn commissions on copied trades. Join ZuluTrade now!`,
        'text7': `ZuluTrade Leader`,
        'text8': `Open Leader Account`,
        'text9': `Learn More`
    },
    section2: {
        'text1': `Top Performing Leaders`,
        'text2': `Strategies that have positive momentum and move near ATHs (All Time Highs)`,
        'text3': `Copiers`,
        'text4': `Followers`,
        'text5': `Join the Best of them`
    },
    section3: {
        'text1': `Why Join ZuluTrade as a `,
        'text2': `Leader?`,
        'text3': `ZuluTrade licence icon`,
        'text4': `ZuluTrade is regulated by HCMC in EU, FSA in Japan, FSC in Mauritius and FSCA in South Africa.`,
        'text5': `ZuluTrade advanced and feature rich icon`,
        'text6': `Advanced and feature-rich platform to customize your trading experience.`,
        'text7': `ZuluTrade support icon`,
        'text8': `Enhance profitability with a dedicated traders desk to attract and retain investors.`,
        'text9': `ZuluTrade No hidden costs icon`,
        'text10': `No hidden costs or monthly payout limits.`,
        'text11': `ZuluTrade multiple brokers icon`,
        'text12': `Platform made to support multiple brokers.`,
        'text13': `ZuluTrade network icon`,
        'text14': `Vast social trading network with 90k+ Leaders.`
    },
    section4: {
        'text1': `How`,
        'text2': `ZuluTrade`,
        'text3': `works for`,
        'text4': `Leaders?`,
        'text5': `Join ZuluTrade icon`,
        'text6': `Become a true Leader on ZuluTrade`,
        'text7': `Join ZuluTrade as a leader, connect your account, and share your winning trading strategies!`,
        'text8': `Rankings icon`,
        'text9': `Stand out and Succeed`,
        'text10': `Our ranking system considers performance, stability, behavior and outlook.`,
        'text11': `Earnings icon`,
        'text12': `Stress-free Earnings`,
        'text13': `As a leader, you earn when investors copy your trades, taking into account their investment size and risk tolerance.`
    },
    section5: {
        'text1': `ZuluTrade Leaders:`,
        'text2': `What they do?`,
        'text3': `A ‘Leader’ is a ZuluTrade user, who shares and allows other users to copy his trades into their account. Each trading action performed in the Leader’s account is then sent to all the Leaders’ Investors in the form of a broadcasted signal.`,
        'text4': `Create a community and lead them!`,
        'text5': `research icon`,
        'text6': `Research`,
        'text7': `the market, news and company updates regularly.`,
        'text8': `intract icon`,
        'text9': `Interact`,
        'text10': `with Investors and communicate updates regarding trading strategy.`,
        'text11': `broadcast icon`,
        'text12': `Broadcast`,
        'text13': `trading signals to the ZuluTrade Investors and get featured on the website rankings.`
    },
    section6: {
        'text1': `How to join ZuluTrade as a`,
        'text2': `Leader?`,
        'text3': `Step 1`,
        'text4': `Sign up as a leader at ZuluTrade.`,
        'text5': `Step 2`,
        'text6': `Make account public & start trading.`,
        'text7': `Step 3`,
        'text8': `Investors copy your strategy and you receive your earnings.`,
        'text9': `Join us at ZuluTrade`,
        'text10': `Auto-fill your personal details using`,
        'text11': `Google icon`,
        'text12': `or fill manually`,
        'text13': `Username or email`,
        'text14': `Enter Password`,
        'text15': `Create An Account`
    },
    second1: {
        'text1': `Partner and Grow with one of the Leading`,
        'text2': `Social Trading Platforms`,
        'text3': `in the World.`,
        'text4': `Get compensated on every successful registration! We have a unique, competitive <br>and transparent compensation model for our Affiliates.`,
        'text5': `Become an Affiliate`,
        'text6': `Partner Banner`,
        'text7': `2M+`,
        'text8': `Users`,
        'text9': `90K+`,
        'text10': `Leaders`,
        'text11': `$2T+`,
        'text12': `Volume`,
        'text13': `150+`,
        'text14': `Countries`
    },
    second2: {
        'text1': `Potential benefit is high when you promote an`,
        'text2': `Award-Winning Platform!`,
        'text3': `Get a slice of the success while making a difference.`,
        'text4': `Join us NOW`,
        'text5': `ZuluTrade UF Awards Cyprus`,
        'text6': `Best Social Trading Solution`,
        'text7': `ZuluTrade Forex Expo Dubai Award`,
        'text8': `Best Social Wealth Management Platform`,
        'text9': `ZuluTrade UF Awards Dubai`,
        'text10': `Best Social Trading Solution in MEA`,
        'text13': `Best Social Trading Solution in APAC`,
        'text11': `ZuluTrade UF Awards Dubai`,
        'text12': `Best Wealth Management Platform Africa`,
    },
    second3: {
        'text1': `Enjoy Flexible`,
        'text2': `Compensation Schemes`,
        'text3': `Our default monetization model is CPA, but we are open to discussing more options for established affiliates, like hybrid and custom deals if they can deliver large volumes of customers. Our CPA playouts are categorized into Tiers, depending on the Geolocation of the accounts referred by our affiliates.`,
        'text4': `Maximize your earnings with our affiliate program. Earn up to 300$ per investor introduced, and claim even more with our hybrid custom CPA model`,
        'text5': `Become an Affiliate`,
        'text6': `Learn More`,
        'text7': `Affiliate Zulu Coin`
    },
    second4: {
        'text1': `How does it`,
        'text2': `work?`,
        'text3': `ZuluTrade Affiliate Account icon`,
        'text4': `1. Create Affiliate Account`,
        'text5': `Fill in your details and get your account enabled immediately. Join us.`,
        'text6': `Fast, Easy, Free!`,
        'text7': `ZuluTrade Promotional Content icon`,
        'text8': `2. Choose Promotional Content`,
        'text9': `Choose the links, banners and landing pages to promote ZuluTrade - the leading Social Trading Platform in the world.`,
        'text10': `ZuluTrade Earn icon`,
        'text11': `3. Get Compensated`,
        'text12': `Enjoy our transparent Geo-based CPA model and monetize your global based traffic and network.`,
        'text13': `Start Earning Now`
    },
    second5: {
        'text1': `How to become`,
        'text2': `ZuluTrade Affiliate`,
        'text3': `in 3 easy steps?`,
        'text4': `1`,
        'text5': `Click on 'Become an Affiliate'.`,
        'text6': `2`,
        'text7': `Complete the registration and get your unique affiliate URL.`,
        'text8': `3`,
        'text9': `Invite your contacts using unique affiliate URL  and start earning.`,
        'text10': `One-Time Pin has been sent to your email. Please enter it below.`,
        'text11': `Enter OTP`,
        'text12': `Submit`,
        'text13': `Affiliates Account Registration`,
        'text14': `Enter Email Address`,
        'text15': `Enter Your Password`,
        'text16': `Confirm Password`,
        'text17': `Select Residence Country`,
        'text18': `Company Full Name (For Corporate Accounts)`,
        'text19': `Enter Company Full Name`,
        'text20': `Company's Legal Representative`,
        'text21': `First Name`,
        'text22': `Last Name`,
        'text23': `I agree to the`,
        'text24': `Privacy Policy`,
        'text25': `Terms.`,
        'text26': `Register`,
        'text27': `Back to login`,
        'text28': `Trading in financial instruments involves significant risk of loss. For full disclaimer`,
        'text29': `click here.`
    },
    welcome: {
        'text1': `Invest with the best!`,
        'text2': `Discover the top Traders from all over the world, copy their strategy and receive their trades in real time in your investment account.`,
        'text3': `Register Now`,
        'text4': `How it works`,
        'text5': `Traders connect their account with ZuluTrade, show their results and compete in Performance page`,
        'text6': `Investors can copy any strategy, apply their own <br>rules, or trade manually`,
        'text7': `Traders get compensated when Investors`,
        'text8': `copy them!`,
        'text9': `Why join ZuluTrade?`,
        'text10': `Discover the next big trading talent, in the largest community of traders in the world!`,
        'text11': `Comment, rate, participate with ZuluTrade's social features and tame the market using the wisdom of the crowds!`,
        'text12': `Stay in control - ZuluTrade is the most advanced and feature-rich platform to customize your experience`,
        'text13': `Doesn't matter if you are newbie; learn the basics the easy way - maybe you will become #1 Trader one day!`,
        'text14': `We are truly customer-oriented: our ace Customer Support is always here to help`,
        'text15': `ZuluTrade is established globally, used by millions of users and is the only regulated service in EU &amp; Japan!`,
        'text16': `Join us at ZuluTrade`,
        'text17': `Create a real money account Or practise with a Demo`,
        'text18': `Trading spot currencies involves substantial risk and there is always the potential for loss.`,
        'text19': `Please click`,
        'text20': `here`,
        'text21': `to see full disclaimer.`
    },
    welcomeb: {
        'text1': `Discover the top Traders from all over the world, copy their strategy and`,
        'text2': `receive their trades in real time in your investment account.`,
        'text3': `OPEN REAL ACCOUNT`,
        'text4': `OPEN DEMO ACCOUNT`,
        'text5': `How does it work?`,
        'text6': `Traders connect their account with ZuluTrade, show their results and compete in Performance page`,
        'text7': `Investors can copy any strategy, apply their own rules, or trade manually`,
        'text8': `Traders get compensated when Investors copy them!`,
        'text9': `Discover the next big trading talent, in the largest community of traders in the world!`,
        'text10': `Comment, rate, participate with ZuluTrade's social features and tame the market using the wisdom of the crowds!`,
        'text11': `Stay in control - ZuluTrade is the most advanced and feature-rich platform to customize your experience`,
        'text12': `Doesn't matter if you are newbie; learn the basics the easy way - maybe you will become #1 Trader one day!`,
        'text13': `We are truly customer-oriented: our ace Customer Support is always here to help`,
        'text14': `ZuluTrade is established globally, used by millions of users and is the only regulated service in EU &amp; Japan!`
    },
    footer: {
        'text1': `Register`,
        'text2': `Supported Brokers`,
        'text3': `Blog`,
        'text4': `Forum`,
        'text5': `Help Center`,
        'text6': `Trading Hours`,
        'text7': `Video Tutorials`,
        'text8': `Trading Guides`,
        'text9': `Investors' Guide`,
        'text10': `Send Feedback`,
        'text11': `About Us`,
        'text12': `Careers`,
        'text13': `Privacy Policy`,
        'text14': `Risk Disclaimer`,
        'text15': `Terms Of Service`,
        'text16': `Contact Us`,
        'text17': `Platforms`,
        'text18': `Sitemap`,
        'text19': `Affiliate Program`,
        'text20': `Affiliates Program Guide`,
        'text21': `Trader Program`,
        'text22': `Trader Guide`,
        'text23': `Partnership Program`,
        'text24': `Combos`,
        'text25': `Cryptos`,
        'text26': `UserLists`,
        'text27': `TradeWall`,
        'text28': `Calendar`,
        'text29': `Forex Tools`,
        'text30': `Automator`,
        'text31': `ZuluGuard™`,
        'text32': `For Mac`,
        'text33': `Mac App Store`,
        'text34': `For Android`,
        'text35': `Play Store Android`,
        'text36': `Follow us on`,
        'text37': `© Copyright 2022 ZuluTrade`,
        'text38': `Trading spot currencies involves substantial risk and there is always the potential for
	loss. Your trading results may vary. Because the risk factor is high in the foreign exchange
	market trading, only genuine "risk"
	funds should be used in such trading. If you do not have the extra capital that you can
	afford to lose, you should not trade in the foreign exchange market. Forex Brokers and
	ZuluTrade are compensated for their
	services through the spread between the bid/ask prices or there may be a cost to initiate a
	trade through the bid/ask spread. Profit sharing accounts are subject to a monthly
	performance fee per selected trading
	system.`,
        'text39': `Forex trading involve a real risk of loss. No "safe" trading system has ever been devised,
	and no one can guarantee profits or freedom from loss. Past performance is not indicative of
	future results.Forex Brokers and ZuluTrade are compensated for their services through the spread between the bid/ask
	prices
	or there may be a cost to initiate a trade through the bid/ask spread. Profit sharing
	accounts are subject to a monthly
	subscription fee and a monthly performance fee per selected trading system.`,
        'text40': `Hypothetical performance results have many inherent limitations, some of which are described
	below. No representation is being made that any account will or is likely to achieve profits
	or losses similar to those
	shown. In fact, there are frequently sharp differences between hypothetical performance
	results and the actual results subsequently achieved by any particular trading program.`,
        'text41': `One of the limitations of hypothetical performance results is that they are generally
	prepared with the benefit of hindsight. In addition, hypothetical trading does not involve
	financial risk, and no hypothetical
	trading record can completely account for the impact of financial risk in actual trading.
	For example, the ability to withstand losses or to adhere to a particular trading program in
	spite of trading losses are
	material points which can also adversely affect actual trading results. There are numerous
	other factors related to the markets in general or to the implementation of any specific
	trading program which cannot be
	fully accounted for in the preparation of hypothetical performance results and all of which
	can adversely affect actual trading results. Please check our`,
        'text42': `full disclaimer`,
        'text43': `HELP`,
        'text44': `INFO`,
        'text45': `PROGRAMS`,
        'text46': `FEATURES`
    },
    otp: {
        'text1': `Enter your email address`,
        'text2': `Email Address`,
        'text3': `Loading...`,
        'text4': `Verify your email address`,
        'text5': `Enter the 6-digit verification code that was sent to`,
        'text6': `The code is valid for 10 minutes`,
        'text7': `I have read and accept the`,
        'text8': `Terms of use`,
        'text9': `Privacy Policy`,
        'text10': `Conflict of Interest Policy`,
        'text11': `Declaration of Consent`,
        'text12': `and`,
        'text13': `Risk Disclaimer`,
        'text14': `Verify Email`
    },
    affiliate: {
        'text1': `How to become`,
        'text2': `ZuluTrade Affiliate`,
        'text3': `in 3 easy steps?`,
        'text4': `1`,
        'text5': `Click on 'Become an Affiliate'.`,
        'text6': `2`,
        'text7': `Complete the registration and get your unique affiliate URL.`,
        'text8': `3`,
        'text9': `Invite your contacts using unique affiliate URL and start earning.`,
        'text10': `Verify your Email address`,
        'text11': `Enter the 6-digit verification code that was sent to`,
        'text12': `The code is valid for 10 minutes.`,
        'text13': `Resend Code`,
        'text14': `Submit`,
        'text15': `Affiliates Account Registration`,
        'text16': `Enter Email Address`,
        'text17': `Enter Your Password`,
        'text18': `Confirm Password`,
        'text19': `Select Residence Country`,
        'text20': `Company Full Name (For Corporate Accounts)`,
        'text21': `Enter Company Full Name`,
        'text22': `First Name`,
        'text23': `Last Name`,
        'text24': `I agree to the`,
        'text25': `Privacy Policy`,
        'text26': `&`,
        'text27': `Terms.`,
        'text28': `Register`,
        'text29': `Thank`,
        'text30': `you!`,
        'text31': `Your account has created successfully.`,
        'text32': `Trading in financial instruments involves significant risk of loss. For full disclaimer`,
        'text33': `click here.`
    },
    zulutrade: {
        'text1': `How to join ZuluTrade as`,
        'text2': `Step 1`,
        'text3': `Step 2`,
        'text4': `Step 3`,
        'text5': `Join us at ZuluTrade`,
        'text6': `Auto-fill your personal details using`,
        'text7': `or fill manually`,
        'text8': `Email`,
        'text9': `Password`,
        'text10': `Password strength`,
        'text11': `1 uppercase character`,
        'text12': `8 or more characters`,
        'text13': `at least 1 number`,
        'text14': `at least 1 letter`,
        'text15': `at least 1 special character`,
        'text16': `Loading...`,
        'text17': `Create Account`,
        'text18': `Trading in financial instruments involves significant risk of loss. For full disclaimer`,
        'text19': `click here.`,
        'text20': `First Name`,
        'text21': `Phone Number`,
        'text22': `Last Name`,
        'text23': `Select Residence Country`,
        'text24': `Search here..`,
        'text25': `Country is required`,
        'text26': `Phone number is required`,
    },
    banner: {
        'text1': `Investing made easy with`,
        'text2': `CopyTrading and Social Trading!`,
        'text3': `Go Zulu!`,
        'text4': `ZuluTrade is one of the world's leading social trading platforms with over 90k+ pro leaders and 2M+ active users on the platform. Discover the top leaders from all over the world, copy their strategy and replicate their trades in real-time to your investment account.`,
        'text5': `Start Investing`,
        'text6': `ZuluTrade Banner Image`
    },
    third1: {
        'text1': `Follow the`,
        'text2': `experts`,
        'text3': `and beat the`,
        'text4': `odds`,
        'text5': `in the world of trading with Copy Trading`,
        'text6': `Take your trading journey to the next level with ZuluTrade by your side.`,
        'text7': `ZuluTrade Compare Leaders Image`,
        'text8': `Open A Demo account and play to learn!`,
        'text9': `DEMO`,
        'text10': `Become an Affiliate`,
    },
    third2: {
        'text1': `Why`,
        'text2': `ZuluTrade?`,
        'text3': `ZuluTrade transparent copy trading platform icon`,
        'text4': `Oldest and most transparent copy trading platform.`,
        'text5': `ZuluTrade license icon`,
        'text6': `ZuluTrade is regulated by HCMC in EU, FSA in Japan, FSC in Mauritius and FSCA in South Africa.`,
        'text7': `ZuluTrade investors profit icon`,
        'text8': `Can reach our highly-rated Customer Service 24/5 via chat, phone or email!`,
        'text9': `ZuluTrade serves globally icon`,
        'text10': `Serves globally in more than 150 countries.`,
        'text11': `Average winning ratio`,
        'text12': `Social tools let you interact with leaders and fellow investors.`,
        'text13': `vast community`,
        'text14': `A vast community of 2M+ users around the globe.`
    },
    third3: {
        'text1': `What makes ZuluTrade`,
        'text2': `unique?`,
        'text3': `ZuluTrade Unique Features Image`,
        'text4': `ZuluGuard`,
        'text5': `TM`,
        'text6': `A smart, secure, social trading tool for your investment. ZuluGuard safeguards your investment capital by constantly monitoring your account, securing profits, and reducing potential losses.`,
        'text7': `Multiple Platforms`,
        'text8': `You can choose to trade from multiple platforms available:`,
        'text9': `MT4, MT5, ActTrader, X Open Hub, Match-Trader.`,
        'text10': `Social Feed`,
        'text11': `Interact with fellow investors and leaders around the world. Comment, rate, participate in the discussion and tame the market using the wisdom of the social-community.`,
        'text12': `Start CopyTrading Now`
    },
    third4: {
        'text1': `How to join ZuluTrade as an`,
        'text2': `Investor?`,
        'text3': `Step 1`,
        'text4': `Investors follow leaders after analysing their performance page.`,
        'text5': `Step 2`,
        'text6': `Each time a trade is made by a leader, it is copied to the investor's account as well.`,
        'text7': `Step 3`,
        'text8': `Trades that are profitable for the leader are also profitable for the investor.`,
        'text9': `Create an Account`,
        'text10': `Trading in financial instruments involves significant risk of loss. For full disclaimer`,
        'text11': `click here.`
    }
}