<template>
  <header class="mainHeader shadow bg-white border-bottom" v-if="header()">
    <nav class="navbar navbar-expand-lg">
      <div class="container-fluid max-1370 p-0">
        <a class="navbar-brand d-flex p-0" :href="`${static_vars.domainURL}`"
          ><img
            src="../../assets/images/logo.svg"
            :alt="$t('header.text1')"
            :title="$t('header.text2')"
        /></a>
        <div class="collapse navbar-collapse" id="mynavbar">
          <ul class="megaMenuList navbar-nav me-auto align-items-center">
            <li class="nav-item">
              <a
                class="nav-link dropdown-toggle"
                role="button"
                data-bs-toggle="dropdown"
                href="javascript:void(0)"
                >{{ $t("header.text3") }}</a
              >
              <div class="dropdown-menu w-100 showMegaMenu">
                <div class="container max-970">
                  <div class="row justify-content-center">
                    <div class="col-12 col-md-5">
                      <div class="d-flex align-items-start horizontralBorder">
                        <span class="icon"
                          ><img
                            src="../../assets/images/copyTrading.svg"
                            :alt="$t('header.text4')"
                            :title="$t('header.text5')"
                        /></span>
                        <div class="flex-none">
                          <h5 class="bold mb-1 secondary">{{ $t("header.text6") }}</h5>
                          <p class="mb-0 medium line-4 f-13 gray">
                            {{ $t("header.text7") }} <br />{{ $t("header.text8") }}
                          </p>
                          <ul class="dropDownMenu mt-3">
                            <li>
                              <a
                                class="px-0 dropdown-item"
                                :href="`${static_vars.domainURL}/traders`"
                                >{{ $t("header.text9") }}
                              </a>
                            </li>
                            <li>
                              <a
                                class="px-0 dropdown-item"
                                :href="`${static_vars.domainURL}/traders/combos`"
                                >{{ $t("header.text10") }}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-5">
                      <div class="d-flex align-items-start">
                        <span class="icon"
                          ><img
                            src="../../assets/images/features.svg"
                            :alt="$t('header.text11')"
                            :title="$t('header.text12')"
                        /></span>
                        <div class="flex-none">
                          <h5 class="bold mb-1 secondary">{{ $t("header.text11") }}</h5>
                          <p class="mb-0 medium f-13 gray">{{ $t("header.text13") }}</p>
                          <ul class="dropDownMenu mt-3">
                            <li>
                              <a
                                class="px-0 dropdown-item"
                                :href="`${static_vars.domainURL}/autoprotect-your-account`"
                              >
                                {{ $t("header.text14") }}
                              </a>
                            </li>
                            <li>
                              <a
                                class="px-0 dropdown-item"
                                :href="`${static_vars.domainURL}/automated-trading`"
                                >{{ $t("header.text15") }}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="nav-item">
              <a
                class="nav-link dropdown-toggle"
                role="button"
                data-bs-toggle="dropdown"
                href="javascript:void(0)"
                >{{ $t("header.text16") }}</a
              >
              <div class="dropdown-menu w-100 showMegaMenu">
                <div class="container max-970">
                  <div class="row justify-content-center">
                    <div class="col-12 col-md-5">
                      <div class="d-flex align-items-start horizontralBorder">
                        <span class="icon"
                          ><img
                            src="../../assets/images/instruments.svg"
                            :alt="$t('header.text17')"
                            :title="$t('header.text18')"
                        /></span>
                        <div class="flex-none">
                          <h5 class="bold mb-1 secondary">{{ $t("header.text17") }}</h5>
                          <p class="mb-0 medium line-4 f-13 gray">
                            {{ $t("header.text19") }}
                          </p>
                          <ul class="dropDownMenu mt-3">
                            <li>
                              <a
                                class="px-0 dropdown-item"
                                :href="`${static_vars.domainURL}/forex-trading`"
                                >{{ $t("header.text20") }}</a
                              >
                            </li>
                            <li>
                              <a
                                class="px-0 dropdown-item"
                                :href="`${static_vars.domainURL}/stock-trading`"
                                >{{ $t("header.text21") }}</a
                              >
                            </li>
                            <li>
                              <a
                                class="px-0 dropdown-item"
                                :href="`${static_vars.domainURL}/cryptocurrency-trading`"
                                >{{ $t("header.text22") }}</a
                              >
                            </li>
                            <li>
                              <a
                                class="px-0 dropdown-item"
                                :href="`${static_vars.domainURL}/commodities-trading`"
                                >{{ $t("header.text23") }}</a
                              >
                            </li>
                            <li>
                              <a
                                class="px-0 dropdown-item"
                                :href="`${static_vars.domainURL}/index-trading`"
                                >{{ $t("header.text24") }}</a
                              >
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-5">
                      <div class="d-flex align-items-start">
                        <span class="icon"
                          ><img
                            src="../../assets/images/tools.svg"
                            :alt="$t('header.text25')"
                            :title="$t('header.text26')"
                        /></span>
                        <div class="flex-none">
                          <h5 class="bold mb-1 secondary">{{ $t("header.text25") }}</h5>
                          <p class="mb-0 medium f-13 gray">{{ $t("header.text27") }}</p>
                          <ul class="dropDownMenu mt-3">
                            <li>
                              <a
                                class="px-0 dropdown-item"
                                :href="`${static_vars.domainURL}/rates`"
                                >{{ $t("header.text28") }}
                              </a>
                            </li>
                            <li>
                              <a
                                class="px-0 dropdown-item"
                                href="https://fp-aaafx-01.zulutrade.com/#/guest"
                                >{{ $t("header.text29") }}</a
                              >
                            </li>
                            <li>
                              <a
                                class="px-0 dropdown-item"
                                href="https://fp-aaafx-01.zulutrade.com/#/guest"
                                >{{ $t("header.text30") }}</a
                              >
                            </li>
                            <li>
                              <a
                                class="px-0 dropdown-item"
                                :href="`${static_vars.domainURL}/forex-tools`"
                                >{{ $t("header.text31") }}</a
                              >
                            </li>
                            <li>
                              <a
                                class="px-0 dropdown-item"
                                :href="`${static_vars.domainURL}/calendar`"
                                >{{ $t("header.text32") }}</a
                              >
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="nav-item">
              <a
                class="nav-link dropdown-toggle"
                role="button"
                data-bs-toggle="dropdown"
                href="javascript:void(0)"
                >{{ $t("header.text33") }}</a
              >
              <div class="dropdown-menu w-100 showMegaMenu">
                <div class="container max-970">
                  <div class="row justify-content-center">
                    <div class="col-12 col-md-4">
                      <div class="d-flex align-items-start horizontralBorder">
                        <span class="icon"
                          ><img
                            src="../../assets/images/help.svg"
                            :alt="$t('header.text34')"
                            :title="$t('header.text35')"
                        /></span>
                        <div class="flex-none">
                          <h5 class="bold mb-1 secondary">{{ $t("header.text34") }}</h5>
                          <p class="mb-0 medium line-4 f-13 gray">
                            {{ $t("header.text36") }}
                          </p>
                          <ul class="dropDownMenu mt-3">
                            <li>
                              <a
                                class="px-0 dropdown-item"
                                :href="`${static_vars.domainURL}/help-center`"
                                >{{ $t("header.text37") }}</a
                              >
                            </li>
                            <li>
                              <a
                                class="px-0 dropdown-item"
                                :href="`${static_vars.domainURL}/tutorials`"
                                >{{ $t("header.text38") }}</a
                              >
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-4">
                      <div class="d-flex align-items-start horizontralBorder">
                        <span class="icon"
                          ><img
                            src="../../assets/images/blog.svg"
                            :alt="$t('header.text39')"
                            :title="$t('header.text40')"
                        /></span>
                        <div class="flex-none">
                          <h5 class="bold mb-1 secondary">{{ $t("header.text39") }}</h5>
                          <p class="mb-0 medium line-4 f-13 gray">
                            {{ $t("header.text41") }}
                          </p>
                          <ul class="dropDownMenu mt-3">
                            <li>
                              <a
                                class="px-0 dropdown-item"
                                :href="`${static_vars.domainURL}/blog/category/Market-View`"
                                >{{ $t("header.text42") }}</a
                              >
                            </li>
                            <li>
                              <a
                                class="px-0 dropdown-item"
                                :href="`${static_vars.domainURL}/blog/category/Knowledge-Crunchl`"
                                >{{ $t("header.text43") }}</a
                              >
                            </li>
                            <li>
                              <a
                                class="px-0 dropdown-item"
                                :href="`${static_vars.domainURL}/blog/category/New-Features`"
                                >{{ $t("header.text44") }}</a
                              >
                            </li>
                            <li>
                              <a
                                class="px-0 dropdown-item"
                                :href="`${static_vars.domainURL}/blog/category/Trading-Floor`"
                                >{{ $t("header.text45") }}</a
                              >
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-4">
                      <div class="d-flex align-items-start">
                        <span class="icon"
                          ><img
                            src="../../assets/images/forum.svg"
                            :alt="$t('header.text46')"
                            :title="$t('header.text47')"
                        /></span>
                        <div class="flex-none">
                          <h5 class="bold mb-1 secondary">{{ $t("header.text46") }}</h5>
                          <p class="mb-0 medium line-4 f-13 gray">
                            {{ $t("header.text48") }}
                          </p>
                          <ul class="dropDownMenu mt-3">
                            <li>
                              <a
                                class="px-0 dropdown-item"
                                href="https://forum.zulutrade.com/c/beginners-talk"
                                >{{ $t("header.text49") }}</a
                              >
                            </li>
                            <li>
                              <a
                                class="px-0 dropdown-item"
                                href="https://forum.zulutrade.com/c/traders-performance"
                                >{{ $t("header.text50") }}</a
                              >
                            </li>
                            <li>
                              <a
                                class="px-0 dropdown-item"
                                href="https://forum.zulutrade.com/c/trading-discussions"
                                >{{ $t("header.text51") }}</a
                              >
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="javascript:void(0)"
                role="button"
                data-bs-toggle="dropdown"
                >{{ $t("header.text52") }}</a
              >
              <div class="dropdown-menu w-100 showMegaMenu">
                <div class="container max-970">
                  <div class="row justify-content-center">
                    <div class="col-12 col-md-5">
                      <div class="d-flex align-items-start horizontralBorder">
                        <span class="icon"
                          ><img
                            src="../../assets/images/zulu.svg"
                            :alt="$t('header.text53')"
                            :title="$t('header.text54')"
                        /></span>
                        <div class="flex-none">
                          <h5 class="bold mb-1 secondary">{{ $t("header.text53") }}</h5>
                          <p class="mb-0 medium line-4 f-13 gray">
                            {{ $t("header.text55") }}
                          </p>
                          <ul class="dropDownMenu mt-3">
                            <li>
                              <a
                                class="px-0 dropdown-item"
                                :href="`${static_vars.domainURL}/about`"
                              >
                                {{ $t("header.text56") }}
                              </a>
                            </li>
                            <li>
                              <a
                                class="px-0 dropdown-item"
                                :href="`${static_vars.domainURL}/careers`"
                                >{{ $t("header.text57") }}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-5">
                      <div class="d-flex align-items-start">
                        <span class="icon"
                          ><img
                            src="../../assets/images/partnerships.svg"
                            :alt="$t('header.text58')"
                            :title="$t('header.text59')"
                        /></span>
                        <div class="flex-none">
                          <h5 class="bold mb-1 secondary">{{ $t("header.text58") }}</h5>
                          <p class="mb-0 medium f-13 gray">{{ $t("header.text59") }}.</p>
                          <ul class="dropDownMenu mt-3">
                            <li>
                              <a
                                class="dropdown-item px-0"
                                :href="`${static_vars.domainURL}/become-an-affiliate`"
                                >{{ $t("header.text60") }}</a
                              >
                            </li>
                            <li>
                              <a
                                class="dropdown-item px-0"
                                :href="`${static_vars.domainURL}/become-a-trader`"
                                >{{ $t("header.text61") }}</a
                              >
                            </li>
                            <li>
                              <a
                                class="dropdown-item px-0"
                                :href="`${static_vars.domainURL}/partnership-program`"
                                >{{ $t("header.text62") }}</a
                              >
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="nav-item d-flex align-items-center feedLink">
              <a class="nav-link pe-2" :href="`${static_vars.domainURL}/social-feed`"
                >{{ $t("header.text63") }}
              </a>
            </li>
          </ul>
        </div>
        <div class="rightHeader d-flex align-items-center">
          <ul class="belowMenu mb-0 d-flex align-items-center me-2 mt-2">
            <li class="nav-item dropdown selectLanguage">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
              >
                <img
                  src="../../assets/images/country_flag/united-kingdom.webp"
                  :alt="$t('header.text64')"
                  :title="$t('header.text65')"
                />
                <span class="d-lg-none">{{ $t("header.text66") }}</span>
              </a>
              <ul class="dropdown-menu">
                <li>
                  <a class="dropdown-item" href="#"
                    ><img
                      src="../../assets/images/country_flag/united-kingdom.webp"
                      :alt="$t('header.text64')"
                      :title="$t('header.text65')"
                    />
                    {{ $t("header.text65") }}</a
                  >
                </li>
                <li>
                  <a class="dropdown-item" href="#"
                    ><img
                      src="../../assets/images/country_flag/united-kingdom.webp"
                      :alt="$t('header.text64')"
                      :title="$t('header.text65')"
                    />
                    {{ $t("header.text68") }}</a
                  >
                </li>
                <li>
                  <a class="dropdown-item" href="#"
                    ><img
                      src="../../assets/images/country_flag/united-kingdom.webp"
                      :alt="$t('header.text64')"
                      :title="$t('header.text65')"
                    />
                    {{ $t("header.text67") }}</a
                  >
                </li>
                <li>
                  <a class="dropdown-item" href="#"
                    ><img
                      src="../../assets/images/country_flag/united-kingdom.webp"
                      :alt="$t('header.text64')"
                      :title="$t('header.text65')"
                    />
                    {{ $t("header.text69") }}</a
                  >
                </li>

                <li>
                  <a class="dropdown-item" href="#"
                    ><img
                      src="../../assets/images/country_flag/united-kingdom.webp"
                      :alt="$t('header.text64')"
                      :title="$t('header.text65')"
                    />
                    {{ $t("header.text70") }}</a
                  >
                </li>

                <li>
                  <a class="dropdown-item" href="#"
                    ><img
                      src="../../assets/images/country_flag/united-kingdom.webp"
                      :alt="$t('header.text64')"
                      :title="$t('header.text65')"
                    />
                    {{ $t("header.text71") }}</a
                  >
                </li>

                <li>
                  <a class="dropdown-item" href="#"
                    ><img
                      src="../../assets/images/country_flag/united-kingdom.webp"
                      :alt="$t('header.text64')"
                      :title="$t('header.text65')"
                    />
                    {{ $t("header.text72") }}</a
                  >
                </li>

                <li>
                  <a class="dropdown-item" href="#"
                    ><img
                      src="../../assets/images/country_flag/united-kingdom.webp"
                      :alt="$t('header.text64')"
                      :title="$t('header.text65')"
                    />
                    {{ $t("header.text73") }}</a
                  >
                </li>

                <li>
                  <a class="dropdown-item" href="#"
                    ><img
                      src="../../assets/images/country_flag/united-kingdom.webp"
                      :alt="$t('header.text64')"
                      :title="$t('header.text65')"
                    />
                    {{ $t("header.text74") }}</a
                  >
                </li>

                <li>
                  <a class="dropdown-item" href="#"
                    ><img
                      src="../../assets/images/country_flag/united-kingdom.webp"
                      :alt="$t('header.text64')"
                      :title="$t('header.text65')"
                    />
                    {{ $t("header.text75") }}</a
                  >
                </li>

                <li>
                  <a class="dropdown-item" href="#"
                    ><img
                      src="../../assets/images/country_flag/united-kingdom.webp"
                      :alt="$t('header.text64')"
                      :title="$t('header.text65')"
                    />
                    {{ $t("header.text76") }}</a
                  >
                </li>

                <li>
                  <a class="dropdown-item" href="#"
                    ><img
                      src="../../assets/images/country_flag/united-kingdom.webp"
                      :alt="$t('header.text64')"
                      :title="$t('header.text65')"
                    />
                    {{ $t("header.text77") }}</a
                  >
                </li>

                <li>
                  <a class="dropdown-item" href="#"
                    ><img
                      src="../../assets/images/country_flag/united-kingdom.webp"
                      :alt="$t('header.text64')"
                      :title="$t('header.text65')"
                    />
                    {{ $t("header.text78") }}</a
                  >
                </li>

                <li>
                  <a class="dropdown-item" href="#"
                    ><img
                      src="../../assets/images/country_flag/united-kingdom.webp"
                      :alt="$t('header.text64')"
                      :title="$t('header.text65')"
                    />
                    {{ $t("header.text79") }}</a
                  >
                </li>

                <li>
                  <a class="dropdown-item" href="#"
                    ><img
                      src="../../assets/images/country_flag/united-kingdom.webp"
                      :alt="$t('header.text64')"
                      :title="$t('header.text65')"
                    />
                    {{ $t("header.text80") }}</a
                  >
                </li>

                <li>
                  <a class="dropdown-item" href="#"
                    ><img
                      src="../../assets/images/country_flag/united-kingdom.webp"
                      :alt="$t('header.text64')"
                      :title="$t('header.text65')"
                    />
                    {{ $t("header.text81") }}</a
                  >
                </li>

                <li>
                  <a class="dropdown-item" href="#"
                    ><img
                      src="../../assets/images/country_flag/united-kingdom.webp"
                      :alt="$t('header.text64')"
                      :title="$t('header.text65')"
                    />
                    {{ $t("header.text82") }}</a
                  >
                </li>

                <li>
                  <a class="dropdown-item" href="#"
                    ><img
                      src="../../assets/images/country_flag/united-kingdom.webp"
                      :alt="$t('header.text64')"
                      :title="$t('header.text65')"
                    />
                    {{ $t("header.text83") }}</a
                  >
                </li>

                <li>
                  <a class="dropdown-item" href="#"
                    ><img
                      src="../../assets/images/country_flag/united-kingdom.webp"
                      :alt="$t('header.text64')"
                      :title="$t('header.text65')"
                    />
                    {{ $t("header.text84") }}</a
                  >
                </li>

                <li>
                  <a class="dropdown-item" href="#"
                    ><img
                      src="../../assets/images/country_flag/united-kingdom.webp"
                      :alt="$t('header.text64')"
                      :title="$t('header.text65')"
                    />
                    {{ $t("header.text85") }}</a
                  >
                </li>

                <li>
                  <a class="dropdown-item" href="#"
                    ><img
                      src="../../assets/images/country_flag/united-kingdom.webp"
                      :alt="$t('header.text64')"
                      :title="$t('header.text65')"
                    />
                    {{ $t("header.text86") }}</a
                  >
                </li>

                <li>
                  <a class="dropdown-item" href="#"
                    ><img
                      src="../../assets/images/country_flag/united-kingdom.webp"
                      :alt="$t('header.text64')"
                      :title="$t('header.text65')"
                    />
                    {{ $t("header.text87") }}</a
                  >
                </li>

                <li>
                  <a class="dropdown-item" href="#"
                    ><img
                      src="../../assets/images/country_flag/united-kingdom.webp"
                      :alt="$t('header.text64')"
                      :title="$t('header.text65')"
                    />
                    {{ $t("header.text88") }}</a
                  >
                </li>

                <li>
                  <a class="dropdown-item" href="#"
                    ><img
                      src="../../assets/images/country_flag/united-kingdom.webp"
                      :alt="$t('header.text64')"
                      :title="$t('header.text65')"
                    />
                    {{ $t("header.text89") }}</a
                  >
                </li>

                <li>
                  <a class="dropdown-item" href="#"
                    ><img
                      src="../../assets/images/country_flag/united-kingdom.webp"
                      :alt="$t('header.text64')"
                      :title="$t('header.text65')"
                    />
                    {{ $t("header.text90") }}</a
                  >
                </li>

                <li>
                  <a class="dropdown-item" href="#"
                    ><img
                      src="../../assets/images/country_flag/united-kingdom.webp"
                      :alt="$t('header.text64')"
                      :title="$t('header.text65')"
                    />
                    {{ $t("header.text91") }}</a
                  >
                </li>
              </ul>
            </li>
          </ul>
          <ul class="loginRegister mb-0">
            <li>
              <a class="linkBtn me-3" :href="`${static_vars.domainURL}/login`">{{
                $t("header.text92")
              }}</a>
            </li>
            <li>
              <a class="linkBtn" :href="`${static_vars.domainURL}/sign-up-investor`">{{
                $t("header.text93")
              }}</a>
            </li>
          </ul>
        </div>
        <button
          class="navbar-toggler ms-3"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#mynavbar"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'index',
    methods: {
        header(){
            if(this.$route.name=='SocialTrading'){
                return false
            } else if(this.$route.name=='zuluLanding'){
                return false
            }
            else if(this.$route.name=='zuluTradeAffiliate'){
                return false
            } else {
                return true
            }
        }
    },
}
</script>
