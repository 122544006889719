export default {
    locales: {
        es: "Spanish"
    },
    // leader page
    leader: {
        'text1': `ZuluTrade Logo`,
        'text2': `Regístrese`,
        'text3': `Inicie sesión`,
        'text4': `Regístrese como líder en ZuluTrade.`,
        'text5': `Haga pública la cuenta y comience a operar.`,
        'text6': `Los inversores copian tu estrategia y tú recibes tus ganancias.`,
        'text7': `Líder`,
        'text8': `una`,
        'text9': `Los inversores siguen a los líderes después de analizar su página de rendimiento.`,
        'text10': `Cada vez que un líder realiza una operación, también se copia en la cuenta de los inversores.`,
        'text11': `Las operaciones que son rentables para el líder también lo son para el inversor.`,
        'text12': `Inversor`,
        'text13': `un`
    },
    pages: {
        'text1': `Únase a un líder mundial`,
        'text2': `comercio social`,
        'text3': `¡Comunidad!`,
        'text4': `Gane $5-$10 por cada $100.000 operadores. El costo depende de los pares de divisas que operan, del tipo de cuenta que tenga y de otros factores.`,
        'text5': `Forme parte de una comunidad de más de 2 millones de usuarios y más de 90.000 líderes, unidos en la plataforma de trading social más grande del mundo.`,
        'text6': `Comparta estrategias y gane comisiones por las operaciones que le copien otros usuarios. ¡Únase a ZuluTrade ahora!`,
        'text7': `ZuluTrade Leader`,
        'text8': `Abra una cuenta de Líder`,
        'text9': `Saber más`
    },
    second3: {
        'text1': `Enjoy Flexible`,
        'text2': `Compensation Schemes`,
        'text3': `Our default monetization model is CPA, but we are open to discussing more options for established affiliates, like hybrid and custom deals if they can deliver large volumes of customers. Our CPA playouts are categorized into Tiers, depending on the Geolocation of the accounts referred by our affiliates.`,
        'text4': `Maximize your earnings with our affiliate program. Earn up to 300$ per investor introduced, and claim even more with our hybrid custom CPA model`,
        'text5': `Become an Affiliate`,
        'text6': `Saber más`,
        'text7': `Affiliate Zulu Coin`
    },
    section2: {
        'text1': `Líderes de alto rendimiento`,
        'text2': `Las estrategias que tienen impulso positivo y que se mueven cerca de máximos de récord.`,
        'text3': `Copiadores`,
        'text4': `Seguidores`,
        'text5': `Comparta lo mejor de todos ellos`
    },
    section3: {
        'text1': `¿Por qué unirse a ZuluTrade como`,
        'text2': `¿Líder?`,
        'text3': `ZuluTrade licence icon`,
        'text4': `ZuluTrade está regulada por la HCMC en la UE, la FSA en Japón, la FSC en Mauricio y la FSCA en Sudáfrica.`,
        'text5': `ZuluTrade advanced and feature rich icon`,
        'text6': `Una plataforma avanzada y con un sinfín de prestaciones para personalizar su experiencia de trading.`,
        'text7': `ZuluTrade support icon`,
        'text8': `Aumente sus beneficios con un trader desk especializado para atraer y retener inversores.`,
        'text9': `ZuluTrade No hidden costs icon`,
        'text10': `Sin costos ocultos ni límites de pagos mensuales.`,
        'text11': `ZuluTrade multiple brokers icon`,
        'text12': `Una plataforma pensada para soportar múltiples brokers.`,
        'text13': `ZuluTrade network icon`,
        'text14': `Una enorme red social de trading con más de 90.000 líderes.`
    },
    section4: {
        'text1': `Cómo`,
        'text2': `ZuluTrade`,
        'text3': `trabaja para`,
        'text4': `¿Líderes?`,
        'text5': `Join ZuluTrade icon`,
        'text6': `Conviértase en un auténtico líder de ZuluTrade`,
        'text7': `¡Únase a ZuluTrade como líder, conecte su cuenta y comparta sus estrategias ganadoras!`,
        'text8': `Rankings icon`,
        'text9': `Destaque del resto y triunfe`,
        'text10': `Nuestro sistema de clasificación tiene en cuenta rendimiento, estabilidad, comportamiento y perspectivas.`,
        'text11': `Earnings icon`,
        'text12': `Gane dinero sin estrés`,
        'text13': `En calidad de líder, usted ganará cuando los inversores copien sus operaciones, teniendo en cuenta el tamaño de cada una de ellas y la tolerancia al riesgo de sus seguidores.`
    },
    section5: {
        'text1': `Líderes de ZuluTrade:`,
        'text2': `¿Qué hacen?`,
        'text3': `Un "Líder" es un usuario de ZuluTrade que comparte sus operaciones con otros usuarios y que deja que éstos lo copien. El Líder manda una señal a todos sus inversores por cada acción de trading que se produce en su cuenta.`,
        'text4': `¡Cree una comunidad y lidérela!`,
        'text5': `research icon`,
        'text6': `Investigue`,
        'text7': `el mercado, las noticias y las novedades de las grandes empresas regularmente.`,
        'text8': `intract icon`,
        'text9': `Interactúe`,
        'text10': `con sus inversores e infórmeles de cómo va su estrategia de trading en todo momento. `,
        'text11': `broadcast icon`,
        'text12': `Envíe`,
        'text13': `señales de trading a los inversores de ZuluTrade y añada su nombre a la clasificación de la web.`
    },
    section6: {
        'text1': `Cómo unirse a ZuluTrade como`,
        'text2': `¿Líder?`,
        'text3': `Paso 1`,
        'text4': `Regístrese como Líder en ZuluTrade`,
        'text5': `Paso 2`,
        'text6': `Haga pública su cuenta y comience a operar.`,
        'text7': `Paso 3`,
        'text8': `Los inversores copian su estrategia y usted recibe beneficios.`,
        'text9': `Únase a la comunidad ZuluTrade`,
        'text10': `Rellene sus datos personales automáticamente`,
        'text11': `Google icon`,
        'text12': `o ingréselos manualmente`,
        'text13': `Nombre de usuario o email`,
        'text14': `Escriba una contraseña`,
        'text15': `Abra una cuenta`
    },
    second5: {
        'text1': `How to become`,
        'text2': `ZuluTrade Affiliate`,
        'text3': `in 3 easy steps?`,
        'text4': `1`,
        'text5': `Click on 'Become an Affiliate'.`,
        'text6': `2`,
        'text7': `Complete the registration and get your unique affiliate URL.`,
        'text8': `3`,
        'text9': `Invite your contacts using unique affiliate URL  and start earning.`,
        'text10': `One-Time Pin has been sent to your email. Please enter it below.`,
        'text11': `Enter OTP`,
        'text12': `Submit`,
        'text13': `Affiliates Account Registration`,
        'text14': `Enter Email Address`,
        'text15': `Enter Your Password`,
        'text16': `Confirm Password`,
        'text17': `Select Residence Country`,
        'text18': `Company Full Name (For Corporate Accounts)`,
        'text19': `Enter Company Full Name`,
        'text20': `Company's Legal Representative`,
        'text21': `Nombre de pila`,
        'text22': `Apellido`,
        'text23': `I agree to the`,
        'text24': `Privacy Policy`,
        'text25': `Terms.`,
        'text26': `Register`,
        'text27': `Back to login`,
        'text28': `La negociación de instrumentos financieros implica un riesgo significativo de pérdida. Para el descargo de responsabilidad completo`,
        'text29': `haga clic aquí.`
    },
    affiliate: {
        'text1': `How to become`,
        'text2': `ZuluTrade Affiliate`,
        'text3': `in 3 easy steps?`,
        'text4': `1`,
        'text5': `Click on 'Become an Affiliate'.`,
        'text6': `2`,
        'text7': `Complete the registration and get your unique affiliate URL.`,
        'text8': `3`,
        'text9': `Invite your contacts using unique affiliate URL and start earning.`,
        'text10': `Verify your Email address`,
        'text11': `Enter the 6-digit verification code that was sent to`,
        'text12': `The code is valid for 10 minutes.`,
        'text13': `Resend Code`,
        'text14': `Submit`,
        'text15': `Affiliates Account Registration`,
        'text16': `Enter Email Address`,
        'text17': `Enter Your Password`,
        'text18': `Confirm Password`,
        'text19': `Select Residence Country`,
        'text20': `Company Full Name (For Corporate Accounts)`,
        'text21': `Enter Company Full Name`,
        'text22': `Nombre de pila`,
        'text23': `Apellido`,
        'text24': `I agree to the`,
        'text25': `Privacy Policy`,
        'text26': `&`,
        'text27': `Terms.`,
        'text28': `Register`,
        'text29': `Thank`,
        'text30': `you!`,
        'text31': `Your account has created successfully.`,
        'text32': `La negociación de instrumentos financieros implica un riesgo significativo de pérdida. Para el descargo de responsabilidad completo`,
        'text33': `haga clic aquí.`,
    },
    zulutrade: {
        'text1': `¿Quiere unirse a ZuluTrade `,
        'text2': `Paso 1`,
        'text3': `Paso 2`,
        'text4': `Paso 3`,
        'text5': `Únase a la comunidad ZuluTrade`,
        'text6': `Rellene sus datos personales automáticamente`,
        'text7': `o ingréselos manualmente`,
        'text8': `Correo electrónico`,
        'text9': `Contraseña`,
        'text10': `Seguridad de la contraseña`,
        'text11': `1 uppercase character`,
        'text12': `8 or more characters`,
        'text13': `at least 1 number`,
        'text14': `at least 1 letter`,
        'text15': `at least 1 special character`,
        'text16': `Loading...`,
        'text17': `Abra una cuenta`,
        'text18': `La negociación de instrumentos financieros implica un riesgo significativo de pérdida. Para el descargo de responsabilidad completo`,
        'text19': `haga clic aquí.`,
        'text20': `Nombre de pila`,
        'text21': `Número de teléfono`,
        'text22': `Apellido`,
        'text23': `Seleccionar país de residencia`,
        'text24': `Busca aquí..`,
        'text25': `El país es obligatorio`,
        'text26': `Se requiere el número de teléfono`,
    },

    // Investor page
    
    banner: {
        'text1': `Invertir es fácil con`,
        'text2': `¡CopyTrading y Trading Social!`,
        'text3': `¡Vuélvete zulú!`,
        'text4': `Con más de 90.000 líderes pro y más de 2 millones de usuarios activos, ZuluTrade es una de las plataformas de trading social líderes del mundo. Descubra a los mejores líderes de todo el planeta, copie sus estrategias y replique sus operaciones en tiempo real en su cuenta de inversión.`,
        'text5': `Empiece a invertir`,
        'text6': `ZuluTrade Banner Image`
    },
    third1: {
        'text1': `Siga el`,
        'text2': `expertas`,
        'text3': `y vencer a la`,
        'text4': `impares`,
        'text5': `en el mundo del trading con Copy Trading`,
        'text6': `Con ZuluTrade a su lado, lleve su viaje por el trading a un nivel superior.`,
        'text7': `ZuluTrade Compare Leaders Image`,
        'text8': `¡Abra una cuenta Demo y practique con ella para aprender!`,
        'text9': `DEMO`,
        'text10': `Become an Affiliate`,
    },
    third2: {
        'text1': `Por qué`,
        'text2': `ZuluTrade?`,
        'text3': `ZuluTrade transparent copy trading platform icon`,
        'text4': `La plataforma de copy trading más antigua y transparente.`,
        'text5': `ZuluTrade license icon`,
        'text6': `ZuluTrade está regulada por la HCMC en la UE, la FSA en Japón, la FSC en Mauricio y la FSCA en Sudáfrica.`,
        'text7': `ZuluTrade investors profit icon`,
        'text8': `Contacte a nuestro equipo de atención al cliente, un servicio con excelente feedback de nuestros usuarios, 24/5 por chat, teléfono o email.`,
        'text9': `ZuluTrade serves globally icon`,
        'text10': `Servicio global en más de 150 países.`,
        'text11': `Average winning ratio`,
        'text12': `Herramientas sociales con las que interactuar con líderes y otros inversores.`,
        'text13': `vast community`,
        'text14': `Una enorme comunidad de más de 2 millones de usuarios de todo el planeta.`
    },
    third3: {
        'text1': `¿Qué hace de ZuluTrade`,
        'text2': ` una plataforma única?`,
        'text3': `ZuluTrade Unique Features Image`,
        'text4': `ZuluGuard`,
        'text5': `TM`,
        'text6': `Una herramienta de trading social inteligente y segura para sus inversiones. ZuluGuard protege el capital que usted invierte, y para ello monitoriza constantemente su cuenta y así asegura beneficios y reduce posibles pérdidas.`,
        'text7': `Múltiples plataformas`,
        'text8': `Puede operar desde cualquiera de las muchas plataformas que ponemos a su disposición:`,
        'text9': `MT4, MT5, ActTrader, X Open Hub y Match-Trader.`,
        'text10': `Muro social`,
        'text11': `Interactúe con otros inversores y líderes de todo el mundo. Comente, reseñe, participe en el debate y aprenda a dominar el mercado gracias a la sabiduría de la comunidad.`,
        'text12': `Empiece a hacer copy trading ahora`
    },
    third4: {
        'text1': `¿Quiere unirse a ZuluTrade como`,
        'text2': ` Inversor?`,
        'text3': `Paso 1`,
        'text4': `Los inversores analizan la página de rendimiento de los líderes y, después, deciden si deben seguirlos o no. `,
        'text5': `Paso 2`,
        'text6': `Cada vez que un líder hace una operación, esta se copia también en las cuentas de los inversores.`,
        'text7': `Paso 3`,
        'text8': `Si una operación es provechosa para el líder, también lo es para el inversor.`,
        'text9': `Abra una cuenta`,
        'text10': `La negociación de instrumentos financieros implica un riesgo significativo de pérdida. Para el descargo de responsabilidad completo`,
        'text11': `haga clic aquí.`
    },
    header: {
        'text12': `ZuluTrade's`,
    }
}