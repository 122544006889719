<template>
  <div>
    <section id="bannerForm" class="pt80 pb80 bodyColor">
      <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7 col-12 mb-4 mb-lg-0">
                <div class="section-disc mobText-center maxCustom">
                    <!-- <h1>One-stop <span>solution</span> for all your <span>social trading needs</span></h1> -->
                    <h1 id="langAR">{{$t('banner.text1')}} <span>{{$t('banner.text2')}}</span> {{$t('banner.text3')}}</h1>
                    <p class="sec-sub-heading">{{$t('banner.text4')}}</p>
                    <!-- <div class="f-flex banner-links mt-2">
                        <a href="#registerForm" class="commonBtn linkBtn btn-minWidth ms-0"><span>{{$t('banner.text5')}}</span></a>
                    </div> -->
                </div>
            </div>
            <div class="col-lg-5 col-12">
              <joinZuluTrade v-if="$route.name == 'zuluLanding'" />
              <joinZuluTrade1 v-if="$route.name == 'zuluLanding1'" />
            </div>
          <!-- <div class="col-md-8 col-12">
            <div class="section-disc text-center">
              <img class="img-fluid" src="../../assets/images/zulutradeSolutionImages/banner-img.png" :alt="$t('banner.text6')" width="1296" height="424"/>
            </div>
          </div> -->
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import joinZuluTrade from "@/components/joinZulutradeForm.vue";
import joinZuluTrade1 from "@/components/joinZulutradeForm1.vue";
export default {
  name: "bannerSection",
  components: { joinZuluTrade, joinZuluTrade1 },
};
</script>
