<template>
    <section class="what-do-leader pt80 pb80">
        <div class="container">
            <div class="row">
                <div class="col-md-6 col-12">
                    <div class="section-disc text-start">
                        <h2 id="langAR"><span>{{$t('section5.text1')}}</span> <br>{{$t('section5.text2')}}</h2>
                    </div>    
                </div>
                <div class="col-md-6 col-12">
                    <div class="section-disc text-start">
                     <p class="sec-sub-heading">{{$t('section5.text3')}}</p>
                    </div>    
                </div>
            </div>
            <h3 class="text-center mt-4 fw-semibold linestext"><span class="position-relative">{{$t('section5.text4')}}</span></h3>
            <div class="row what-do-leader-row">
                <div class="col-md-4 col-12">
                    <div class="easy-step-col">
                        <div class="easy-step-info">
                        <div class="icn-wrap">
                            <img class="img-fluid" src="../../assets/images/zuluImages/research.svg" :alt="$t('section5.text5')">
                        </div>
                        <h3>{{$t('section5.text6')}}</h3>
                        <p>{{$t('section5.text7')}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-12">
                    <div class="easy-step-col">
                        <div class="easy-step-info">
                        <div class="icn-wrap">
                            <img class="img-fluid" src="../../assets/images/zuluImages/intract.svg" :alt="$t('section5.text8')">
                        </div>
                        <h3>{{$t('section5.text9')}}</h3>
                        <p>{{$t('section5.text10')}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-12">
                    <div class="easy-step-col">
                        <div class="easy-step-info">
                        <div class="icn-wrap">
                            <img class="img-fluid" src="../../assets/images/zuluImages/broadcast.svg" :alt="$t('section5.text11')">
                        </div>
                        <h3>{{$t('section5.text12')}}</h3>
                        <p>{{$t('section5.text13')}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script >
export default {
    name: 'SectionFifth'
}
</script>
