<template>
  <div class="lnvestorLandingPage">
    <header>
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6 col-md-6 col-6">
            <div class="header-logo">
              <a :href="`${static_vars.domainURL}`" target="_self">
              <img
                class="img-fluid"
                src="../assets/images/zulutradeSolutionImages/zulutradeLogo.svg"
                :alt="$t('leader.text1')"
              />
              </a>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-6 text-end">
            <div class="signUpBtn d-flex align-items-center justify-content-end">
              <div class="position-relative">
                <a
                  @click.prevent="countrySelector = !countrySelector"
                  class="d-flex align-items-center country_selector_btn_wrapper filter-dropdown"
                  href="javascript:void(0)"
                >
                  <span class="flagName me-2">{{ selectedLanguage.name }}</span>
                  <span
                    class="country_selector_flag"
                    :class="selectedLanguage.flag"
                  ></span>
                </a>
                <ul
                  class="dropdown_menu_animated dropdown-menu"
                  :class="countrySelector ? 'show' : ''"
                >
                  <li
                    v-for="lang of langs"
                    v-on:click="() => selectLanguage(lang)"
                    class="country_dropdown_list_item"
                  >
                    <span class="country_selector_flag" :class="lang.flag"></span>
                    <span class="country_dropdown_link_name">{{ lang.name }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <bannerSection />
    <sectionScnd />
    <sectionThird />
    <sectionForth />
    <sectionFifth
      :stepOne="$t('leader.text9')"
      :stepTwo="$t('leader.text10')"
      :stepThree="$t('leader.text11')"
      :pageType="$t('leader.text12')"
      :a_an="$t('leader.text13')"
    />
  </div>
</template>
<script>
import bannerSection from "./zulu-landing/bannerSection.vue";
import sectionScnd from "./zulu-landing/sectionScnd.vue";
import sectionThird from "./zulu-landing/sectionThird.vue";
import sectionForth from "./zulu-landing/sectionForth.vue";
import sectionFifth from "./zulu-landing/sectionFifth.vue";
import { myStore } from "@/store/pinia";
import $ from 'jquery';

export default {
  setup() {
      const store = myStore();
      return { store };
  },
  data() {
    return {
      langs: [
        { name: "English", flag: "flag-eng", value: "en" },
        { name: "Arabic", flag: "flag-ara", value: "ar" },
        { name: "German", flag: "flag-de", value: "de" },
        { name: "Polish", flag: "flag-pl", value: "pl" },
        { name: "Portuguese", flag: "flag-plrt", value: "pt" },
        { name: "Spanish", flag: "flag-spa", value: "es" },
        { name: "Vietnamese", flag: "flag-vn", value: "vi" },
        { name: "Italian", flag: "flag-it", value: "it" },
        { name: "Filipino", flag: "flag-fil", value: "fil" },
        { name: "Malay", flag: "flag-ms", value: "ms" },
      ],
      countrySelector: false,
      selectedLanguage: { name: "English", flag: "flag-eng", value: "en" },
    };
  },
  components: { bannerSection, sectionScnd, sectionThird, sectionForth, sectionFifth },
  methods: {
    goTowebsite(){
      window.open(`${static_vars.domainURL}`,'_self')
    },
    selectLanguage(lang) {
      this.selectedLanguage = lang;
      this.countrySelector = !this.countrySelector;
      this.$i18n.locale = lang.value;
    },
  },
  mounted(){
      const _that = this;
      $(document).on("click", function (event) {
          if (!$(event.target).closest(".filter-dropdown").length) {
            if(_that.selectedLanguage.name=='Arabic'){
              var tags = $('[id=langAR]')
              for (var i = 0; i < tags.length; i++) {
                $(tags[i]).attr('dir', 'rtl');
                $(tags[i]).attr('lang', 'AR');
              }
            } else {
              var tags = $('[id=langAR]')
              for (var i = 0; i < tags.length; i++) {
                $(tags[i]).removeAttr('dir');
                $(tags[i]).removeAttr('lang');
              }
            }
              _that.countrySelector = false;
          }
      });
  },
  created(){
    const userLocale =
  navigator.languages && navigator.languages.length
    ? navigator.languages[0]
    : navigator.language;
    if(userLocale){
      let data = this.langs.filter(i => i.value == userLocale);
      if(data.length){
        this.selectedLanguage = data[0]
        this.selectLanguage( this.selectedLanguage)
      }else{
        this.selectedLanguage = { name: "English", flag: "flag-eng", value: "en" }
        this.selectLanguage( this.selectedLanguage)
      }
    }
    this.countrySelector = false
  }
};
</script>
